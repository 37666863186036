import type { ViewItem } from 'types/financials.types';
import {
  ActionButtonOptions,
  ActiveCardType,
  DetailedViewSettings,
  LazyLoadingRowExpansionState,
  Period,
  PeriodSlice,
} from 'types/financials.types';
import { AppDispatch, AppThunk, RootState } from 'store/store';
import { setDefaultPeriod, setRangeType } from 'utils/period.utils';
import { FilterList } from 'types/filterTable.types';

import { debounce } from 'lodash';
import { getValueFromStorage, toggleActionOption } from 'utils/actionButtonStorage';
import { reportsService } from 'services/reports.services';
import { defaultDetailedViewSettings, financialsSlice, selectPanelTable } from './slice';
import { reportsApi } from 'store/api/reports.api';
import { appSlice } from 'store/app.slice';
import { PanelType } from 'types/app.types';

type PeriodSetter = PeriodSlice & {
  isDefaultRange?: boolean;
  isDashboard?: boolean;
};

export const setPeriod = (
  {
    period,
    templateId,
    isDefaultRange = false,
    isDashboard = false,
  }: PeriodSetter): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const table = getState().financials.tables[ templateId ];
    if (!table) {
      return;
    }
    const currentPeriod = table.period;
    const newPeriod: Period = {
      ...currentPeriod,
      ...period,
      isManuallySet: period.isManuallySet == undefined ? true : period.isManuallySet
    };
    dispatch(financialsSlice.actions.setPeriod({ templateId, period: newPeriod }));
    if (isDashboard) return;
    setRangeType(templateId, isDefaultRange);
    setDefaultPeriod(templateId, newPeriod, isDefaultRange);
  };

export const toggleActionsButton = (templateId: number, option: ActionButtonOptions): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { financials } = getState();
    dispatch(financialsSlice.actions.setState({
      templateId,
      value: {
        ...financials.tables[ templateId ].state,
        [ option ]: !getValueFromStorage(templateId, option)
      }
    }));
    toggleActionOption(templateId, option);
  };

export const toggleFilteredElements = (
  stagingFilters: FilterList): AppThunk => (dispatch: AppDispatch) => {
  if (!stagingFilters) return;
  dispatch(financialsSlice.actions.setFilters(stagingFilters));
};

export const setFiltersTable = (
  templateId: number,
  filters: FilterList): AppThunk =>
  (dispatch: AppDispatch,) => {
    dispatch(financialsSlice.actions.setFiltersTable({ templateId, value: filters }));
  };

export const clearFilters = (templateId: number): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.clearFilters(templateId));
  };

interface PanelSetter {
  panelType: PanelType;
  type?: ActiveCardType;
  templateId?: number | null;
  rowId?: number | null;
}

export const setLeftPanel = ({ templateId, panelType, type }: PanelSetter): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.setActive({ templateId: templateId, type }));
    dispatch(appSlice.actions.setLeftPanel(panelType));
  };

export const setRightPanel = (templateId: number): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.setActive({ templateId, type: 'table' }));
    dispatch(financialsSlice.actions.setRightPanel('chat'));
  };

export const clearLeftPanel = (): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setLeftPanel(null));
  };

export const clearRightPanel = (): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.setRightPanel(null));
  };

export const clearPanels = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.setLeftPanel(null));
  dispatch(financialsSlice.actions.setRightPanel(null));
};

export const clearCapsuleList = () => (dispatch: AppDispatch) => {
  dispatch(financialsSlice.actions.setCapsuleList([]));
};

export const updateCapsuleList = (data: ViewItem[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const viewId = state.financials.viewId;
    dispatch(financialsSlice.actions.setCapsuleList(data));
    await updateVisible(viewId, data);
  };

export const setCapsuleList = (data: ViewItem[]): AppThunk => (dispatch: AppDispatch) => {
  dispatch(financialsSlice.actions.setCapsuleList(data));
};

export const clearViewId = () => (dispatch: AppDispatch) => {
  dispatch(financialsSlice.actions.setViewId(null));
};

export const toggleVisibilityItem = (viewItem: ViewItem) => 
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const viewItemList = state.financials.capsuleList;
    const viewId = state.financials.viewId;
    const findItemIndex = viewItemList.findIndex(
      c => c.templateId === viewItem.templateId && c.type === viewItem.type
    );
    const foundItem = viewItemList.at(findItemIndex);

    if (foundItem) {
      const newItem = { ...foundItem, isShown: !foundItem.isShown };
      const items = state.financials.capsuleList.slice();
      items.splice(findItemIndex, 1, newItem);

      dispatch(financialsSlice.actions.setCapsuleList(items));
      await updateVisible(viewId, items);
    }
  };

export const setOneVisible = (viewItem: ViewItem) => 
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const viewId = state.financials.viewId;
    const items = state.financials.capsuleList.map((c) => 
      ({ ...c, isShown: viewItem.templateId == c.templateId && viewItem.type === c.type })
    );

    dispatch(financialsSlice.actions.setCapsuleList(items));
    dispatch(financialsSlice.actions.setActive(viewItem));
    await updateVisible(viewId, items);
  };

export const addViewItem = (viewItem: ViewItem) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const findItem = state.financials.capsuleList.find(
      c => c.templateId === viewItem.templateId && c.type === viewItem.type
    );

    if (!findItem) {
      const items = [ ...state.financials.capsuleList, viewItem ];
      dispatch(financialsSlice.actions.setCapsuleList(items));
      await updateVisible(state.financials.viewId, items);
    }
  };

export const removeViewItem = (viewItem: ViewItem) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const findItem = state.financials.capsuleList.find(
      c => c.templateId === viewItem.templateId && c.type === viewItem.type
    );

    if (findItem) {
      const items = state.financials.capsuleList.filter(el => el !== findItem);
      dispatch(financialsSlice.actions.setCapsuleList(items));
      await updateVisible(state.financials.viewId, items);
    }
  };

const updateVisible = debounce(async (viewId: number, capsuleList: ViewItem[]) => {
  // viewId can be null if we are previewing template that is not assigned to any view.
  if (viewId) {
    const cl = capsuleList.map(c => ({
      ...c,
      type: c.type === 'table' ? 'template' : c.type,
    }));

    await reportsService.toggleReportVisible(viewId, cl);
  }
}, 600);

export const addNewTables = (templateIds: number[]) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const tables = getState().financials.tables;
    const tableIds = Object.keys(tables);
    const newTables = templateIds.filter(id => !tableIds.includes(id.toString()));
    dispatch(financialsSlice.actions.addTables(newTables));
  };

export const removeLazyLoadingRowToExpand = (templateId: number, id: string) =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.clearLazyLoadingRowToExpand({
      templateId,
      value: id
    }));
  };

export const addLazyLoadingRowToExpand = (templateId: number, row: LazyLoadingRowExpansionState) =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.setLazyLoadingRowToExpand({
      templateId,
      value: row
    }));
  };

export const updateDetailedViewSettings = (settings: Partial<DetailedViewSettings>) =>
  (dispatch: AppDispatch) => {
    dispatch(financialsSlice.actions.setDetailedViewSettings(settings));
  };

export const clearDetailedViewSettings = () => (dispatch: AppDispatch) => {
  dispatch(updateDetailedViewSettings(defaultDetailedViewSettings));
};

export const changePanelContextIfOpen = (templateId: number, itemType: ActiveCardType) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const table = selectPanelTable(state) != null;

    if (table) {
      dispatch(setLeftPanel({
        templateId,
        type: itemType,
        panelType: state.app.leftPanel
      }));
    }
  };

export const removeDimensionFromFilters = ({ templateId, dimensionId }: {
  templateId: number;
  dimensionId: number;
}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {

    const filters = getState().financials.tables[ templateId ]?.filter;
    if (!filters) return;
    const newFilters = filters.filter(f => f.dimension !== dimensionId);
    await dispatch(reportsApi.endpoints.setFilters.initiate({ id: templateId, data: newFilters }));
    dispatch(setFiltersTable(templateId, newFilters));
  };
