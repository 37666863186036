import React, { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  type UseFormSetValue,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, InputNumber } from 'antd';
import clsx from 'clsx';

import type { FormType } from '../dimensionSplit.types';

import Button from 'components/elements/button/Button';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { useAppSelector } from 'store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import { balanceValues, CURRENCY_SYMBOL } from '../split.utils';

import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle-thick.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { sumLabels } from '../validation';

import DimensionSearchableSelect from './dimensionSearchableSelect/DimensionSearchableSelect';

import styles from './DimensionSplit.module.scss';
import { emptyDimension, emptyLabel } from '../splits.constants';
import DeleteSplitPopover from './deletePopover/DeleteSplitPopover';
import { separateThousands } from 'utils/financials.utils';

interface Props {
  index: number;
  control: Control<FormType>;
  errors: FieldErrors<FormType>;
  nominalValue?: number | null;
  isDraft?: boolean;
  selectedDimensions: number[];
  deleteDimension: (isDraft: boolean) => void;
  getValues: () => FormType;
  reset: (value: Partial<FormType>) => void;
  setValue: UseFormSetValue<FormType>;
}

const DimensionSplit = ({
  index,
  control,
  errors,
  nominalValue,
  isDraft,
  selectedDimensions,
  deleteDimension,
  getValues,
  reset,
  setValue
}: Props) => {
  const [ t ] = useTranslation('financials');

  const dimensions = useAppSelector(state => state.breakdowns.dimensions);
  const dimensionsMap = useAppSelector(state => state.breakdowns.dimensionMap);

  const selectedDimension = useWatch({
    control,
    name: `dimensions.${ index }.dimension`,
  });

  const excludedDimensions = useMemo(() => {
    return dimensions
      .filter(item => item.id !== selectedDimension && selectedDimensions.includes(item.id))
      .map(item => item.id);
  }, [ selectedDimensions, selectedDimension ]);

  const labelValues = useWatch({
    control,
    name: `dimensions.${ index }.labels`,
  });

  const onDimensionChange = (dimensionId: number) => {
    const prevValue = getValues();

    if (
      dimensionId &&
      prevValue.dimensions[ index ].dimension !== dimensionId &&
      prevValue.dimensions[ index ].labels &&
      prevValue.dimensions[ index ].labels.some(item => item.dimensionItemId)
    ) {

      const newEmptyDimension = {
        ...emptyDimension,
        dimension: dimensionId
      };

      prevValue.dimensions[ index ] = newEmptyDimension;

      reset({ ...prevValue });
    }
  };

  const labelsValue = useWatch({
    control,
    name: `dimensions.${ index }.labels`
  });

  const percentageSum = useMemo(() => sumLabels(labelsValue), [ labelsValue ]);
  const nominalValueSum = useMemo(() => {
    if (!nominalValue) return 0;
    return (sumLabels(labelsValue) / 100) * nominalValue ?? 0;
  }, [ labelsValue, nominalValue ]);

  const labelsOptions = useMemo(() => {
    if (!selectedDimension) {
      return [];
    }

    const dimension = dimensions.find(item => item.id === selectedDimension);
    if (!dimension) {
      return [];
    }

    return dimension.items.map(item => ({
      label: getDisplayName(item.customName),
      value: item.id
    }));
  }, [ selectedDimension, dimensions ]);

  const { fields, append, remove } = useFieldArray({
    name: `dimensions.${ index }.labels`,
    control
  });

  const getLabelOptions = (dimensionId: number) => {
    // exclude already assigned labels
    const assignedLabels = labelValues.map(item => item.dimensionItemId)
      .filter(label => label !== dimensionId);

    return labelsOptions
      .filter(item => !assignedLabels.includes(item.value));
  };

  const addLabel = () => {
    append({ ...emptyLabel }, { shouldFocus: true });

    setTimeout(() => {
      const lastLabel = document.getElementById(
        `dimensions_${ index }_labels_${ fields.length }_dimensionItemId`
      );

      lastLabel?.focus();
    }, 100);
  };

  const canUnassign = (poz: number) => {
    const formPart = getValues().dimensions[ poz ];
    const dimension = formPart.dimension;

    if (!dimension) return true;

    return dimensions.find(item => item.id === dimension)?.canBeUnassigned;
  };

  const balanceLabels = () => {
    const balancedValues = balanceValues(labelsValue.map(item => Number(item.percentage)));

    labelValues.forEach((item, labelIndex) => {
      setValue(
        `dimensions.${ index }.labels.${ labelIndex }.percentage`,
        formatNumber(balancedValues[ labelIndex ])
      );

      const newNominal = (balancedValues[ labelIndex ] / 100) * nominalValue;

      setValue(
        `dimensions.${ index }.labels.${ labelIndex }.nominalValue`,
        formatNumber(Math.round((newNominal + Number.EPSILON) * 100) / 100)
      );
    });

  };

  const canBalanceValues = () => {
    const activeLabels = labelValues.filter(label => typeof label.dimensionItemId === 'number');
    const isMultipleValueLabels = activeLabels
      .some(label => (label.dimensionItemId as unknown as string) === '(Multiple)');

    return activeLabels.length > 1 && !isMultipleValueLabels;
  };

  return <>
    <div className={ styles.dimensionSection }>
      <div className={ styles.formField }>
        <label htmlFor={ 'dimension_' + index } className={ styles.label }>
          { t('dimension-split.modal.dimension') }
        </label>

        <div className={ styles.dimensionRow } id={ 'dimension_' + index + '_cont' }>
          <Controller
            control={ control }
            name={ `dimensions.${ index }.dimension` }
            render={ ({ field: { value, onChange } } ) => 
              <DimensionSearchableSelect
                value={ value as number }
                dimensions={ dimensions }
                autoClearSearchValue
                disabledDimensions={ excludedDimensions }
                onChange={ (e) => {
                  onDimensionChange(e as unknown as number);
                  onChange(e);
                } }
                index={ index }
              />
            }
          />
          <DeleteSplitPopover
            dimension={ dimensionsMap[ selectedDimension ] }
            onDelete={ () => deleteDimension(isDraft) }
            canUnassign={ canUnassign(index) }
          />
        </div>
      </div>
      <div className={ styles.labels }>
        { 
          selectedDimension ? <>
            <label className={ clsx(styles.label, styles.labelsLabel ) }>
              { t('dimension-split.modal.labels') }
            </label>

            {
              fields.map((field, labelIndex) => {
                return <div key={ field.id }>
                  <div className={ styles.dimensionLabelRow }>
                    <div id={ 'dimension_' + index + '_labels_' + labelIndex + '_cont' }>
                      <Controller
                        control={ control }
                        name={ `dimensions.${ index }.labels.${ labelIndex }.dimensionItemId` }
                        render={ ({ field: { value, onChange } } ) => <SearchableSelect
                          labelInValue={ true }
                          id={ `dimensions_${ index }_labels_${ labelIndex }_dimensionItemId` }
                          className={ clsx(
                            styles.searchableSelect,
                            { [ styles.withNominal ]: !!nominalValue }
                          ) }
                          autoClearSearchValue
                          placeholder={ t('dimension-split.modal.select-label') }
                          disabled={ !selectedDimension }
                          showAction={ [ 'click', 'focus' ] }
                          getPopupContainer={
                            () => document.getElementById(
                              'dimension_' + index + '_labels_' + labelIndex + '_cont'
                            ) as HTMLElement
                          }
                          autoFocus={ false }
                          options={ getLabelOptions(value) }
                          defaultValue={ value }
                          popupClassName='ag-custom-component-popup'
                          onChange={ (val) => {
                            onChange(val.value);
                          } }
                        /> }
                      />
                    </div>
                    { !!nominalValue && <Controller
                      control={ control }
                      name={ `dimensions.${ index }.labels.${ labelIndex }.nominalValue` }
                      render={ ({ field: { value, onChange } }) =>
                        <InputNumber
                          className={ styles.nominalInput }
                          id={ `dimensions_${ index }_labels_${ labelIndex }_nominalValue` }
                          disabled={ !labelsValue.at(labelIndex)?.dimensionItemId }
                          value={ value }
                          prefix={ CURRENCY_SYMBOL }
                          controls={ false }
                          precision={ 2 }
                          formatter={
                            newValue => `${ newValue }`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ')
                          }
                          parser={ newValue => Number(newValue.replaceAll(/\s€?|(,*)/g, '')) }
                          onChange={ (newValue) => {
                            onChange(newValue);
                            const newPercentage = (newValue / nominalValue) * 100;
                            setValue(
                              `dimensions.${ index }.labels.${ labelIndex }.percentage`,
                              Math.round((newPercentage + Number.EPSILON) * 100) / 100
                            );
                          } }
                        />
                      }
                    /> }
                    <Controller
                      control={ control }
                      name={ `dimensions.${ index }.labels.${ labelIndex }.percentage` }
                      render={ ({ field: { value, onChange } }) =>
                        <Input
                          className={ styles.percentageInput }
                          id={ `dimensions_${ index }_labels_${ labelIndex }_percentage` }
                          type='number'
                          suffix='%'
                          disabled={ !labelsValue.at(labelIndex)?.dimensionItemId }
                          value={ value }
                          onChange={ (e) => {
                            onChange(e);
                            const newNominal = (parseFloat(e.target.value) / 100) * nominalValue;
                            setValue(
                              `dimensions.${ index }.labels.${ labelIndex }.nominalValue`,
                              Math.round((newNominal + Number.EPSILON) * 100) / 100
                            );
                          } }
                        />
                      }
                    />
                    
                    <Button
                      className={ styles.removeButton }
                      type='link'
                      tabIndex={ -1 }
                      onClick={ () => remove(labelIndex) }
                    >
                      <RemoveIcon />
                    </Button>
                  </div>
                  { errors?.dimensions?.[ index ]?.labels?.[ labelIndex ]?.percentage &&
                    <span className={ styles.formError }>
                      { errors?.dimensions[ index ]?.labels?.[ labelIndex ].percentage?.message }
                    </span> }
                </div>;
              }) 
            }

          </> : null
        }
      </div>

    </div>

    { selectedDimension ? <>
      <div className={ styles.labelsControl }>
        <Button
          className={ styles.addLabelButton }
          onClick={ addLabel }
          type='link'
          icon={ <AddIcon /> }>
          { t('dimension-split.modal.add-label') }
        </Button>

        {
          canBalanceValues() ?
            <Button
              type='link'
              className={ styles.balanceButton }
              onClick={ balanceLabels }
              disabled={ percentageSum === 100 }
            >
              { t('dimension-split.modal.balanceValues') }
            </Button> : <div></div>
        }
        
      </div>

      { labelValues.some(label => typeof label.dimensionItemId !== 'string' ) ? <div className={
        clsx(styles.validation, percentageSum === 100 ? styles.success : styles.error )
      }>
        <span className={ styles.validationLabel }>
          { percentageSum === 100 ? <CheckIcon /> : <WarningIcon /> }
          { t('dimension-split.modal.total') }
        </span>
        {
          !!nominalValue && <span className={ styles.validationNominalValue }>
            { formatNominalValue(nominalValueSum) }
            { ' ' }
            { CURRENCY_SYMBOL }
          </span>
        }
        <span className={ styles.validationPercentage }>
          { formatNumber(percentageSum) }
          { ' ' }
          %
        </span>
      </div> : null }
    </> : null }

  </>;
};

export default DimensionSplit;

function formatNumber(value: number) {
  return parseFloat(value?.toFixed(2));
}

function formatNominalValue(value: number) {
  return separateThousands(formatNumber(value) ?? '');
}
