import Modal, { Props as ModalProps } from '../../elements/modal/Modal';
import { useTranslation } from 'react-i18next';
import React from 'react';
import SearchableSelect from '../../elements/searchableSelect/SearchableSelect';
import { Dimension } from '../../../types/filterTable.types';
import { getDisplayName } from '../../../utils/common.utils';
import styles from '../../settings/integrations/accountRoles/AccountRolesSection.module.scss';

export type DimensionItemSelectorModalProps = Omit<ModalProps, 'onConfirm'> & {
  isVisible: boolean;
  dimension: Dimension;
  onClose: () => void;
  onConfirm: (dimensionItemId: number | 'unassigned') => Promise<void>;
};

const DimensionItemSelectorModal = (props: DimensionItemSelectorModalProps) => {
  const [ t ] = useTranslation('financials');
  const [ isBusy, setIsBusy ] = React.useState(false);
  const options: { value: number | 'unassigned'; label: string }[] = props.dimension.items.map(
    item => ({
      value: item.id,
      label: getDisplayName(item.customName)
    }));
  if (props.dimension.canBeUnassigned) {
    options.push({ value: 'unassigned', label: t('common:unassigned') });
  }
  const [
    selectedDimensionItemId,
    setSelectedDimensionItemId ] = React.useState<number | 'unassigned'>(options[ 0 ].value);
  const onChange = (value: number) => {
    setSelectedDimensionItemId(value);
  };
  return (
    <Modal
      title={ t('dimension-item-selector.title') }
      { ...props }
      width={ 325 }
      closeText={ t('common:form.cancel') }
      okText={ t('common:form.apply') }
      onConfirm={ async () => {
        setIsBusy(true);
        await props.onConfirm(selectedDimensionItemId);
        setIsBusy(false);
      } }
      confirmLoading={ isBusy }
    >
      <div className={ styles.role }>
        <span>{ getDisplayName(props.dimension.customName) }</span>
        <SearchableSelect
          autoFocus={ true }
          className={ styles.select }
          defaultValue={ options[ 0 ] }
          options={ options }
          onChange={ onChange }
        />
      </div>
    </Modal>
  );
};

export default DimensionItemSelectorModal;
