import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriversCategory } from 'types/financials.types';
import DriversGroup from 'components/financials/panels/planDriverPanel/driversGroup/DriversGroup';
import { useSearchable } from 'context/SearchableContext';
import { useFormContext } from 'react-hook-form';
import { PLAN_SETTINGS_KEYS, PlanSettings } from 'types/planning.types';

import styles from 'components/financials/panels/planDriverPanel/drivers/Drivers.module.scss';
import Tabs from 'components/elements/tabs/Tabs';

const Drivers = () => {
  const { getValues } = useFormContext<PlanSettings>();
  const [ t ] = useTranslation('planning');
  const { state: { search }, dispatch } = useSearchable();
  const translateTab = (tabValue: DriversCategory) => {
    return {
      [ DriversCategory.ALL ]: t('plan-drivers.tabs.all'),
      [ DriversCategory.GENERAL ]: t('plan-drivers.tabs.general'),
    }[ tabValue ] || '';
  };
  const tabOptions = useMemo(() => {
    return Object.values(DriversCategory).map((tab) => ({
      label: translateTab(tab),
      key: tab,
    }));
  }, []);
  const [ tab, setTab ] = useState(tabOptions[ 0 ].key);

  const getResultAmount = () => {
    const values = getValues();
    return PLAN_SETTINGS_KEYS
      .map((group) => {
        return Object.values(values[ group ]).filter((val) => {
          return val.name.toLowerCase().includes(search.toLowerCase()) ||
            val.description?.toLowerCase().includes(search.toLowerCase());
        });
      })
      .reduce((acc, curr) => acc + curr.length, 0);
  };

  useEffect(() => {
    if (search === '') {
      dispatch({ type: 'results', payload: 0 });
      return;
    }
    dispatch({ type: 'results', payload: getResultAmount() });
    setTab(DriversCategory.ALL);
  }, [ search ]);

  const renderAllDrivers = () => {
    return <div className={ styles.allDrivers }>
      {
        PLAN_SETTINGS_KEYS.map((group) => {
          return <DriversGroup
            key={ group }
            showGroupName={ true }
            groupName={ translateTab(group as unknown as DriversCategory) }
            group={ group }
          />;
        })
      }
    </div>;
  };

  const onChangeTab = useCallback((key: DriversCategory) => {
    setTab(key);
  }, []);

  return (
    <div className={ styles.drivers }>
      <div className={ styles.tabs }>
        <Tabs
          fullWidth={ false }
          activeKey={ tab }
          animated={ true }
          onChange={ onChangeTab }
          items={ tabOptions }
          centered={ false }
        />
      </div>
      {
        tab === DriversCategory.ALL ?
          renderAllDrivers() : <DriversGroup
            key = { tab }
            showGroupName={ false }
            groupName={ tab.toString() }
            group={ tab }
          />
      }
    </div>
  );
};

export default Drivers;
