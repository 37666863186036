import React from 'react';
import styles from './Sections.module.scss';
import { TableSection } from '../types/table.types';
import Tabs from '../../tabs/Tabs';

interface Props {
  items: TableSection[];
  onChange: (activeKey: string) => void;
  activeKey: string;
}

const Sections = ({ items, onChange, activeKey }: Props) => {
  if (!items || items.length === 0) {
    return null;
  }
  
  return <div className={ styles.sections }>
    <Tabs
      fullWidth={ false }
      items={ items }
      centered={ false }
      activeKey={ activeKey }
      onChange={ onChange }
      className={ styles.tabs }
      size='small'
    />
  </div>;
};

export default Sections;
