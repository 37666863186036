import React, { useState } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import Button from 'components/elements/button/Button';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-bin.svg';

import styles from './DeleteSplitPopover.module.scss';
import { Dimension } from 'types/filterTable.types';
import { getDisplayName } from 'utils/common.utils';

interface Props {
  dimension: Dimension;
  canUnassign: boolean;
  onDelete: () => void;
}

const DeleteSplitPopover = ({ dimension, onDelete, canUnassign }: Props) => {
  const [ t ] = useTranslation('financials');

  const name = dimension?.customName ?? dimension?.name;

  const [ deletePopoverVisible, setDeletePopoverVisible ] = useState(false);

  return <Popover 
    placement='bottomRight'
    showArrow={ false }
    open={ deletePopoverVisible }
    onOpenChange={ (visible) => setDeletePopoverVisible(visible) }
    overlayClassName={ styles.popover }
    trigger='click'
    title={
      <div className={ styles.popoverHeader }>
        <div className={ styles.popoverTitle }>
          <TrashIcon />
          { t('dimension-split.modal.deletePopup.title') }
        </div>
        <GhostIconButton onClick={ () => setDeletePopoverVisible(false) }>
          <CloseIcon />
        </GhostIconButton>
      </div>
    }
    className={ styles.deletePopover }
    content={
      <div className={ styles.popoverContent }>
        <p className={ styles.popoverDescription }>
          {
            canUnassign ?
              t('dimension-split.modal.deletePopup.description', {
                dimensionName: getDisplayName(name)
              })
              :
              t('dimension-split.modal.deletePopup.deleteUnassignableDescription')
          }
        </p>
        <div className={ styles.popoverActions }>
          <Button
            className={ styles.popoverButton }
            onClick={ () => setDeletePopoverVisible(false) }
            type='link'
          >
            { t('common:form.cancel') }
          </Button>
          <Button
            className={ styles.popoverButton }
            danger
            onClick={ () => onDelete() }
          >
            { t('common:delete') }
          </Button>
        </div>
      </div>
    }
    mouseEnterDelay={ .3 }
  >
    <Button
      className={ styles.removeDimension }
      type='link'
      tabIndex={ -1 }
    >
      <TrashIcon />
    </Button>
  </Popover>;
};

export default DeleteSplitPopover;
