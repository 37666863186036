import React from 'react';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import CommandBarDropdown from
  'components/commandBar/components/common/dropdown/CommandBarDropdown';
import { CommandBarCustom } from 'components/commandBar/types/commandBar.types';

import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';

import styles from '../dropdownButton/CommandBarDropdownButton.module.scss';
import clsx from 'clsx';

interface Props {
  button: CommandBarCustom;
}

const CommandBarCustomButton = ({ button }: Props) => {
  return <CommandBarDropdown button={ button }>
    <CommandBarButton button={ button }>
      <div className={ styles.dropdownTrigger }>
        { button.icon }
        <ArrowIcon className={ clsx(styles.arrowDown, 'exc') } />
      </div>
    </CommandBarButton>
  </CommandBarDropdown>;
};

export default CommandBarCustomButton;