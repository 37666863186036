import {
  GridApi,
  ColDef,
  IRowNode,
  CellRange,
  RowNodeTransaction,
  ValueSetterParams
} from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import dayjs from 'dayjs';
import type { BudgetItemType } from 'types/budget.types';
import type { DimensionItem } from 'types/filterTable.types';
import type { Account, Counterparty } from 'types/statutory.types';

import { Duration, InvoicingFrequency } from 'types/contracts.types';
import { type FinancialRow, type FinancialsRowData, RowType } from 'types/financials.types';
import { ReportType } from 'types/templates.types';
import { getUUID } from 'utils/templates.utils';
import { Entry } from 'components/elements/tableInlineEdit/budgetItemPopover/budgetOverview.utils';
import { getListOfSelectedRows, getValueCellsInRange, isRangeSelection } from './selection.utils';
import { AgGridReact } from 'ag-grid-react';
import { isLazyLoadingRow, isProductProxy } from 'utils/financials.utils';
import { addLazyLoadingRowToExpand } from 'store/financials.slice';
import { store } from 'store/store';
import { getDisplayName } from 'utils/common.utils';
import { DATE_FORMATS } from 'utils/date.utils';

export const NO_ADD_BUTTON_ROW_TYPES: RowType[] = [
  RowType.TITLE,
  RowType.SUM_UP,
  RowType.SUBTOTAL,
  RowType.TOTAL,
  RowType.FORMULA,
  RowType.SPACER,
  RowType.HALF_SPACER,
];

export const durationMap = {
  [ Duration.FOR_1_MONTH ]: 1,
  [ Duration.FOR_3_MONTHS ]: 3,
  [ Duration.FOR_6_MONTHS ]: 6,
  [ Duration.FOR_18_MONTHS ]: 18,
  [ Duration.FOR_12_MONTHS ]: 12,
  [ Duration.FOR_24_MONTHS ]: 24,
};

type BudgetingOptionKeys = 'startDate' | 'endDate' | 'duration' | 'frequency';

export const getBudgetingOptionKey = (option: BudgetingOptionKeys, templateId: number) => {
  return `budgeting-option_${ templateId }:${ option }`;
};

export function focusFirstBudgetCell(grid: AgGridCommon<unknown, unknown>, rowIndex: number) {
  const firstBudgetColumn = grid.columnApi.getAllGridColumns()
    .find(col => col.getColId().startsWith('plan'));

  if (!firstBudgetColumn) return;

  setTimeout(() => {
    grid.api.setFocusedCell(rowIndex, firstBudgetColumn.getColId());
    grid.api.startEditingCell({
      rowIndex,
      colKey: firstBudgetColumn.getColId(),
    });
  });
}

export function refocusCell(api: GridApi<FinancialRow>, rowIndex?: number, column?: string) {
  const focusedCell = api.getFocusedCell();

  const _rowIndex = rowIndex ?? focusedCell?.rowIndex;
  const _column = column ?? focusedCell?.column;

  api.clearRangeSelection();
  api.clearFocusedCell();
  api.setFocusedCell(_rowIndex, _column);
  api.addCellRange({
    rowStartIndex: _rowIndex,
    rowEndIndex: _rowIndex,
    columns: [ _column ]
  });

}

export function isNewRowWithValue(data: FinancialRow) {
  if (data?.type !== RowType.NEW_BUDGET_ITEM) {
    return false;
  }

  const allValues = Object.values(data.plan);

  return allValues.some((value) =>!!value);
}

export function isNewCellHasValue(data: FinancialRow, column: ColDef) {
  if (data?.type !== RowType.NEW_BUDGET_ITEM || !column.field?.startsWith(ReportType.PLAN)) {
    return false;
  }

  const allEntries = Object
    .entries(data.plan)
    .filter(([ , value ]) => !!value);
  if (allEntries.length) {
    const result = allEntries.find(([ key ]) => column.field?.endsWith(key));

    return !!result;
  }

  return false;
}

export function getRowDataForDetailedView(node: IRowNode, columnFields?: string[]) {
  const rowData = node?.data?.rowData;

  if (!rowData) {
    return null;
  }

  if (!columnFields?.length) {
    const entriesWithValue = Object.entries(rowData.entries)
      .filter(([ , value ]: [ string, Entry ]) => value.amountFormula);
    if (entriesWithValue.length) {
      return entriesWithValue.map(([ key ]) => getCellForDetailedView(node, key));
    } else {
      return getCellForDetailedView(node);
    }
  }

  return columnFields.map(columnField => {
    let key = columnField;
    if (key.startsWith(ReportType.PLAN)) {
      key = columnField.split('__').at(-1);
    }
    return getCellForDetailedView(node, key);
  });
}

export function getCellForDetailedView(node: IRowNode, dateColumn?: string) {
  const rowData = node?.data?.rowData;

  const dateFallback = dateColumn ? dayjs(dateColumn).format('YYYY-MM-DD') : '';

  const amountFormula = rowData.entries?.[ dateColumn ]?.amountFormula;
  const startDate = rowData.entries?.[ dateColumn ]?.startDate;

  return {
    ...rowData,
    endDate: getEndDate(rowData.endDate, rowData.startDate ?? dateFallback, rowData.duration?.id),
    itemType: rowData.budgetItemType?.id,
    startDate: startDate ?? dateFallback,
    amountFormula: amountFormula,
    invoicingFrequency: rowData.invoicingFrequency?.id,
    dimensionItemIds: rowData.dimensionItems
      ?.map((item: DimensionItem) => item.id) ?? [],
    internalId: getUUID(),
  };
}

export function getEndDate(endDate: string, startDate: string, duration: Duration) {
  if (endDate) return endDate;

  if (duration) {
    return dayjs(startDate).add(durationMap[ duration ], 'month').format('YYYY-MM-DDTHH:mm:ss');
  }

  return null;
}

export function createNewRow(
  templateId: number,
  _node: IRowNode,
  budgetItemTypes: BudgetItemType[],
  overrides?: Partial<FinancialRow>
) {
  const newId = `${ getUUID() }_${ Date.now() }`;

  let node;

  if (isProductProxy(_node)) {
    node = _node?.childrenAfterFilter?.at(0);
  } else {
    node = _node;
  }

  if (!node) {
    return;
  }

  const BO = getBudgetingOptions(templateId);

  const [
    dimensionItems,
    parentAccounts,
    counterparties,
    budgetItemsTypeIds
  ] = getParentsDimensions(node, [ [], [], [], [] ]);

  const salesBudgetItem = budgetItemTypes.find(type => type.name?.[ 'en' ] === 'Sales');

  const budgetItemType = budgetItemTypes.find(type => {
    const lastBudgetTypeId = typeof budgetItemsTypeIds.at(0) === 'object' ?
      (budgetItemsTypeIds.at(0) as unknown as BudgetItemType).id :
      budgetItemsTypeIds.at(0);
    return type.id === lastBudgetTypeId;
  });

  let memo = '...';

  if ((isProductProxy(_node)) || (isProductProxy(_node.parent) && _node.parent?.key)) {
    // ? Copy PP name if new row is added to PP
    if (isProductProxy(_node.parent)) {
      memo = _node.parent.key;
    } else {
      memo = _node.key;
    }
  } else {
    memo = getDisplayName(budgetItemType?.memo ?? salesBudgetItem?.memo, 'Sales');
  }

  const parentPrimary = parentAccounts.at(0)?.id;
  const budgetItemTypeAccounts = { ...(budgetItemType ?? salesBudgetItem)?.defaultAccounts };
  if (parentPrimary) {
    budgetItemTypeAccounts.primary = parentPrimary;
  }

  const newRow = {
    [ ReportType.ACTUAL ]: {},
    [ ReportType.PLAN ]: {},
    uuid: newId,
    type: RowType.NEW_BUDGET_ITEM,
    rowData: {
      name: {
        en: '...',
        fi: '...'
      },
      endDate: BO.endDate ? dayjs(BO.endDate, DATE_FORMATS) : undefined,
      duration: { id: BO.duration ?? null },
      invoicingFrequency: { id: BO.frequency ?? InvoicingFrequency.ONE_TIME },
      budgetItemType: budgetItemType ?? salesBudgetItem,
      dimensionItems: dimensionItems,
      counterparty: counterparties.at(0),
      memo,
      accounts: budgetItemTypeAccounts,
    },
    id: newId,
    filePath: [ ...(node.data?.filePath?.slice(0, -1) ?? []), newId ]
  };

  if ([
    RowType.BREAKDOWN, 
    RowType.FINANCIALS,
    RowType.DIMENSION_ITEM,
    RowType.UNASSIGNED
  ].includes(node.data?.type)) {
    const childPath = [ ...node.data.filePath, newId ];
    newRow.filePath = childPath;
  }

  if (overrides) {
    const { rowData } = overrides;

    return {
      ...newRow,
      ...overrides,
      rowData: {
        ...newRow.rowData,
        ...rowData
      }
    };
  }

  return newRow;
}

function getParentsDimensions(
  node: IRowNode,
  result: [ FinancialsRowData[], Account[], Counterparty[], number[] ]
): [ FinancialsRowData[], Account[], Counterparty[], number[] ] {

  const rowData = node.data?.rowData;

  if (node.data?.type === RowType.DIMENSION_ITEM) {

    if (rowData?.account?.id) {
      result.at(1).push(rowData.account);
    } else if (rowData?.counterparty?.id) {
      result.at(2).push(rowData.counterparty);
    } else if (typeof rowData.id === 'number') {
      result.at(0).push(rowData);
    }
  }

  if (rowData?.budgetItemType) {
    result.at(3).push(rowData.budgetItemType);
  }

  if (!node.parent) return result;

  return getParentsDimensions(node.parent, result);
}

export function insertNewRow(
  templateId: number,
  grid: AgGridCommon<unknown, unknown>,
  sourceNode: IRowNode,
  budgetItemTypes: BudgetItemType[],
  overrides?: Partial<FinancialRow>
) {
  const newNode = createNewRow(
    templateId,
    sourceNode,
    budgetItemTypes,
    overrides
  );

  if (!newNode) return;

  expandRow(sourceNode);

  if (isLazyLoadingRow(sourceNode)) {
    const state = store.getState();
    store.dispatch(addLazyLoadingRowToExpand(state.financials.active.templateId, {
      nodeId: sourceNode.id,
      expandedChildren: [ ]
    }));
    const transaction = applyNewCell(grid, sourceNode, newNode);

    // eslint-disable-next-line no-inner-declarations
    function handleEvent () {
      focusNewCell(grid, transaction);

      document.removeEventListener('lazyLoadedRow', handleEvent);
    }

    document.addEventListener('lazyLoadedRow' , handleEvent);
  } else {
    const transaction = applyNewCell(grid, sourceNode, newNode);
    focusNewCell(grid, transaction);
  }
}

function applyNewCell(
  grid: AgGridCommon<unknown, unknown>,
  sourceNode: IRowNode,
  newNode: unknown
) {
  
  let newRowIndex;
  if (sourceNode?.data?.type === RowType.BREAKDOWN) {
    newRowIndex = sourceNode.rowIndex + 1;
  } else {
    newRowIndex = sourceNode.parent.rowIndex + 1;
  }

  const transaction = grid?.api?.applyTransaction({
    add: [ newNode ],
    addIndex: newRowIndex
  });

  return transaction;
}

function focusNewCell(
  grid: AgGridCommon<unknown, unknown>,
  transaction: RowNodeTransaction,
) {
  const newRow = transaction.add.at(0);
  const lastFocusedCell = store.getState().financials.actionQueue
    .filter(action => action.type === 'focus').at(-1);

  if (!newRow.displayed) {
    const eventListener = () => {
      // ? Give UI time to render new row
      setTimeout(() => {
        focusNewCell(grid, transaction);
      }, 100);

      newRow.removeEventListener('heightChanged', eventListener);
    };

    newRow.addEventListener('heightChanged', eventListener);
  }

  if (lastFocusedCell && lastFocusedCell.payload.colId.startsWith(ReportType.PLAN)) {
    setTimeout(() => {
      const rowIndex = newRow.rowIndex;
      const column = lastFocusedCell.payload.colId;

      // Prevent selecting whole row or column
      if (rowIndex == null || column == null) return;
  
      grid.api.clearRangeSelection();
      grid.api.setFocusedCell(rowIndex, column);
  
      grid.api.addCellRange({
        rowStartIndex: rowIndex,
        rowEndIndex: rowIndex,
        columns: [ column ]
      });
    
      grid.api.startEditingCell({
        rowIndex: rowIndex,
        colKey: column
      });
    });
  } else {
    focusFirstBudgetCell(grid, newRow.rowIndex);
  }

}

export function budgetItemsFromSelection(selection: CellRange[] | IRowNode[], agGrid: AgGridReact) {
  let budgetItems = [];

  if (!selection) {
    return budgetItems;
  }

  if (isRangeSelection(selection)) {
    const rowsInRange = isRangeSelection(selection) ?
      getListOfSelectedRows(selection, agGrid) :
      [];

    const areNewRowsInRange = rowsInRange.length ? rowsInRange
      .every((row) => row?.data?.type === RowType.NEW_BUDGET_ITEM) : false;

    if (!areNewRowsInRange) {
      return [];
    }

    const newCellsInRange = areNewRowsInRange ?
      getValueCellsInRange(selection, agGrid, ReportType.PLAN) :
      [];

    if (newCellsInRange.length > 0) {
      budgetItems = newCellsInRange.flatMap(
        ({ node, column }) => getRowDataForDetailedView(node, [ column ])
      );
    } else {
      budgetItems = rowsInRange.flatMap(row => getRowDataForDetailedView(
        row, selection.at(0).columns.map(column => column.getColId())
      ));
    }

  } else {
    const areNewRowsInSelection = !!selection?.length && selection
      .every((row) => row?.data?.type === RowType.NEW_BUDGET_ITEM);
    const newCellsSelected = areNewRowsInSelection ?
      selection?.filter(
        (node) => Object.values(node.data.rowData.entries ?? {}).some(Boolean)
      ) : [];

    budgetItems = newCellsSelected.flatMap(node => getRowDataForDetailedView(node));
  }

  return budgetItems;
}

function expandRow(node: IRowNode) {
  node.setExpanded(true);

  if (node.displayed) {
    return;
  }

  if (node.parent) {
    expandRow(node.parent);
  }
}

function getBudgetingOptions (templateId: number) {
  if (!localStorage) return {};

  const endDate = JSON.parse(
    localStorage.getItem(getBudgetingOptionKey('endDate', templateId))
  );
  const duration = JSON.parse(
    localStorage.getItem(getBudgetingOptionKey('duration', templateId))
  );
  const frequency = JSON.parse(
    localStorage.getItem(getBudgetingOptionKey('frequency', templateId))
  );

  return {
    endDate,
    duration,
    frequency
  };
}

const trimReportType = (field: string, reportType: ReportType) => {
  return !field.includes(reportType) ? field : field.replace(`${ reportType }__`, '');
};

export function addOrUpdateRow(
  newValues: string[],
  params: ValueSetterParams<FinancialRow>,
  field: string,
  reportType: ReportType
) {
  if (params.node?.data?.type === RowType.NEW_BUDGET_ITEM) {
    params.api.applyTransaction({
      update: createUpdateForNewRow(params.node, newValues, field, reportType, params)
    });
  
    setTimeout(() => {
      refocusCell(params.api);
    });
  
    return true;
  }

  const firstChild = params.node.childrenAfterSort?.at(0);
  if (firstChild?.data?.type === RowType.NEW_BUDGET_ITEM) {
    const trimmedField = trimReportType(field, reportType);
    const oldChildValue = firstChild?.data.rowData.entries[ trimmedField ]?.amountFormula;

    if (oldChildValue && oldChildValue !== '0') {
      makeRowInsertion(params.node, newValues, field, reportType, params);
    } else {
      params.api.applyTransaction({
        update: createUpdateForNewRow(firstChild, newValues, field, reportType, params)
      });
    }
    
  } else {
    makeRowInsertion(params.node, newValues, field, reportType, params);
  }

  setTimeout(() => {
    refocusCell(params.api);
  });

  return false;
}

function makeRowInsertion(
  sourceNode: IRowNode,
  values: string[],
  field: string,
  reportType: ReportType,
  grid: AgGridCommon<unknown, unknown>,
) {
  const { entries, newReportData } = getNewFields(sourceNode, values, field, reportType, grid);

  const state = store.getState();
  const templateId = state.financials.active.templateId;

  setTimeout(() => {
    insertNewRow(templateId, grid, sourceNode, state.budget.budgetItemTypes, {
      [ reportType ]: newReportData,
      rowData: { entries }
    });
  });
}

function createUpdateForNewRow(
  sourceNode: IRowNode,
  values: string[],
  field: string,
  reportType: ReportType,
  grid: AgGridCommon<unknown, unknown>
) {
  const data = sourceNode.data;

  const { entries, newReportData } = getNewFields(sourceNode, values, field, reportType, grid);

  return [ {
    ...data,
    [ reportType ]: { ...(data?.[ reportType ] ?? {}), ...newReportData },
    rowData: {
      ...data.rowData,
      entries: {
        ...(data.rowData.entries ?? {}),
        ...entries
      }
    }
  } ];
}

function getNewFields(
  sourceNode: IRowNode,
  values: string[],
  field: string,
  reportType: ReportType,
  grid: AgGridCommon<unknown, unknown>
) {
  const columns = grid.columnApi?.getColumns();
  const startingColumnIndex = columns?.findIndex(column => column.getColId() === field);
  const newCells = values.map((value, i) => {
    return {
      column: columns?.at(startingColumnIndex + i),
      value
    };
  })
    .filter(({ column }) => {
      return column && column.getColId().startsWith(ReportType.PLAN);
    });

  const entries = newCells.reduce((acc, { column, value }) => {
    const trimmedField = trimReportType(column.getColId(), reportType);

    return {
      ...acc,
      [ trimmedField ]: {
        startDate: column.getColId().split('__')?.at(1)?.slice(0, 10),
        ...(sourceNode.data?.rowData?.entries?.[ trimmedField ] ?? {}),
        amountFormula: value,
      }
    };
  }, {});

  const newReportData = Object.fromEntries(
    Object.entries(entries).map(
      ([ key, value ]: [string, { amountFormula: string }]) => [ key, value.amountFormula ]
    ));

  return { entries, newReportData };
}
