import React from 'react';
import BudgetItemTypeDropdown
  from 'components/budget/budgetItemTypeDropdown/BudgetItemTypeDropdown';
import { getDisplayName } from 'utils/common.utils';
import { useAppSelector } from 'store/hooks/hooks';
import Button from 'components/elements/button/Button';
import styles from './Editors.module.scss';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { useTranslation } from 'react-i18next';

const BudgetItemTypeEditor = ({ value, onChange }) => {
  const [ t ] = useTranslation('financials');
  const budgetItemTypesMap = useAppSelector(state => state.budget.budgetItemTypesMap);

  const onSelect = (id: number) => {
    onChange(budgetItemTypesMap[ id ]);
  };

  return <BudgetItemTypeDropdown
    onSelect={ onSelect }
    placement='bottomLeft'
    title={
      <h4 className={ styles.title }>
        { t('inline-edit.fields.budget-item-type-placeholder') }
      </h4>
    }
    getPopupContainer={ (triggerNode) => triggerNode }
  >
    <Button type='ghost' className={ styles.budgetItemTypeButton }>
      { value ? getDisplayName(value?.name) : t('inline-edit.fields.budget-item-type-placeholder') }
      <ChevronDown />
    </Button>
  </BudgetItemTypeDropdown>;
};

export default BudgetItemTypeEditor;