import React from 'react';
import Modal from 'components/elements/modal/Modal';
import LabelingRulesTransactions
  from '../../../../labelingRules/rulesList/labelingRulesTransactions/LabelingRulesTransactions';
import styles from './ModalTransactionLines.module.scss';
import { TableContextProvider } from 'context/TableContext';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  ruleId: number;
}

const ModalTransactionLines = (
  {
    isVisible,
    onClose,
    onConfirm,
    ruleId,
  }: Props) => {

  return (
    <Modal
      isVisible={ isVisible }
      destroyOnClose={ true }
      onClose={ onClose }
      onConfirm={ onConfirm }
      disableButtons={ true }
      className={ styles.modal }
    >
      <TableContextProvider>
        <LabelingRulesTransactions ruleId={ ruleId }/>
      </TableContextProvider>
    </Modal>
  );
};

export default ModalTransactionLines;
