import { DisplayName } from './app.types';
import { BreakdownType } from './templates.types';
import { Account, Counterparty } from './statutory.types';
import { Product } from './revenueRecognition.types';
import { Contract } from './contracts.types';

export type DimensionItem = {
  id: number;
  name: DisplayName;
  customName: string | null;
  breakdownType?: BreakdownType;
  dimensionId: number | null;
  type?: string;
  group: DisplayName | null;
  relation: DimensionRelation | null;
  account: Account | null;
  counterparty: Counterparty | null;
  product: Product | null;
  contract: Contract | null;
  plType: string | null;
};

export type DimensionRelation = 'ACCOUNT' | 'CONTRACT' | 'PRODUCT' | 'COUNTERPARTY';

export type Dimension = {
  id: number;
  name: DisplayName;
  customName: DisplayName | string;
  items: DimensionItem[];
  canBeUnassigned: boolean;
  canBeAssigned: boolean;
  transactionLines: number;
  canEditName: boolean;
  canAddItems: boolean;
  category: DisplayName;
  favorite: boolean;
  type: BreakdownCategoryType;
  relation?: DimensionRelation;
};

export type DimensionLabelingProgress = {
  id: number;
  transactionLines: number;
};

export type DimensionItemWithDimension = {
  id: number;
  name: DisplayName;
  dimension: Omit<Dimension, 'items'>;
};

export type FilterList = DimensionFilter[];

export interface DimensionFilter {
  dimension: number;
  excludeUnassigned: boolean;
  excludedItems: number[];
}

export enum BreakdownCategoryType {
  USER = 'user',
  GLOBAL = 'global',
  PROCOUNTOR = 'procountor',
  NETVISOR = 'netvisor',
  XERO = 'xero',
}

export const EXTERNAL_BREAKDOWN_TYPES = [
  BreakdownCategoryType.PROCOUNTOR,
  BreakdownCategoryType.NETVISOR,
  BreakdownCategoryType.XERO,
];

export interface CountTransactionLines{
  count: number;
}
