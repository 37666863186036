import React, { useCallback, useMemo, useState } from 'react';
import WarnBeforeExitModal from '../../../../elements/modals/warnings/WarnBeforeExitModal';

interface Props<T> {
  onConfirm: (props: T) => void;
  isUnsaved: boolean;
}

/**
 * Hook that shows a warning modal if there are unsaved changes. The modal will have a confirm
 * button that will call the onConfirm function. The onConfirm function will be called with the
 * props that were passed to the continueWithCheck function. If there are no unsaved changes,
 * the onConfirm function will be called immediately. The hook returns the warnModal component
 * and the continueWithCheck function. The warnModal component should be rendered in the parent
 * component. The continueWithCheck function should be called when the user wants to continue
 * with the check for unsaved changes.
 */
export const useWarnOnUnsaved = <T, >({ onConfirm, isUnsaved }: Props<T>) => {
  const [ warnModalOpen, setWarnModalOpen ] = useState(false);
  const [ confirmProps, setConfirmProps ] = useState<T | null>(null);

  /**
   * Continue with the check for the unsaved changes. If there are unsaved changes,
   * show the warning modal and store the props for the onConfirm callback.
   * Otherwise, call the onConfirm function.
   */
  const continueWithCheck = useCallback(
    (props: T) => {
      if (isUnsaved) {
        setWarnModalOpen(true);
        setConfirmProps(props);
      } else {
        onConfirm(props);
      }
    },
    [ isUnsaved, onConfirm ]
  );

  const warnModal = useMemo(() => {
    return (
      <WarnBeforeExitModal
        isVisible={ warnModalOpen }
        onClose={ () => setWarnModalOpen(false) }
        onConfirm={ () => {
          setWarnModalOpen(false);
          if (confirmProps !== null) {
            onConfirm(confirmProps);
          }
        } }
      />
    );
  }, [ warnModalOpen, onConfirm, confirmProps ]);

  return {
    warnModal,
    continueWithCheck,
  };
};
