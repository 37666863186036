import { MutableRefObject } from 'react';
import useKeyPressListener from './useKeyPressListener';
import useOutsideClickListener from './useOutsideClickListener';

const useEscapeListener = (
  ref: MutableRefObject<HTMLElement>,
  callback: () => void,
  condition = true
) => {
  useOutsideClickListener({ ref, cb: callback, enabled: condition });
  useKeyPressListener({ keyBinding: { code: 'Escape' }, cb: callback, enabled: condition });
};

export default useEscapeListener;
