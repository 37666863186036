import { AgGridReact } from 'ag-grid-react';
import {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { cloneDeep } from 'lodash';
import React, { RefObject, useCallback, useContext, useMemo, useRef } from 'react';
import organizationsService from 'services/organizations.service';
import { breakdownsActions, selectBreakdowns } from 'store/breakdowns.slice';
import { selectPeriod } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  BreakdownCategoryType,
  Dimension,
  DimensionItem as DimensionItemType,
  EXTERNAL_BREAKDOWN_TYPES,
  FilterList
} from 'types/filterTable.types';

import { getDisplayName } from 'utils/common.utils';
import styles from './DetailsList.module.scss';
import UnassignedButton from './unassignedButton/UnassignedButton';
import { arrayToMap } from 'utils/mapping.utils';
import { FinancialTableContext } from 'context/FinancialTableContext';
import DimensionGroup from './DimensionGroup/DimensionGroup';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import { useTranslation } from 'react-i18next';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import clsx from 'clsx';
import ScrollDown from 'components/elements/scrollDown/ScrollDown';
import { GroupedVirtuoso } from 'react-virtuoso';
import DimensionItem from './DimensionItem/DimensionItem';
import { findDimensionFilter } from '../dimensionDetails.util';
import { isTransactionNode } from '../../../../../../types/statutory.types';

interface Props {
  dimension: Dimension;
  items: DimensionItemType[];
  stagingFilter?: FilterList;
  setStagingFilter?: React.Dispatch<React.SetStateAction<FilterList>>;
  isListView: boolean;
  activeUnassigned: boolean;
  setActiveUnassigned?: React.Dispatch<React.SetStateAction<boolean>>;
  gridRef?: RefObject<AgGridReact>;
  defaultUnassignNodesCallback?: UnassignFunction;
  assignLabels?: AssignFunction;
  templateId: number;
  isLabeling?: boolean;
  showUnassignedButton?: boolean;
}

const DetailsList = (
  {
    dimension,
    showUnassignedButton,
    items,
    stagingFilter,
    setStagingFilter,
    activeUnassigned,
    setActiveUnassigned,
    gridRef,
    defaultUnassignNodesCallback,
    isListView,
    templateId,
    isLabeling,
    assignLabels
  }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('labeling');

  const dimensions = useAppSelector(selectBreakdowns).dimensions;

  const scrollableElement = useRef<HTMLDivElement>();

  const financialTableContext = useContext(FinancialTableContext);
  const isTemplateBuilder = financialTableContext == null;
  const period = useAppSelector(selectPeriod(templateId));
  const { refetch } = useReportQuery({ templateId, period });

  const canDelete = (dimensionItem: DimensionItemType) => {
    if (dimension.relation === 'COUNTERPARTY') {
      return dimension.canAddItems && dimensionItem.counterparty?.source === 'USER';
    }
    return dimension.canAddItems && dimensionItem.type === BreakdownCategoryType.USER;
  };
  const canEdit = (dimensionItem: DimensionItemType) => {
    if (dimension.relation === 'COUNTERPARTY') {
      return dimension.canEditName && dimensionItem.counterparty?.source === 'USER';
    }
    return dimension.canEditName;
  };

  const showUnassigned = showUnassignedButton && (dimension?.canBeUnassigned ||
    EXTERNAL_BREAKDOWN_TYPES.includes(dimension?.type));

  const hasManyGroups = useMemo(() => dimension && Object.keys(arrayToMap(
    dimension?.items, di => getDisplayName(di.group), true)).length > 1, [ dimension ]);

  const onDelete = async (item: DimensionItemType) => {
    await organizationsService.deleteDimensionItem(item.id);
    const clone = cloneDeep(dimensions);
    const findDimension =
      clone.find(el => el.id === item.dimensionId);
    findDimension.items = findDimension.items.filter(el => el.id !== item.id);
    dispatch(breakdownsActions.setDimensions(clone));
    if (templateId)
      refetch();
  };

  const onEdit = async (name: string, item: DimensionItemType) => {
    try {
      const editResponse = await organizationsService.editDimensionsItem(
        item.id, { name });
      const data = editResponse.data;
      let findDimension = dimensions.find(
        el => el.id === item.dimensionId);
      findDimension = {
        ...findDimension,
        items: findDimension.items.map(di => di.id === data.id ? data : di)
      };
      dispatch(breakdownsActions.setDimensions(
        dimensions.map(d => d.id === findDimension.id ? findDimension : d)));
    } catch (e) {
      if (e.response.data.nonFieldErrors) {
        notifyError(t('labeling:dimension-item-unique-error'));
        throw e;
      } else {
        notifyUnexpectedError(e);
      }
    }

  };

  const onClickUnassigned = () => {
    if (isTemplateBuilder) return;
    setActiveUnassigned((prev) => !prev);
    setStagingFilter(prev => {
      const newFilters = cloneDeep(prev);
      const dimensionFiltersIndex = findDimensionFilter(newFilters, dimension.id);
      if (dimensionFiltersIndex > -1) {
        newFilters[ dimensionFiltersIndex ].excludeUnassigned = activeUnassigned;
      } else {
        newFilters.push({
          dimension: dimension.id,
          excludeUnassigned: activeUnassigned,
          excludedItems: [],
        });
      }
      return newFilters;
    });
  };

  const unassignButton = useMemo(() => {
    return showUnassigned && <UnassignedButton
      isActive={ activeUnassigned }
      dimension={ dimension }
      onClick={ onClickUnassigned }
      isListView={ isListView }
      gridRef={ gridRef }
      isLabeling={ isLabeling }
      unassignNodesCallback={ (nodes, unassignDimension, showModal) => {
        // if dimension is external - only allow budget transaction nodes
        const external = EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type);
        if (external) {
          const filteredNodes = nodes.filter(isTransactionNode).filter(n => n.data.budgetItem);
          if (filteredNodes.length !== 0) {
            defaultUnassignNodesCallback(filteredNodes, unassignDimension, showModal);
          }

        } else if ( unassignDimension.canBeUnassigned ) {
          defaultUnassignNodesCallback(nodes, unassignDimension, showModal);
        }
      } }
      isTemplateBuilder={ isTemplateBuilder }
    />;
  }, [
    activeUnassigned,
    defaultUnassignNodesCallback,
    gridRef,
    isLabeling,
    isListView,
    showUnassigned
  ]);

  const renderItems = useCallback(() => {
    const groupedItems = arrayToMap(items, di => getDisplayName(di.group), true);
    if (isListView) {
      const groups = Object.keys(groupedItems);
      const groupedItemsValues = Object.values(groupedItems);
      const counts = groupedItemsValues.map(group => group.length);
      // add 1 to the last count. We'll insert unassigned element there
      if (showUnassigned) {
        if (counts.length === 0) {
          counts.push(0);
        }
        counts[ counts.length - 1 ]++;
      }

      const flatItems = groupedItemsValues.flat(1);
      return <GroupedVirtuoso
        style={ { height: '100%' } }
        groupCounts={ counts }
        overscan={ 1000 }
        increaseViewportBy={ 1000 }
        groupContent={ index => {
          return (
            <DimensionGroup
              items={ [] }
              isTemplateBuilder={ isTemplateBuilder }
              onEdit={ onEdit }
              onDelete={ onDelete }
              setStagingFilter={ setStagingFilter }
              stagingFilter={ stagingFilter }
              isLabeling={ isLabeling }
              isListView={ isListView }
              canDelete={ canDelete }
              canEdit={ canEdit }
              gridRef={ gridRef }
              group={ groups[ index ] }
              assignLabels={ assignLabels }
            />
          );
        } }
        itemContent={ (index) => {
          if (index === flatItems.length && showUnassigned) {
            return unassignButton;
          }
          const dim = flatItems[ index ];
          if (dim == null) return null;
          return (
            <DimensionItem
              onEdit={ name => onEdit(name, dim) }
              onDelete={ () => onDelete(dim) }
              setStagingFilter={ setStagingFilter }
              stagingFilter={ stagingFilter }
              item={ dim }
              key={ dim.id }
              canEdit={ canEdit(dim) }
              canDelete={ canDelete(dim) }
              gridRef={ gridRef }
              isTemplateBuilder={ isTemplateBuilder }
              isLabeling={ isLabeling }
              assignLabels={ assignLabels }
            />
          );
        } }
      />;
    }

    return (
      <div className={ styles.groupsContainer }>
        { Object.entries(groupedItems).map(([ group, dimensionItems ]) => {
          return (
            <DimensionGroup
              key={ group }
              items={ dimensionItems }
              isTemplateBuilder={ isTemplateBuilder }
              onEdit={ onEdit }
              onDelete={ onDelete }
              setStagingFilter={ setStagingFilter }
              stagingFilter={ stagingFilter }
              isLabeling={ isLabeling }
              isListView={ isListView }
              canDelete={ canDelete }
              canEdit={ canEdit }
              gridRef={ gridRef }
              group={ hasManyGroups ? group : '' }
              assignLabels={ assignLabels }
            />
          );
        }) }

      </div>
    );
  }, [
    dimension,
    stagingFilter,
    isListView,
    dimensions,
    items,
    isLabeling,
    hasManyGroups,
    showUnassigned,
    assignLabels,
  ]);

  return <div ref={ scrollableElement } className={ clsx(
    styles.detailsList,
    { [ styles.templateBuilder ]: isTemplateBuilder, [ styles.column ]: isListView }
  ) }>
    <div className={ styles.groupContainer }>
      { dimension && renderItems() }
      {
        // In list view unassign button is virtualized with the rest of the items
        !isListView &&
        (<div className={ styles.unassignedButton }>
          { unassignButton }
        </div>)
      }
    </div>
    <ScrollDown scrollableElementRef={ scrollableElement }/>
  </div>;
};

export default DetailsList;
