import React, { useMemo, useRef } from 'react';
import { Control, 
  Controller, Path, PathValue, UnpackNestedValue, UseFormSetValue } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import enGB from 'antd/lib/locale/en_GB';
import fi from 'antd/lib/locale/fi_FI';
import 'dayjs/locale/en-gb';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styles from './DatePicker.module.scss';
import { Cadence, StatementsRequestForm } from 'types/form.types';
import {
  DATE_FORMATS,
  firstDayOfCadence,
  getDatePickerInputName,
  lastDayOfCadence
} from 'utils/date.utils';
import i18n from 'locales/i18n';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(quarterOfYear);

type Props<TFormValues> = {
  name: Path<TFormValues>;
  cadence: Cadence;
  control: Control<TFormValues>;
  defaultValue?: UnpackNestedValue<PathValue<TFormValues, Path<TFormValues>>>;
  className?: string;
  countEndOf?: boolean;
  setValue?: UseFormSetValue<StatementsRequestForm>;
  onChangeValue?: (date: dayjs.Dayjs) => void;
  isEndDate?: boolean;
};

const CustomDatePicker = <TFormValues extends Record<string, unknown>>({
  cadence, name, defaultValue, control, className = '',
  setValue, onChangeValue, isEndDate
}: Props<TFormValues>) => {
  const ref = useRef();

  const setDate = (date: Dayjs) => {
    if ( isEndDate )
      return lastDayOfCadence(date, cadence as Cadence);
    else return firstDayOfCadence(date, cadence as Cadence);
  };

  const locale = useMemo(() => 
    i18n.language === 'en' ? enGB.DatePicker : fi.DatePicker ,[ i18n.language ]);

  return (
    <div className={ `${ className } ${ styles.datePicker }` } ref={ ref }>
      <Controller
        control={ control }
        name={ name }
        defaultValue={ defaultValue }
        render={ ({ field: { onChange, value } }) => (
          <DatePicker
            data-testid={ `${ name }Picker` }
            locale={ locale }
            picker={ cadence }
            open={ true }
            bordered={ false }
            value={ value as Dayjs }
            onChange={ (date) => {
              const d = setDate(date);
              onChange(d);
              setValue(
                getDatePickerInputName(name),
                setDate(d).format(DATE_FORMATS[ 0 ]),
                { shouldValidate: true }
              );
              onChangeValue(d);
            } }
            getPopupContainer={ () => ref.current }
            className={ styles.picker }
            size='small'
          />
        ) }
      />
    </div>
  );
};

export default CustomDatePicker;
