import React, { useCallback, useMemo } from 'react';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import {
  HeaderTool,
  PanelSetting,
  SectionTableProps
} from 'components/elements/tableWrapper/types/table.types';
import { t } from 'i18next';
import { filter, noop } from 'lodash';
import { defaultFilter } from 'utils/financials.utils';
import DetailedViewLeftPanel from '../panels/DetailedViewLeftPanel';
import { ReactComponent as ColumnsIcon } from 'assets/icons/column.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import { AgGridReact } from 'ag-grid-react';
import { TablePanelProps } from '../panels/types/panel.types';
import { Transaction } from 'types/statutory.types';
import { InternalBudgetItem } from 'components/budget/budgetItemTable/hooks/useBudgetItems';
import { DetailedViewType } from 'types/financials.types';

interface Props {
  type: DetailedViewType;
  headerTools: HeaderTool[];
  budgetTableProps: SectionTableProps<InternalBudgetItem>;
  isGridReady: boolean;
  gridRef: React.MutableRefObject<AgGridReact>;
  sectionKey: string;
}

const useBudgetingSectionProps = ({
  type, headerTools, gridRef, budgetTableProps, sectionKey, isGridReady
}: Props) => {

  const groupOptions = null;

  const budgetSectionProps: SectionTableProps<InternalBudgetItem> = useMemo(() => {
    return {
      ...budgetTableProps,
      headerTools: [ ...headerTools, ...budgetTableProps.headerTools ],
    };
  }, [ budgetTableProps, type ]);

  const renderRowOperationPanel = useCallback((
    mode: 'label' | 'filter' | 'sort' | 'group' | 'columns',
    props: TablePanelProps<Transaction>
  ) => {
    return <DetailedViewLeftPanel
      availableTabs={ [ 'sort', 'group', 'columns' ] }
      assignLabels={ noop }
      setFilter={ noop }
      setStagingFilter={ noop }
      stagingFilter={ defaultFilter }
      unassignLabels={ noop }
      groupOptions={ groupOptions }
      mode={ mode }
      tableKey={ budgetTableProps.tableKey }
      sectionKey={ sectionKey }
      gridRef={ gridRef }
      isGridReady={ isGridReady }
      filter={ null }
      { ...props }
    />;
  }, [ gridRef, isGridReady, sectionKey ]);

  const panels = useMemo((): PanelSetting<Transaction>[] => {
    return [
      {
        key: 'sort',
        render: (props) => renderRowOperationPanel('sort', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.sort') }>
          <SortIcon />
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 3,
      },
      {
        key: 'group',
        render: (props) => renderRowOperationPanel('group', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.group') }>
          <GroupIcon />
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 4,
      },
      {
        key: 'columns',
        render: (props) => renderRowOperationPanel('columns', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.columns') }>
          <ColumnsIcon />
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 5,
      },
    ];
  }, [ renderRowOperationPanel, filter, sectionKey ]);

  const props = useMemo(() => {
    return {
      ...budgetSectionProps,
      panels,
    };
  }, [ budgetSectionProps, panels ]);

  return props;
};

export default useBudgetingSectionProps;
