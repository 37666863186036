import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { selectBudgetItemTypes } from 'store/budget.slice';
import Button from 'components/elements/button/Button';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import SectionDropdown from 'components/elements/sectionDropdown/SectionDropdown';
import type {
  DropdownGroup,
  ItemRenderer
} from 'components/elements/sectionDropdown/types/sectionDropdown.types';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';

import styles from './AddFieldDropdown.module.scss';

interface DropdownBudgetItemType {
  id: string;
  title: string;
  key: string;
  editor: string;
}

interface AddFieldDropdownProps {
  fields: DropdownGroup<DropdownBudgetItemType>[];
  onSelect: (id: string) => void;
}

const AddFieldDropdown = ({ fields, onSelect }: AddFieldDropdownProps) => {
  const [ t ] = useTranslation('financials');
  const [ open, setOpen ] = useState(false);
  const budgetItemTypes = useAppSelector(selectBudgetItemTypes);

  const itemRenderer: ItemRenderer = useCallback(({ item, search }) => {
    return <div className={ styles.item }>
      <HighlightedText text={ item.title } highlight={ search } />
    </div>;
  }, []);

  const onSelectItem = useCallback((id: number) => {
    onSelect(id as unknown as string);
    setOpen(false);
  }, [ onSelect ]);

  return <SectionDropdown
    open={ open }
    overlayStyle={ { width: '22rem' } }
    onOpenChange={ setOpen }
    isSelected={ () => false }
    onSelect={ onSelectItem }
    isDisabled={ () => false }
    rowHoverActions={ [] }
    groups={ fields }
    defaultGroupKey='all'
    total={ budgetItemTypes.length }
    itemRenderer={ itemRenderer }
    placement='bottomRight'
    dropdownClassName={ styles.dropdown }
    title={ <h4 className={ styles.title }>
      { t('inline-edit.add-field') }
    </h4> }
  >
    <Button className={ styles.triggerButton } type='default'>
      <PlusIcon />
      { t('inline-edit.add-field') }
    </Button>
  </SectionDropdown>;
};

export default AddFieldDropdown;