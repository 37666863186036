import React, { RefObject, useEffect, useMemo } from 'react';
import type { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { type FinancialNode, LoadingStatus, RowType } from 'types/financials.types';
import SpinningLoader from 'components/elements/spinning-loader/SpinningLoader';
import Tooltip from '../../elements/tooltip/Tooltip';
import RowCounterpartyLogo from '../financialTable/common/RowCounterpartyLogo';
import { isDimensionItemNode } from '../../../types/templates.types';
import {
  UNASSIGNED_ROW_NAME
} from '../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import useLazyLoadingRow from '../financialTable/hooks/useLazyLoadingRow';
import { removeLazyLoadingRowToExpand } from '../../../store/financials.slice';

import { ReactComponent as ExpandArrow } from 'assets/icons/chevron-right.svg'; 

import styles from 'components/financials/financialTable/styles/FinancialTable.module.scss';
import clsx from 'clsx';

interface Props {
  data: FinancialNode;
  node: IRowNode;
  value: string;
  gridRef: RefObject<AgGridReact>;
  templateId: number;
  tooltip: string;
}

const LazyLoadingRow = ({
  data,
  node,
  value,
  gridRef,
  templateId,
  tooltip
}: Props) => {
  const dispatch = useAppDispatch();
  const tableState = useAppSelector(state => state.financials.tables[ templateId ].state);
  const { addTransactionsToNode, controller } = useLazyLoadingRow(gridRef, templateId);
  const loadingStatus =
    useAppSelector((state) => state.financials.tables[ templateId ].lazyLoadingStatus[ node.id ]);
  const displayName = useMemo(() => value, [ value ]);
  const forceRowToExpand = useAppSelector((state) => {
    const lazyLoadingRowsToExpand = state.financials.tables[ templateId ].lazyLoadingRowsToExpand;
    return lazyLoadingRowsToExpand[ node.id ];
  });

  const hideExpandButton = useMemo(
    () => node?.allChildrenCount || !node?.data?.hasTransactions, [ node ]
  );

  const onLazyLoad = (childrenToExpand: string[] = []) => {
    if (loadingStatus === LoadingStatus.LOADING) {
      controller.abort();
      gridRef?.current?.api?.applyTransaction({
        update: [ { ...node.data, asyncStatus: 'loading' } ]
      });
      gridRef.current.api?.refreshCells({ force: true, rowNodes: [ node ] });
      return;
    }
    addTransactionsToNode(node, childrenToExpand);
  };

  const showCounterpartyLogo = (rowNode: IRowNode) => {
    if (rowNode.data && isDimensionItemNode(rowNode.data)) {
      return tableState.counterpartyLogos &&
        rowNode.data.rowData.counterparty &&
        rowNode.key !== 'Unassigned';
    }
  };

  useEffect(() => {
    if (forceRowToExpand != null) {
      onLazyLoad(forceRowToExpand.expandedChildren);
      dispatch(removeLazyLoadingRowToExpand(templateId, node.id));
      const event = new CustomEvent('lazyLoadedRow', { detail: { node: node.rowIndex } });
      document.dispatchEvent(event);
    }
  }, [ forceRowToExpand ]);

  return (
    <Tooltip title={ tooltip }>
      <span
        onDoubleClick={ hideExpandButton ? undefined : () => onLazyLoad([]) }
        className={ `${ styles.text }
        ${ hideExpandButton ? styles.emptyLazyLoadingRow : '' }
        ${ tableState.counterpartyLogos && (
      data.rowData?.name !== UNASSIGNED_ROW_NAME &&
      data?.type === RowType.DIMENSION_ITEM
    ) ? styles.renderLogo : '' }` }>
        <button
          type='button'
          className={
            clsx(styles.expandButton, hideExpandButton && styles.hidden)
          }
          onClick={ () => onLazyLoad([]) }
        >
          {
            loadingStatus === LoadingStatus.LOADING ? (
              <SpinningLoader className={ styles.loader } size={ 14 }/>
            ) : (
              <ExpandArrow className={ styles.expandArrow }/>
            )
          }
        </button>
        { showCounterpartyLogo(node) && <RowCounterpartyLogo row={ node }/> }
        { displayName }
      </span>
    </Tooltip>
  );
};

export default LazyLoadingRow;
