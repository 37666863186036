import React from 'react';
import clsx from 'clsx';

import styles from '../BudgetDropdown.module.scss';

interface Props {
  name: string;
  icon: React.ReactNode;
  value: string;
  className?: string;
  active?: boolean;
  disabled?: boolean;
}

const BudgetOption = ({ icon, name, value, className, active, disabled, ...props }: Props) => {
  return <div
    className={ clsx(
      styles.optionButton,
      { [ styles.active ]: active },
      { [ styles.disabled ]: disabled },
      className
    ) }
    { ...props }
  >
    <div className={ styles.label }>
      <div className={ styles.icon }>{ icon }</div>
      <div className={ styles.name }>{ name }</div>

    </div>
    <div className={ styles.value }>{ value }</div>
  </div>;
};

export default BudgetOption;
