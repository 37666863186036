export type OAuthIntegrationRequest = {
  name?: string;
  authorizationCode: string;
};

export enum IntegrationEnum {
  procountor = 'procountor',
  netvisor = 'netvisor',
  xero = 'xero',
}

export enum SyncState {
  NOT_STARTED = 'NOT_STARTED',
  AWAITING_ACCOUNTS = 'AWAITING_ACCOUNTS',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  FULL = 'FULL',
}

export interface Integration {
  id: number;
  name: string;
  type: keyof typeof IntegrationEnum;
  lastParsed: string;
  lastParsedStatus: 'success' | 'fail';
  syncInProgress: boolean;
  initialSyncState: SyncState;
}
