import React, { useRef, useState } from 'react';
import { Switch } from 'antd';
import { CommandBarOption } from 'components/commandBar/types/commandBar.types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './CommandBarDropdownOption.module.scss';

interface Props {
  option: CommandBarOption;
  onClose: () => void;
  disabled: boolean;
}

const CommandBarDropdownOption = ({ option, onClose, disabled }: Props) => {
  const getActive = () => {
    if (option.type === 'toggle') {
      return option.active;
    }
    return false;
  };
  const [ active, setActive ] = useState(getActive());
  const activeRef = useRef(getActive());

  const onChange = (_active: boolean) => {
    option.type === 'toggle' && option.onChange(_active);
    setActive(_active);
    activeRef.current = _active;
  };

  return <div
    onClick={ () => {
      if (option.type === 'default') {
        option.onClick();
        onClose();
      }
    } }
    className={ `
      ${ styles.option }
      ${ option.disabled ? styles.disabled : '' }
      ${ styles[ option.type ] }
    ` }
  >
    {
      option.type === 'toggle' ? <Switch
        checkedChildren={ <CheckOutlined /> }
        unCheckedChildren={ <CloseOutlined /> }
        checked={ active }
        onChange={ onChange }
        disabled={ disabled }
      /> : null
    }
    {
      option.icon ? <span className={ styles.icon }>
        { option.icon }
      </span> : null
    }
    <span className={ styles.name }>
      { option.label }
    </span>
  </div>;
};

export default CommandBarDropdownOption;
