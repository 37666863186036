import React from 'react';
import { Integration } from 'types/settings.types';
import { ReactComponent as CheckMark } from 'assets/icons/check-circle.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import styles from './IntegrationStatus.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../elements/button/Button';
import {
  retryNetvisor,
  retryProcountor,
  retryXero
} from '../../../../services/integration.service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { appActions } from '../../../../store/app.slice';

interface Props {
  integration: Integration;
}

const IntegrationStatus = ({ integration }: Props) => {
  const syncInProgress = useAppSelector(state => state.app.integrations[ 0 ]?.syncInProgress );
  const dispatch = useAppDispatch();

  const [ t ] = useTranslation('settings');

  const onRetry = async () => {
    dispatch(appActions.setIntegrationSyncInProgress(true));
    switch (integration?.type) {
      case 'netvisor':
        await retryNetvisor(integration.id);
        break;
      case 'procountor':
        await retryProcountor(integration.id);
        break;
      case 'xero':
        await retryXero(integration.id);
        break;
    }
  };

  if (!integration) {
    return null;
  }
  if (syncInProgress) {
    return <div className={ styles.inProgress }>
      <span>{ t('integration-in-progress') }</span>
    </div>;
  } else if (integration.lastParsedStatus === 'success') {
    return <CheckMark className={ styles.icon }/>;
  } else if (integration.lastParsedStatus === 'fail') {
    return <Button danger onClick={ onRetry }>
      { t('integration-retry') }
      <Warning className={ styles.icon }/>
    </Button>;
  }

  return null;
};

export default IntegrationStatus;
