import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PanelType } from 'types/app.types';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarUtil, UtilKey } from 'components/commandBar/types/commandBar.types';
import { toggleActionsButton, clearLeftPanel, setLeftPanel } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { ActionButtonOptions } from 'types/financials.types';
import { areFiltersActive } from 'utils/financials.utils';
import BudgetDropdown from '../financialTable/common/budgetDropdown/BudgetDropdown';

import { ReactComponent as FilterActiveIcon } from 'assets/icons/filters-active.svg';

const budgetDropdownButton = defaults.find((button) => button.key === 'budgetDropdown');

interface Props {
  disabled?: boolean;
  disabledCommands?: UtilKey[];
}

const useFinancialsCommandBar = ({ disabled = false, disabledCommands = [] }: Props) => {
  const [ t, i18n ] = useTranslation('financials');
  const appDispatch = useAppDispatch();
  const activeCard = useAppSelector(state => state.financials.active?.templateId);
  const isTableActive = useAppSelector(state => state.financials.active?.type === 'table');
  const panel = useAppSelector(state => state.app.leftPanel);
  const filters = useAppSelector(state => state.financials.tables[ activeCard ]?.filter);
  const hasNewCells = useAppSelector(state => state.financials.tables[ activeCard ]?.hasNewCells);

  const tableState = useAppSelector(sliceState => {
    const financialsState = sliceState.financials;
    return financialsState.tables[ activeCard ]?.state;
  });
  const toggleAction = (active: boolean, templateId: number, option: ActionButtonOptions) => {
    if (active !== tableState[ option ]) {
      appDispatch(toggleActionsButton(templateId, option));
    }
  };

  const settingsButton: CommandBarUtil = useMemo(() => {
    if (!tableState || activeCard == null || !isTableActive) {
      return null;
    }
    const actions = [
      {
        label:  t('actions.hide-dimension-labels'),
        active: tableState.dimensionLabels,
        disabled: hasNewCells,
        onChange: (active) =>
          toggleAction(active, activeCard, ActionButtonOptions.DIMENSION_LABELS)
      },
      {
        label:  t('actions.hide-dimension-subtotals'),
        active: tableState.dimensionNumbers,
        onChange: (active) =>
          toggleAction(active, activeCard, ActionButtonOptions.DIMENSION_NUMBERS)
      },
      {
        label: t('actions.hide-counterparty-logos'),
        active: tableState.counterpartyLogos,
        onChange: (active) =>
          toggleAction(active, activeCard, ActionButtonOptions.COUNTERPARTY_LOGOS)
      },
      {
        label:  t('actions.hide-accounts-number'),
        active: tableState.accountsNumber,
        onChange: (active) => toggleAction(active, activeCard, ActionButtonOptions.ACCOUNTS_NUMBER)
      },
      {
        label: t('actions.hide-accounts'),
        active: tableState.accounts,
        onChange: (active) => toggleAction(active, activeCard, ActionButtonOptions.ACCOUNTS),
      },
    ];

    const defaultValue = defaults.find(button => button.key === 'settings');
    return {
      ...defaultValue,
      tooltip: t('common:command-bar.tooltip.settings'),
      disabled,
      hidden: false,
      type: 'button-dropdown',
      title: t('actions.header.show'),
      options: actions.map(action => ({
        type: 'toggle',
        ...action,
      }))
    };
  }, [ tableState, activeCard, isTableActive, disabled, i18n.language, hasNewCells ]);

  const budgetingOptions = useMemo(() => {
    if (activeCard == null || !isTableActive) {
      return null;
    }

    return {
      key: 'budgetDropdown',
      type: 'button-custom',
      icon: budgetDropdownButton.icon,
      className: budgetDropdownButton.className,
      hidden: false,
      disabled,
      placement: 'left',
      tooltip: budgetDropdownButton.tooltip,
      render: () => {
        return <BudgetDropdown />;
      }
    } as const;
  }, [ isTableActive, activeCard, disabled ]);

  const getPanelButton = useCallback((panelType: PanelType): CommandBarUtil => {
    if (activeCard == null && panelType !== 'layout') {
      return null;
    }
    const defaultValue = defaults.find(button => button.key === panelType);
    const active = panel === panelType;
    return {
      ...defaultValue,
      tooltip: t(`common:command-bar.tooltip.${ panelType }`),
      icon: areFiltersActive(filters) && panelType === 'filter' ?
        <FilterActiveIcon/> : defaultValue.icon,
      disabled,
      hidden: false,
      active,
      type: 'button',
      onClick: () => {
        if (active) {
          appDispatch(clearLeftPanel());
        } else {
          appDispatch(setLeftPanel({ panelType, templateId: activeCard, type: 'table' }));
        }
      }
    };
  }, [ panel, activeCard, disabled, filters, i18n.language ]);

  const filterButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('filter');
  }, [ getPanelButton ]);

  const labelButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('label');
  }, [ getPanelButton ]);

  const sortButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('sort');
  }, [ getPanelButton ]);

  const layoutButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('layout');
  }, [ getPanelButton ]);

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => {
    return [
      budgetingOptions,
      settingsButton,
      filterButton,
      labelButton,
      sortButton,
      layoutButton
    ]
      .filter(el => el)
      .filter(el => !disabledCommands?.includes(el.key));
  }, [
    budgetingOptions,
    settingsButton,
    filterButton,
    settingsButton,
    sortButton,
    layoutButton,
  ]);
  
  useCommandBarEffect({ utils: utilsToUpdate, filterCapsules: filters });
  return undefined;
};

export default useFinancialsCommandBar;
