import React, { useState } from 'react';
import { CellRange } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { getValueCellsInRange } from 'components/financials/utils/selection.utils';

import styles from './RangeSignifier.module.scss';
import { isProductProxy, separateThousands } from 'utils/financials.utils';
import Portal from 'components/elements/portal/Portal';
import { ReportType } from 'types/templates.types';
import { RowType } from 'types/financials.types';
import { useAppSelector } from 'store/hooks/hooks';
import { COMMAND_BAR_PORTAL_ID } from 'components/commandBar/CommandBar';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  range: CellRange[];
  agGrid: AgGridReact;
}

const SELECTED_PROPS_LS_KEY = 'selectedProps';

const propsAvailable = {
  'sum': {
    // t('financials:table.range-signifier.sum')
    label: 'table.range-signifier.sum',
    getValue: getSum
  },
  'avg': {
    // t('financials:table.range-signifier.avg')
    label: 'table.range-signifier.avg',
    getValue: getAverage
  },
  'min': {
    // t('financials:table.range-signifier.min')
    label: 'table.range-signifier.min',
    getValue: getMinValue
  },
  'max': {
    // t('financials:table.range-signifier.max')
    label: 'table.range-signifier.max',
    getValue: getMaxValue
  },
  'count': {
    // t('financials:table.range-signifier.count')
    label: 'table.range-signifier.count',
    getValue: getCount
  }
} as const;

type PropsAvailable = typeof propsAvailable;

const RangeSignifier = ({ range, agGrid }: Props) => {
  const [ t ] = useTranslation('financials');
  const [ open, setOpen ] = useState(false);

  const [ selectedProps, setSelectedProps ] = useLocalStorage<(keyof PropsAvailable)[]>(
    SELECTED_PROPS_LS_KEY, [ 'sum', 'avg' ]
  );

  const templateId = useAppSelector(state => state.financials.active?.templateId);
  const table = useAppSelector(state => state.financials.tables[ templateId ]) || null;

  if (!range) {
    return null;
  }

  const values = [
    ...getValueCellsInRange(range, agGrid, ReportType.PLAN),
    ...getValueCellsInRange(range, agGrid, ReportType.ACTUAL)
  ];

  const justValues = values
    .filter(({ node }) => {
      if (node?.data?.type === RowType.BREAKDOWN && !table?.state?.dimensionNumbers) {
        if (node.level !== 0) {
          return false;
        }
      }

      return true;
    })
    .map(({ node, column }) => {
      const [ reportType, date ] = column.split('__');

      let val = 0;
      if (isProductProxy(node)) {
        val = parseFloat(node.aggData?.[ column ]);
      } else {
        val = parseFloat(node.data?.[ reportType ]?.[ date ]);
      }

      if (isNaN(val)) {
        return 0;
      }

      return val;
    });

  if (!justValues.length) {
    return null;
  }

  return <Portal portalId={ COMMAND_BAR_PORTAL_ID }>
    <div className={ styles.rangeSignifier }>
      {
        selectedProps.map((key) => {
          const { label, getValue } = propsAvailable[ key ];

          return <div key={ key } className={ styles.stat }>
            <span className={ styles.label }>
              { t(label) }
            </span>
            <span className={ styles.value }>
              { formatNominalValue(getValue(justValues)) }
            </span>
          </div>;
        })
      }

      <Dropdown
        placement='bottomRight'
        open={ open }
        onOpenChange={ setOpen }
        overlayClassName={ styles.dropdownContainer }
        trigger={ [ 'click' ] }
        destroyPopupOnHide={ true }
        dropdownRender={ () => 
          <RangeSignifierDropdown
            selectedProps={ selectedProps }
            setSelectedProps={ setSelectedProps }
            values={ justValues }
          />
        }
        className={ open ? styles.activeDropdownButton : '' }
      >
        <button className={ styles.dropdownTrigger }>
          <ArrowIcon className={ styles.dropdownArrow } />
        </button>
      </Dropdown>
    </div>
  </Portal>;
};

export default RangeSignifier;

const RangeSignifierDropdown = ({ selectedProps, setSelectedProps, values }) => {
  const [ t ] = useTranslation('financials');

  return <div className={ styles.dropdown }>
    {
      Object.entries(propsAvailable).map(([ key, props ]) => {
        return <div key={ key } className={ styles.stat }>
          <Checkbox
            className={ styles.checkbox }
            disabled={ isCheckboxDisabled(key as keyof PropsAvailable, selectedProps) }
            checked={ selectedProps.includes(key as keyof PropsAvailable) }
            onChange={ (e) => {
              if (e.target.checked) {
                setSelectedProps([ ...selectedProps, key as keyof PropsAvailable ]);
              } else {
                setSelectedProps(selectedProps.filter((prop) => prop !== key));
              }
            } }
          >
            <div className={ styles.checkboxContent }>
              <span className={ styles.label }>
                { t(props.label) }
              </span>
              <span className={ styles.value }>
                { formatNominalValue(props.getValue(values)) }
              </span>
            </div>
          </Checkbox>
        </div>;
      })
    }
  </div>;
};

function isCheckboxDisabled(key: keyof PropsAvailable, selectedProps: (keyof PropsAvailable)[]) {
  if (selectedProps.length > 1 && !selectedProps.includes(key)) {
    return true;
  }

  return selectedProps.length === 1 && selectedProps.includes(key);
}

function getSum (values: number[]) {
  return values.reduce((acc, value) => acc + value, 0);
}

function getAverage (values: number[]) {
  return getSum(values) / values.length;
}

function getMinValue (values: number[]) {
  return Math.min(...values);
}

function getMaxValue (values: number[]) {
  return Math.max(...values);
}

function getCount(values: number[]) {
  return values.length;
}

function formatNumber(value: number) {
  return parseFloat(value?.toFixed(2));
}

function formatNominalValue(value: number) {
  return separateThousands(formatNumber(value) ?? '');
}
