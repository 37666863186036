import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { setActiveBudgetIdCookie, setActiveOrganizationIdCookie } from 'utils/auth.utils';
import { setLatestDashboardIdCookie } from 'utils/dashboard.utils';
import SearchableSelect from '../searchableSelect/SearchableSelect';
import styles from './OrganizationSelect.module.scss';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import clsx from 'clsx';

const OrganizationSelect = () => {
  const organizations = useAppSelector(state => state.auth.user.organizations);
  const organization = useAppSelector(state => state.auth.organization);

  const options = useMemo(() => {
    return organizations
      .slice()
      .sort((a, b) => a?.name?.localeCompare(b?.name) ?? 0)
      .map(org => ({
        label: <div className={ styles.organizationLabel }>
          <div>{ org.name }</div>
          <CheckIcon />
        </div>,
        name: org.name,
        value: org.id
      }));
  }, [ organizations ]);

  const onOrganizationChange = useCallback((org: number) => {
    setActiveOrganizationIdCookie(org);
    setActiveBudgetIdCookie('');
    setLatestDashboardIdCookie('');
    window.location.reload();
  }, []);

  return (
    <SearchableSelect
      className={ clsx(styles.select, 'withBorder') }
      options={ options }
      searchBy={ (e) => e.name }
      popupClassName={ styles.popup }
      hideSearch={ options.length < 10 }
      defaultValue={ organization?.id }
      onChange={ onOrganizationChange }
    /> 
  );
};

export default OrganizationSelect;
