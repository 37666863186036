import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { BudgetItem } from 'types/budget.types';
import { Transaction } from 'types/statutory.types';
import {
  getExistingSplits,
  addDimensionId,
  uniqueBy
} from './split.utils';
import { emptyDimension } from './splits.constants';
import { sumLabels } from './validation';

const useSplitItemDefault = () => {
  const [ t ] = useTranslation('financials');
  const dimensionsItemsMap = useAppSelector(state => state.breakdowns.dimensionItemMap);

  const getDefaultValues = (items: (Transaction | BudgetItem)[], nominalValue?: number | null) => {

    if (items?.length) {
      const splits = getExistingSplits(items)
        .map(addDimensionId(dimensionsItemsMap));

      if (splits.length === 0) {
        return { dimensions: [ emptyDimension ] };
      }
      
      const groupedByDimension = groupBy(splits, 'dimension');

      const dimensions = Object.entries(groupedByDimension).map(([ dimension, dimeWithSplit ]) => {
        const uniqueLabels = uniqueBy(
          dimeWithSplit,
          (a, b) => a.dimensionItem === b.dimensionItem && a.percentage === b.percentage
        );

        const sum = sumLabels(
          uniqueLabels
            .map(item => ({ dimensionItemId: item.dimensionItem, percentage: item.percentage }))
        );

        if (sum === 100) {
          return {
            dimension: Number(dimension),
            labels: uniqueLabels
              .map((item) => ({
                dimensionItemId: item.dimensionItem,
                percentage: Number(item.percentage),
                nominalValue: (+(item.percentage) / 100) * nominalValue,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                draft: (item as any).draft
              })),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            draft: uniqueLabels.every(item => (item as any).draft)
          };
        } else {
          return { dimension: Number(dimension),
            labels: [
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              { dimensionItemId: t('dimension-split.modal.multipleLabel') as any },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              { dimensionItemId: t('dimension-split.modal.multipleLabel') as any }
            ] };
        }
      });

      return { dimensions };
      
    }

    return { dimensions: [ emptyDimension ] };
  };

  return getDefaultValues;
};

export default useSplitItemDefault;
