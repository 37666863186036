import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styles from './Editors.module.scss';
import { DATE_FORMATS } from 'utils/date.utils';

import { FieldErrors } from 'react-hook-form';

interface FiledProps {
  value: string;
  errors: FieldErrors;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DatePickerCellEditor = (props: FiledProps) => {
  const [ value, setValue ] = useState<Dayjs | null>(
    props.value ? dayjs(props.value) : null
  );
  const [ valid, setValid ] = useState(true);
  const refContainer = useRef<HTMLDivElement>(null);
  const [ open, setOpen ] = useState(false);

  const getDateInFormat = useCallback((date: Dayjs): string => {
    return date?.format('YYYY-MM-DD');
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (props.value !== value?.format('YYYY-MM-DD')) {
      setValue(props.value ? dayjs(props.value) : undefined);
    }
  }, [ props.value ]);

  const onSelect = useCallback((date: Dayjs) => {
    if (date == null) {
      setValue(null);
      props.setValue(null);
      return;
    }
    setValid(true);
    setValue(date);
    props.setValue(getDateInFormat(date));
    onClose();
  }, [ ]);

  const onKeyDown = useCallback((e) => {
    /**
     * Handle the case when the user press Enter after clearing the input
     */
    if (e.key === 'Enter') {
      const inputValue = e.target.value;
      if (inputValue === '') {
        setValue(null);
        props.setValue(getDateInFormat(null));
        onClose();
      }
    }

  }, []);

  return (
    <div ref={ refContainer }>
      <DatePicker
        onFocus={ () => setOpen(true) }
        onOpenChange={ setOpen }
        disabled={ props.disabled }
        open={ open }
        onKeyDown={ onKeyDown }
        value={ value }
        placeholder={ props.placeholder }
        format={ DATE_FORMATS }
        status={ valid ? null : 'error' }
        getPopupContainer={ trigger => trigger }
        onSelect={ onSelect }
        onChange={ onSelect }
        popupClassName='ag-custom-component-popup'
        className={ styles.datePicker }
      />
    </div>
  );
};

DatePickerCellEditor.displayName = 'DatePickerCellEditor';
export default DatePickerCellEditor;
