/* eslint-disable no-constant-condition */
import React from 'react';
import dayjs, { Dayjs, QUnitType } from 'dayjs';
import { getPickerEndDate } from 'utils/date.utils';
import {
  ColumnsSettings,
  DynamicColumnType,
  FinancialRow,
  NumberSign,
  Period,
  RowType,
  TableColumns,
  TotalColumnsSettings,
} from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { capitalize, cloneDeep, inRange } from 'lodash';
import { isProductProxy } from './financials.utils';
import { t } from 'i18next';
import isoWeek from 'dayjs/plugin/isoWeek';
import {
  HeaderClass,
  ICellRendererParams,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { FormulaNodeRowData, isFormulaNode, ReportType } from 'types/templates.types';
import { getOverlappingRange, } from 'utils/period.utils';
import ValueBadge from 'components/elements/badge/value/ValueBadge';
import styles from './styles/grid.module.scss';
import { useAppDispatch } from 'store/hooks/hooks';
import BudgetCellRenderer
  from 'components/elements/tableInlineEdit/budgetCellRenderer/BudgetCellRenderer';
import { addOrUpdateRow } from 'components/financials/utils/addRow.utils';
import FinancialCellEditor from 'components/financials/financialTable/common/FinancialCellEditor';

dayjs.extend(isoWeek);

export const AG_GRID_AUTO_COLUMN = 'ag-Grid-AutoColumn';

export const NO_DATA_ROWS: RowType[] = [ RowType.TITLE, RowType.SPACER, RowType.HALF_SPACER ];

export const BVA_COLUMNS = [
  DynamicColumnType.BUDGET_VS_ACTUAL,
  DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT,
  DynamicColumnType.BUDGET_VS_ACTUAL_TOTALS,
];

export const getColumnWidth = (row) => {
  return row.children ? getColumnWidth(row.children[ 0 ]) : row.actualWidth;
};

export type DynamicColumn = {
  date: Dayjs;
  name: string;
  type: DynamicColumnType;
  field: string;
  subtitle: string;
  reportType: ReportType;
  columnType: 'default' | 'total';
};

interface DatesSetterParams {
  type: DynamicColumnType;
  name: string;
  field: string;
  period: Period;
  subtitle: string;
}

const CADENCE_SUFFIXES = {
  week: 'W',
  month: 'M',
  quarter: 'Q',
  year: 'Y',
};

const setDatesAtActualsEnd = ({
  type,
  name,
  field,
  period,
  subtitle,
}: DatesSetterParams): DynamicColumn => {
  if (!period.startDate || !period.endDate) return null;

  const date = dayjs.unix(period.endDate).subtract(1, 'h');
  return {
    date,
    name,
    type,
    field,
    subtitle,
    reportType: ReportType.ACTUAL,
    columnType: 'default',
  };
};

const setDatesAtPlanEnd = ({
  type,
  name,
  field,
  period,
  subtitle
}: DatesSetterParams): DynamicColumn => {
  if (!period.startDatePlan || !period.endDatePlan) return null;

  const date = dayjs.unix(period.endDatePlan).subtract(1, 'h');
  return {
    date,
    name,
    type,
    field,
    subtitle,
    reportType: ReportType.PLAN,
    columnType: 'default',
  };
};

const getEndOfPeriodOrEndDate = (date: Dayjs, period: Period, reportType: ReportType) => {
  const endOfPeriod = getPickerEndDate(date, period.cadence);
  const endDate = reportType === ReportType.ACTUAL ? period.endDate : period.endDatePlan;

  return endOfPeriod.diff(dayjs.unix(endDate)) <= 0 ?
    endOfPeriod.subtract(1, 'h') : dayjs.unix(endDate).subtract(1, 'h');
};

interface IColumnSpecifiers {
  period: Period;
  type: DynamicColumnType;
  getName: (date: Dayjs) => string;
  getField: (date: Dayjs) => string;
  canAdd: (date: Dayjs) => boolean;
  start: Dayjs;
  interval: number;
  cadence: Cadence;
  subtitle: string;
  reportType: ReportType;
}

const setPeriodicDates = (
  {
    period,
    getName,
    type,
    getField,
    canAdd,
    subtitle,
    start,
    interval,
    cadence,
    reportType
  }: IColumnSpecifiers): DynamicColumn[] => {
  if (
    ((!period.startDate || !period.endDate) && reportType === ReportType.ACTUAL) ||
    ((!period.startDatePlan || !period.endDatePlan) && reportType === ReportType.PLAN)
  ) return [];
  let startDate = cloneDeep(start);
  const newDates: DynamicColumn[] = [];
  const endDate = reportType === ReportType.ACTUAL ? period.endDate : period.endDatePlan;

  while (true) {
    const date = getEndOfPeriodOrEndDate(startDate, { ...period, cadence }, reportType);
    if (canAdd(date)) {
      newDates.push({
        date,
        type,
        subtitle,
        name: getName(date),
        field: getField(date),
        reportType,
        columnType: 'default',
      });
    }
    if (date.diff(dayjs.unix(endDate), 'day') === 0) {
      break;
    }
    startDate = startDate.add(interval, cadence as QUnitType);
  }
  return newDates;
};

export const ensureDifferentDates = (dates: DynamicColumn[]): DynamicColumn[] => {
  const newDates = cloneDeep(dates);
  for (let i = 0; i < newDates.length; i++) {
    const date = newDates[ i ];
    const nextDate = newDates[ i + 1 ];
    if (nextDate && date.date.diff(nextDate.date, 'm') >= 0) {
      newDates[ i + 1 ].date = date.date.add(1, 'm');
    }
  }
  return newDates;
};

export const sortDynamicColumns = (columns: DynamicColumn[]): DynamicColumn[] => {
  const sortedColumns = cloneDeep(columns);
  const sortedTypes = Object.values(DynamicColumnType);
  sortedColumns.sort((a, b) => {
    const primarySort = a.date.diff(b.date, 'm');
    if (primarySort !== 0) return primarySort;
    const aIndex = sortedTypes.indexOf(a.type);
    const bIndex = sortedTypes.indexOf(b.type);
    return aIndex - bIndex;
  });
  return sortedColumns;
};

const sortColumnSettings = (columns: TotalColumnsSettings[]): TotalColumnsSettings[] => {
  const sortedColumns = cloneDeep(columns);
  const sortedTypes = Object.values(DynamicColumnType);
  sortedColumns.sort((a, b) => {
    const aIndex = sortedTypes.indexOf(a.type);
    const bIndex = sortedTypes.indexOf(b.type);
    return aIndex - bIndex;
  });
  return sortedColumns;
};

const setTooltipForTrialing = (type: DynamicColumnType) => {
  if (type === DynamicColumnType.TRAILING_12_MONTHS)
    return t('dynamicColumns:tooltip.trailing_12_months');
  else if (type === DynamicColumnType.TRAILING_3_MONTHS)
    return t('dynamicColumns:tooltip.trailing_3_months');
  return t('dynamicColumns:tooltip.trailing_6_months');
};

const getCadenceNumber = (date: Dayjs, cadence: Cadence) => {
  switch (cadence) {
    case Cadence.week:
      return date.isoWeek();
    case Cadence.month:
      return date.month() + 1;
    case Cadence.quarter:
      return date.quarter();
    case Cadence.year:
      return '';
    default:
      throw new Error('Invalid cadence');
  }
};

const getPeriodicCadenceField = (type: DynamicColumnType, date: Dayjs, cadence: Cadence) => {
  const cadenceSuffix = CADENCE_SUFFIXES[ cadence ];
  const cadenceNumber = getCadenceNumber(date, cadence);
  return `${ type }:${ date.year() }:${ cadenceSuffix }${ cadenceNumber }`;
};

const getPeriodicTotalField = (type: DynamicColumnType, totalField: string) => {
  return `${ type }:${ totalField }`;
};

const getHalfYearNumber = (date: Dayjs) => {
  return date.month() < 6 ? 1 : 2;
};

const getBaseDynamicColumns = (columns: TotalColumnsSettings[], period: Period) => {
  const dates: DynamicColumn[] = [];
  const isBvaSelected = columns.some(column => BVA_COLUMNS.includes(column.type));
  const overlappingRange = isBvaSelected ? getOverlappingRange(period) : null;
  const placeGrandTotals = overlappingRange ? (
    dayjs.unix(period.startDate).isSame(dayjs.unix(period.startDatePlan), 'day') &&
    dayjs.unix(period.endDate).isSame(dayjs.unix(period.endDatePlan), 'day')
  ) : true;
  const isBvaTotalsSelected = columns.some(
    column => column.type === DynamicColumnType.BUDGET_VS_ACTUAL_TOTALS
  );

  for (const column of columns) {
    const type = column.type;
    const options = column.options;
    let cadence = period.cadence;
    const isPercent = column.type.includes('%');
    const percentNominalSuffix = isPercent ? 'percent' : 'nominal';
    let params: {};

    switch (type) {
      case DynamicColumnType.GRAND_TOTAL:
        if (period.endDate && period.endDatePlan && placeGrandTotals) {
          dates.push(setDatesAtPlanEnd({
            name: t('dynamicColumns:columns.grand-total'),
            subtitle: t('dynamicColumns:columns.grand-total'),
            type,
            field: type,
            period
          }));
        }
        break;
      case DynamicColumnType.YEAR_TO_DATE:
        params = {
          name: type,
          subtitle: t('dynamicColumns:tooltip.year_to_date'),
          type,
          field: type,
          period
        };
        dates.push(setDatesAtActualsEnd(params as DatesSetterParams));
        dates.push(setDatesAtPlanEnd(params as DatesSetterParams));
        break;
      case DynamicColumnType.CALENDAR_YEAR:
        params = {
          period,
          subtitle: t('dynamicColumns:tooltip.calendar_year'),
          type,
          getName: (date: Dayjs) =>
            `${ DynamicColumnType.CALENDAR_YEAR } '${ String(date.year()).slice(-2) }`,
          getField: (date: Dayjs) => `${ type }:${ date.year() }`,
          canAdd: () => true,
          start: dayjs.unix(period.startDate).startOf(Cadence.year),
          interval: 1,
          cadence: Cadence.year,
          reportType: ReportType.ACTUAL
        };
        dates.push(...setPeriodicDates(params as IColumnSpecifiers));
        dates.push(...setPeriodicDates({
          ...params,
          reportType: ReportType.PLAN,
          start: dayjs.unix(period.startDatePlan).startOf(Cadence.year)
        } as IColumnSpecifiers));
        break;
      case DynamicColumnType.QUARTERLY_TOTAL:
        params = {
          period,
          subtitle: `${ t('dynamicColumns:columns.quarter') }`,
          getName: (date: Dayjs) => {
            return `Q${ date.quarter() } '${ String(date.year()).slice(-2) }`;
          },
          type,
          getField: (date: Dayjs) => getPeriodicCadenceField(type, date, Cadence.quarter),
          canAdd: (date: Dayjs) => {
            const isCurrentQuarterOn = options.includes(`Q${ date.quarter() }`);
            return dayjs.unix(period.startDate).isBefore(date) && isCurrentQuarterOn;
          },
          start: dayjs.unix(period.startDate)
            .startOf(Cadence.year)
            .add(1, Cadence.quarter)
            .subtract(1, 'h'),
          interval: 1,
          cadence: Cadence.quarter,
          reportType: ReportType.ACTUAL
        };
        dates.push(...setPeriodicDates(params as IColumnSpecifiers));
        dates.push(...setPeriodicDates({
          ...params,
          reportType: ReportType.PLAN,
          start: dayjs.unix(period.startDatePlan)
            .startOf(Cadence.year)
            .add(1, Cadence.quarter)
            .subtract(1, 'h'),
          canAdd: (date: Dayjs) => {
            const isCurrentQuarterOn = options.includes(`Q${ date.quarter() }`);
            return dayjs.unix(period.startDatePlan).isBefore(date) && isCurrentQuarterOn;
          },
        } as IColumnSpecifiers));
        break;
      case DynamicColumnType.HALF_YEAR:
        params = {
          period,
          type,
          subtitle: `${ t('dynamicColumns:tooltip.half_year') }`,
          getName: (date: Dayjs) =>
            `${ type }${ getHalfYearNumber(date) } '${ String(date.year()).slice(-2) }`,
          getField: (date: Dayjs) => `${ type }:${ date.year() }:H${ getHalfYearNumber(date) }`,
          canAdd: (date: Dayjs) => {
            const isCurrentHalfYearOn = options.includes(`H${ getHalfYearNumber(date) }`);
            return dayjs.unix(period.startDate).isBefore(date) && isCurrentHalfYearOn;
          },
          start: dayjs.unix(period.startDate)
            .startOf(Cadence.year)
            .add(6, Cadence.month)
            .subtract(1, 'h'),
          interval: 6,
          cadence: Cadence.month,
          reportType: ReportType.ACTUAL
        };

        dates.push(...setPeriodicDates(params as IColumnSpecifiers));
        dates.push(...setPeriodicDates({
          ...params,
          reportType: ReportType.PLAN,
          start: dayjs.unix(period.startDatePlan)
            .startOf(Cadence.year)
            .add(6, Cadence.month)
            .subtract(1, 'h'),
          canAdd: (date: Dayjs) => {
            const isCurrentHalfYearOn = options.includes(`H${ getHalfYearNumber(date) }`);
            return dayjs.unix(period.startDatePlan).isBefore(date) && isCurrentHalfYearOn;
          },
        } as IColumnSpecifiers));
        break;
      case DynamicColumnType.TRAILING_12_MONTHS:
      case DynamicColumnType.TRAILING_6_MONTHS:
      case DynamicColumnType.TRAILING_3_MONTHS:
        params = {
          name: type,
          subtitle: setTooltipForTrialing(type),
          field: type,
          type,
          period
        };

        dates.push(setDatesAtActualsEnd(params as DatesSetterParams));
        dates.push(setDatesAtPlanEnd(params as DatesSetterParams));
        break;
      case DynamicColumnType.ACTUAL_TOTAL:
        if (placeGrandTotals) {
          dates.push(setDatesAtActualsEnd({
            name: t('dynamicColumns:columns.actual-total'),
            subtitle: t('dynamicColumns:columns.actual-total'),
            field: type,
            type,
            period
          }));
        }
        break;
      case DynamicColumnType.BUDGET_TOTAL:
        if (placeGrandTotals) {
          dates.push(setDatesAtPlanEnd({
            name: t('dynamicColumns:columns.budget-total'),
            subtitle: t('dynamicColumns:columns.budget-total'),
            field: type,
            type,
            period
          }));
        }
        break;
      case DynamicColumnType.PERCENTAGE_OF_REVENUE_BASE:
        params = {
          period,
          type,
          getName: () => t('dynamicColumns:columns.percentage-of-revenue'),
          subtitle: t('dynamicColumns:tooltip.percentage-of-revenue'),
          getField: (date: Dayjs) => getPeriodicCadenceField(type, date, cadence),
          canAdd: () => true,
          start: dayjs.unix(period.startDate).startOf(cadence as QUnitType),
          interval: 1,
          cadence,
          reportType: ReportType.ACTUAL
        };
        dates.push(...setPeriodicDates(params as IColumnSpecifiers));
        dates.push(...setPeriodicDates({
          ...params,
          reportType: ReportType.PLAN,
          start: dayjs.unix(period.startDatePlan).startOf(cadence as QUnitType),
        } as IColumnSpecifiers));
        break;
      case DynamicColumnType.PERIOD_NOMINAL:
      case DynamicColumnType.YEAR_NOMINAL:
      case DynamicColumnType.PERIOD_PERCENT:
      case DynamicColumnType.YEAR_PERCENT:
        cadence = (
          type === DynamicColumnType.PERIOD_NOMINAL ||
          type === DynamicColumnType.PERIOD_PERCENT
        ) ? period.cadence : Cadence.year;

        params = {
          period,
          type,
          getName: () => {
            return t(
              `dynamicColumns:columns.${ cadence }_over_${ cadence }_${ percentNominalSuffix }`
            );
          },
          getField: (date: Dayjs) => getPeriodicCadenceField(type, date, period.cadence),
          canAdd: () => true,
          start: dayjs.unix(period.startDate).startOf(period.cadence as QUnitType),
          interval: 1,
          cadence: period.cadence,
          subtitle: percentNominalSuffix === 'percent' ?
            t('dynamicColumns:tooltip.period_over_percentage', { cadence: capitalize(cadence) }) :
            t('dynamicColumns:tooltip.period_over', { cadence: capitalize(cadence) }),
          reportType: ReportType.ACTUAL
        };

        dates.push(...setPeriodicDates(params as IColumnSpecifiers));
        dates.push(...setPeriodicDates({
          ...params,
          start: dayjs.unix(period.startDatePlan).startOf(period.cadence as QUnitType),
          reportType: ReportType.PLAN
        } as IColumnSpecifiers));
        break;
      case DynamicColumnType.BUDGET_VS_ACTUAL:
      case DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT:
        if (overlappingRange && !isBvaTotalsSelected) {
          dates.push(...setPeriodicDates({
            period,
            type,
            getName: () => t(`dynamicColumns:columns.budget_vs_actual_${ percentNominalSuffix }`),
            getField: (date: Dayjs) => getPeriodicCadenceField(type, date, cadence),
            start: dayjs.unix(overlappingRange.start).startOf(Cadence.year),
            interval: 1,
            cadence,
            subtitle: percentNominalSuffix === 'percent' ?
              t('dynamicColumns:tooltip.budget_vs_actual_percentage') :
              t('dynamicColumns:tooltip.budget_vs_actual'),
            canAdd: (date) => {
              return date.isBetween(
                dayjs.unix(overlappingRange.start),
                dayjs.unix(overlappingRange.end),
                'day',
                '[]'
              );
            },
            reportType: ReportType.ACTUAL
          }));
        }
        break;
      default:
        break;
    }
  }
  return dates;
};

export const TOTAL_COLUMNS = [
  DynamicColumnType.ACTUAL_TOTAL,
  DynamicColumnType.BUDGET_TOTAL,
  DynamicColumnType.GRAND_TOTAL,
  DynamicColumnType.QUARTERLY_TOTAL,
  DynamicColumnType.HALF_YEAR,
  DynamicColumnType.CALENDAR_YEAR,
  DynamicColumnType.TRAILING_12_MONTHS,
  DynamicColumnType.TRAILING_6_MONTHS,
  DynamicColumnType.TRAILING_3_MONTHS,
  DynamicColumnType.YEAR_TO_DATE
];

const totalColumnsFilter = (column: DynamicColumn) => {
  return TOTAL_COLUMNS.includes(column.type);
};
export const isNonTotalColumn = (column: DynamicColumn): boolean => {
  return !TOTAL_COLUMNS.includes(column.type) && column.columnType === 'default';
};

interface ColumnTotalsParams {
  totalColumns: DynamicColumn[];
  getField: (column: DynamicColumn) => string;
  getName: (date: Dayjs) => string;
  type: DynamicColumnType;
  subtitle: string;
}

const addColumnsForTotals = ({
  totalColumns,
  getField,
  getName,
  type,
  subtitle,
}: ColumnTotalsParams) => {
  const columns: DynamicColumn[] = [];

  for (const column of totalColumns) {
    const columnDate = column.date.add(1, 'm');
    columns.push({
      date: columnDate,
      name: getName(columnDate),
      field: getField(column),
      type,
      subtitle,
      reportType: column.reportType,
      columnType: 'total',
    });
  }

  return columns;
};

export const getDynamicColumnsForTotals = (
  dates: DynamicColumn[],
  columns: TotalColumnsSettings[]
): DynamicColumn[] => {
  const totalColumns = dates.filter(totalColumnsFilter);
  const datesForTotals: DynamicColumn[] = [];

  for (const column of columns) {
    const isPercent = column.type.includes('%');
    const percentNominalSuffix = isPercent ? 'percent' : 'nominal';

    switch (column.type) {
      case DynamicColumnType.BUDGET_VS_ACTUAL:
      case DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT:
        if (totalColumns.some(_column => _column.type === DynamicColumnType.BUDGET_TOTAL)) {
          datesForTotals.push(...addColumnsForTotals({
            totalColumns: totalColumns.filter(
              (_column) => _column.type === DynamicColumnType.ACTUAL_TOTAL
            ),
            getField: (_column) => getPeriodicTotalField(column.type, _column.field),
            getName: () => t(`dynamicColumns:columns.budget_vs_actual_${ percentNominalSuffix }`),
            type: column.type,
            subtitle: isPercent ? t('dynamicColumns:tooltip.budget_vs_actual_percentage') :
              t('dynamicColumns:tooltip.budget_vs_actual'),
          }));
        }
        break;
      case DynamicColumnType.PERCENTAGE_OF_REVENUE_TOTALS:
        datesForTotals.push(...addColumnsForTotals({
          totalColumns,
          getName: () => t('dynamicColumns:columns.percentage-of-revenue'),
          subtitle: t('dynamicColumns:tooltip.percentage-of-revenue'),
          getField: (_column) => getPeriodicTotalField(column.type, _column.field),
          type: column.type,
        }));
        break;
      default:
        break;
    }
  }

  return datesForTotals;
};

export const getDynamicColumnDates = (settings: ColumnsSettings, period: Period) => {
  if (!settings) return [];
  const sortedColumns = sortColumnSettings(settings.columns);
  let dates: DynamicColumn[] = getBaseDynamicColumns(sortedColumns, period);
  dates = dates.filter(date => date);
  dates = sortDynamicColumns(dates);
  dates = ensureDifferentDates(dates);

  const columnsForTotals = getDynamicColumnsForTotals(dates, sortedColumns);
  dates.push(...columnsForTotals);
  dates = sortDynamicColumns(dates);
  dates = ensureDifferentDates(dates);

  return cloneDeep(dates);
};

interface AddColumnParams {
  columns: TableColumns;
  index: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editable?: boolean | ((params: any) => boolean);
  isFirst: boolean;
  field: string;
  headerName: string;
  headerClass?: HeaderClass;
  cadence: Cadence;
  subtitle?: string;
  year: string;
  // eslint-disable-next-line no-undef
  headerComponent: (props) => JSX.Element;
  isDynamicColumn?: boolean;
  reportType?: ReportType;
  conditionalFormatting?: boolean;
}

export const getFieldType = (field: string) => {
  if (field.includes(ReportType.ACTUAL)) return ReportType.ACTUAL;

  return ReportType.PLAN;
};

export const trimReportType = (field: string, reportType: ReportType) => {
  return !field.includes(reportType) ? field : field.replace(`${ reportType }__`, '');
};

export const isChangeDynamicColumn = (field: string) => {
  return [
    DynamicColumnType.BUDGET_VS_ACTUAL,
    DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT,
    DynamicColumnType.PERIOD_NOMINAL,
    DynamicColumnType.PERIOD_PERCENT,
    DynamicColumnType.YEAR_NOMINAL,
    DynamicColumnType.YEAR_PERCENT,
  ].some(type => field.includes(type));
};

const showChangeRenderer = (
  field: string,
  isDynamicColumn: boolean,
  conditionalFormatting: boolean
) => {
  if (!isDynamicColumn || !conditionalFormatting) {
    return false;
  }
  return isChangeDynamicColumn(field);
};

const PERCENTAGE_NEUTRAL_CHANGE = 0.1 / 100;
const NOMINAL_NEUTRAL_CHANGE = 100;

const getSign = (value: number, isPercent: boolean): NumberSign => {
  if (
    (value == null) ||
    (isPercent && inRange(value, -PERCENTAGE_NEUTRAL_CHANGE, PERCENTAGE_NEUTRAL_CHANGE)) ||
    (!isPercent && inRange(value, -NOMINAL_NEUTRAL_CHANGE, NOMINAL_NEUTRAL_CHANGE))
  ) {
    return 'neutral';
  }
  return value > 0 ? 'positive' : 'negative';
};

const getReversedSign = (sign: Omit<NumberSign, 'neutral'>) => {
  return sign === 'positive' ? 'negative' : 'positive';
};

const changeCellRenderer = (params: ICellRendererParams) => {
  if (!params.valueFormatted || !params.valueFormatted.trim()) return undefined;

  const sign = getSign(params.value, params.valueFormatted.includes('%'));
  if (sign === 'neutral') {
    return params.valueFormatted;
  }
  const rowData = params.node?.data?.rowData;
  const isFormula = isFormulaNode(params.node.data);
  const reverseSign = isFormula && (rowData as FormulaNodeRowData).reverseChangesFormatting;

  return <ValueBadge
    value={ params.valueFormatted }
    sign={ reverseSign ? getReversedSign(sign) : sign }
    className={ styles.changeBadge }
    size='small'
  />;
};

const basicCellRenderer = (params: ICellRendererParams) => {
  if (
    params.node?.data?.type === RowType.NEW_BUDGET_ITEM &&
    !params.colDef?.headerComponentParams?.isDynamicColumn &&
    params.colDef?.field?.startsWith('plan')
  ) {
    return <BudgetCellRenderer { ...params } >
      { params.formatValue(params.getValue()) }
    </BudgetCellRenderer>;
  }

  return params.valueFormatted;
};

export const addColumn = ({
  columns,
  index,
  isFirst,
  field,
  year,
  headerName,
  headerClass,
  subtitle,
  cadence,
  editable = false,
  isDynamicColumn = false,
  headerComponent,
  reportType = ReportType.ACTUAL,
  conditionalFormatting,
}: AddColumnParams) => {
  columns[ index ] = {
    field,
    year,
    editable,
    isFirst,
    headerClass,
    reportType,
    suppressMovable: true,
    aggFunc: params => {
      const value = params.rowNode.data && params.rowNode.data[ params.column.getColId() ];
      if (!isProductProxy(params.rowNode)) {
        return value;
      }
      if (value) {
        return value;
      }
      let total = 0;
      let hadValue = false;
      params.values.forEach((v) => {
        if (!isNaN(v) && (v || v == 0)) {
          hadValue = true;
          total += Number(v);
        }
      });
      return hadValue ? total : null;
    },
    headerName,
    type: 'valueColumn',
    filter: 'agNumberColumnFilter',
    headerComponentParams: {
      cadence,
      year,
      subtitle,
      isDynamicColumn
    },
    headerComponent,
    cellEditor: FinancialCellEditor,
    valueGetter: (params: ValueGetterParams<FinancialRow>) => {
      const trimmedField = trimReportType(field, reportType);
      if (field === DynamicColumnType.GRAND_TOTAL) {
        const actualsGrand =
          params.data[ ReportType.ACTUAL ] && params.data[ ReportType.ACTUAL ][ field ] || 0;
        const planGrand =
          params.data[ ReportType.PLAN ] && params.data[ ReportType.PLAN ][ field ] || 0;
        if (planGrand) {
          return planGrand;
        } else {
          return actualsGrand === 0 ? undefined : actualsGrand;
        }
      }
      if (field === 'REVENUE%_TOTALS:GRAND') {
        const actualsPercentage =
          params.data[ ReportType.ACTUAL ] && params.data[ ReportType.ACTUAL ][ field ] || 0;
        const planPercentage =
          params.data[ ReportType.PLAN ] && params.data[ ReportType.PLAN ][ field ] || 0;
        if (planPercentage) {
          return planPercentage;
        } else {
          return actualsPercentage === 0 ? undefined : actualsPercentage;
        }
      }
      return params.data[ reportType ] ?
        params.data[ reportType ][ trimmedField ] : null;
    },
    valueSetter: (params: ValueSetterParams<FinancialRow>) => {
      const trimmedField = trimReportType(field, reportType);
      const reportData = params.data[ reportType ] || {};
      const newValue = params.newValue;
      const oldValue = reportData[ trimmedField ];

      if (newValue === oldValue) {
        return false;
      }

      if (typeof newValue === 'object' && Reflect.has(newValue, 'clipboard')) {
        const reversed = newValue.clipboard.slice().reverse();
        reversed.forEach((newValuesRow) => {
          addOrUpdateRow(newValuesRow, params, field, reportType);

          return false;
        });
      } else {
        return addOrUpdateRow([ newValue ], params, field, reportType);
      }

    },
    suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
      if (params.event.code === 'Delete' || params.event.code === 'Backspace') {
        return true;
      }

      if (params.event.code === 'Enter' && (!params.editing || params.event.shiftKey)) {
        return true;
      }

      return false;
    },
    cellRenderer: showChangeRenderer(
      field,
      isDynamicColumn,
      conditionalFormatting
    ) ? changeCellRenderer : basicCellRenderer,
  };
};

interface AddDynamicColumnParams {
  columns: TableColumns;
  column: DynamicColumn;
  offset: number;
  cadence: Cadence;
  // eslint-disable-next-line no-undef
  headerComponent: (props) => JSX.Element;
  headerClass?: HeaderClass;
  reportType: ReportType;
  year: string;
  conditionalFormatting: boolean;
  dispatch: ReturnType<typeof useAppDispatch>;
}

export const addDynamicColumn = ({
  column,
  columns,
  offset,
  headerComponent,
  reportType,
  cadence,
  headerClass,
  year,
  conditionalFormatting
}: AddDynamicColumnParams) => {
  const field = column.field;
  const index = column.date.add(offset, 'h').toString();
  const subtitle = column.subtitle;
  addColumn({
    columns,
    index,
    isFirst: false,
    subtitle,
    field,
    year,
    headerName: column.name,
    headerClass,
    isDynamicColumn: true,
    cadence,
    headerComponent,
    reportType,
    conditionalFormatting
  });
};

export const placeDynamicColumnInReport =
  (type: DynamicColumnType, reportTypes: ReportType[], isBothOpened: boolean): boolean => {
    switch (type) {
      case DynamicColumnType.PERCENTAGE_OF_REVENUE_BASE:
      case DynamicColumnType.PERCENTAGE_OF_REVENUE_TOTALS:
      case DynamicColumnType.PERIOD_NOMINAL:
      case DynamicColumnType.YEAR_NOMINAL:
      case DynamicColumnType.PERIOD_PERCENT:
      case DynamicColumnType.YEAR_PERCENT:
      case DynamicColumnType.ACTUAL_TOTAL:
      case DynamicColumnType.QUARTERLY_TOTAL:
      case DynamicColumnType.HALF_YEAR:
      case DynamicColumnType.YEAR_TO_DATE:
      case DynamicColumnType.CALENDAR_YEAR:
      case DynamicColumnType.FISCAL_YEAR:
      case DynamicColumnType.TRAILING_12_MONTHS:
      case DynamicColumnType.TRAILING_6_MONTHS:
      case DynamicColumnType.TRAILING_3_MONTHS:
        return true;
      case DynamicColumnType.BUDGET_TOTAL:
        return reportTypes.includes(ReportType.PLAN);
      case DynamicColumnType.GRAND_TOTAL:
        return isBothOpened && reportTypes.includes(ReportType.PLAN);
      case DynamicColumnType.BUDGET_VS_ACTUAL:
      case DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT:
      case DynamicColumnType.BUDGET_VS_ACTUAL_TOTALS:
        return isBothOpened &&
          reportTypes.includes(ReportType.ACTUAL) &&
          reportTypes.includes(ReportType.PLAN);
      default:
        return false;
    }
  };
