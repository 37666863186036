// eslint-disable-next-line no-undef
const apiUrl = process.env.REACT_APP_API_URL;
// eslint-disable-next-line no-undef
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
// eslint-disable-next-line max-len
const agGridLicence = 'Using_this_{AG_Grid}_Enterprise_key_{AG-051624}_in_excess_of_the_licence_granted_' +
  'is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_' +
  'changing_this_key_please_contact_info@ag-grid.com___{Caleido_Ltd}_is_granted_a_{' +
  'Single_Application}_Developer_License_for_the_application_{app.caleido.io}_only_for_{1}_Front-' +
  'End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_' +
  '{app.caleido.io}_need_to_' +
  'be_licensed___{app.caleido.io}_has_been_granted_a_Deployment_License_Add-on_for_' +
  '{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_' +
  'before_{9_February_2025}____[v3]_[01]_MTczOTA1OTIwMDAwMA==d6f9148de1f9c015886b40d42ca2826d';

const routes = {
  home: '/',
  login: '/login',
  comingSoon: 'coming-soon',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
  financialView: '/view/',
  dashboard: '/dashboard',
  inputs: '/inputs',
  report: {
    templateBuilder: '/report/template-builder',
  },
  manage:{
    revenueRecognition: '/manage/revenue-recognition/',
    contracts: '/contracts',
    labelingRules: '/labeling-rules/',
  },
  settings: {
    index: '/settings',
    integrations: '/settings/integrations',
    global: '/settings/global',
    accountsMapping: '/accounts-mapping',
  },
};

const endpoints = {
  users: {
    me: '/users/me/',
    login: '/users/login/',
    logout: '/users/logout/',
    token: {
      refresh: '/users/token/refresh/',
      verify: '/users/token/verify/'
    },
    password: {
      reset: '/users/password/reset/',
      confirm: '/users/password/reset/confirm/'
    },
    websocket: {
      token: '/users/ws/token/'
    }
  },
  customView:{
    view: '/custom-views/views/{id}/',
    sections:'/custom-views/views/sections/'
  },
  integrations: {
    retry: {
      procountor: '/integrations/procountor/{id}/retry/',
      netvisor: '/integrations/netvisor/{id}/retry/',
      xero: '/integrations/xero/{id}/retry/',
    },
    procountor: '/integrations/procountor/',
    xero: '/integrations/xero/',
    netvisor: '/integrations/netvisor/',
    netvisorUpdate: '/integrations/netvisor/{id}/',
    trigger: {
      procountor: '/integrations/procountor/{id}/synchronize/',
      netvisor: '/integrations/netvisor/{id}/synchronize/',
      xero: '/integrations/xero/{id}/synchronize/',
    }
  },
  organizations: {
    integrations: '/integrations/integrations/',
    chatbotConsent: '/organizations/chatbot_consent/',
    accounts: '/organizations/accounts/',
    accountsRoles: '/organizations/accounts/required/',
    dimensions: '/organizations/dimensions/',
    dimensionsLabelingProgress: '/organizations/dimensions/labeling_progress/',
    dimension: '/organizations/dimensions/{id}/',
    dimensionItems:'/organizations/dimensions/{id}/add_item/',
    deleteDimensionItem:'/organizations/dimension_items/{id}/',
    dimensionToggleFavourite: '/organizations/dimensions/{id}/favorite/',
    transactions: {
      count: '/organizations/transaction_lines/count/',
      list: '/organizations/transaction_lines/',
      recognizedList: '/organizations/transaction_lines/recognized_list/',
      table: '/organizations/transaction_lines/table/',
      ssrm: '/organizations/transaction_lines/ssrm/',
      ssrmSum: '/organizations/transaction_lines/ssrm_sum/',
      addDimension: '/organizations/transaction_lines/add_dimension/',
      addProduct: '/organizations/transaction_lines/add_product/',
      addCounterparty: '/organizations/transaction_lines/add_counterparty/',
      clearDimensions: 'organizations/transaction_lines/clear_dimensions/',
      assignCounterparty: '/organizations/counterparties/assign_dimensions/',
      clearProduct: '/organizations/transaction_lines/clear_product/',
      getInvoice: '/organizations/transaction_lines/{id}/get_pdf/',
      bulkLabel: '/organizations/transaction_lines/bulk_label/',
      getSingleTransaction: '/organizations/transaction_lines/{id}/'
    },
    counterparty: {
      list: '/organizations/counterparties/',
      revenueRecognitionList: '/organizations/counterparties/revenue_recognition_list/',
      counterpartiesList: '/organizations/counterparties/',
      detail: '/organizations/counterparties/{id}/',
      assignTransactions: '/organizations/counterparties/{id}/assign_transaction_lines/',
    },
    products: {
      index: '/organizations/products',
      edit: '/organizations/products/{id}/',
      add: '/organizations/products/',
      assignTransactions: '/organizations/products/{id}/assign_transactions/',
      delete:'/organizations/products/{id}/',
    },
  },
  reports: {
    systemTemplates:'/reports/system-templates/',
    systemTemplate:'/reports/system-templates/{id}/',
    userTemplates: '/reports/user-templates/',
    userTemplate: '/reports/user-templates/{id}/',
    data: '/reports/reports/{id}/',
    stream: '/reports/reports/{id}/stream/',
    columnsSettings: '/reports/reports/{id}/columns_settings/',
    favorites: '/reports/reports/{id}/favorites/',
    presets: '/reports/user-templates/presets/',
    preset: '/reports/user-templates/presets/{id}/',
    toggleVisibility:'/custom-views/views/{id}/set_visible_templates/',
    reportFilters:{
      index: '/reports/reports/{id}/filters/'
    },
    managerialReports: {
      index: '/reports/order/managerial/',
      update: '/reports/order/managerial/update_order/',
    },
    statutory: {
      index: '/reports/order/statutory/',
      update: '/reports/order/statutory/update_order/',
    }
  },
  statutory: {
    statements: {
      base: '/statutory/statements/',
      byId: '/statutory/statements/{id}/',
      byType: '/statutory/statements/by-type/',
    },
    template: {
      base: '/statutory/template/',
      one: '/statutory/template/{id}/',
      byType: '/statutory/template/by-type/',
      associations: {
        base: '/statutory/template/associations/',
        remove: '/statutory/template/associations/remove/',
        unassigned: '/statutory/template/associations/unassigned/',
      },
    }
  },
  revenueRecognition: {
    transactions: {
      assignDeal: '/revenue-recognition/transaction-lines/{id}/deal_type/'
    },
    recognizeRevenue: '/revenue-recognition/transaction-lines/{id}/revenue_recognition/',
  },
  labeling: {
    rules: {
      index: '/labeling/rules/',
      detail: '/labeling/rules/{id}/',
      remove: '/labeling/rules/remove_rule/',
      bulkCreate: '/labeling/rules/bulk_create/',
      transactionLines: '/labeling/rules/{id}/transaction_lines/',
      bulkDelete: '/labeling/rules/bulk_delete'
    }
  },
  planning: {
    settings: '/planning/settings/',
    refreshPlan: '/planning/settings/refresh_plan/',
    restoreDefaults: '/planning/settings/restore_defaults/',
    generatePayments: '/planning/settings/generate_receivable_payments/',
    budgets: '/planning/budgets/',
    budgetItemTypes: '/planning/budget_item_types/',
    budgetItems: {
      index: '/planning/budget_items/',
      detail: '/planning/budget_items/{id}/',
      batchUpdate: '/planning/budget_items/bulk/',
      aiCompletion: '/planning/budget_items/ai_completion/',
      clearDimensions: '/planning/budget_items/clear_dimension/',
      bulkDelete: '/planning/budget_items/bulk_delete/',
      table: '/planning/budget_items/table/',
    },
  },
  contracts: {
    index: '/contracts/contracts/',
    detail: '/contracts/contracts/{id}/',
    customers: '/contracts/customers/',
    revenueRecognitionList: '/contracts/customers/revenue_recognition_list/',
    contractsReport: '/contracts/customers/{id}/contracts_report/',
    wizard: '/contracts/contracts/wizard/',
  },
  dashboard: {
    index: '/dashboard/dashboards/',
    detail: '/dashboard/dashboards/{id}/',
    elementDetail: '/dashboard/elements/{id}/',
    preview: '/dashboard/elements/preview/',
    dashboards: '/dashboard/dashboards/'
  },
  inputs: {
    index: '/inputs/inputs/',
    detail: '/inputs/inputs/{id}/',
    categories: '/inputs/categories/',
    values: '/inputs/inputs/values/',
    bulk: '/inputs/inputs/bulk/',
  }
};

const websockets = {
  events: '/organizations/events/{id}/'
};

export {
  apiUrl,
  agGridLicence,
  routes,
  endpoints,
  websocketUrl,
  websockets
};
