import { DriversCategory } from 'types/financials.types';
import { PlanSettings } from 'types/planning.types';
import * as yup from 'yup';
import { t } from 'i18next';
import dayjs from 'dayjs';
import {
  getRangeValidator,
  REQUIRED_VALIDATOR,
} from 'utils/valdation.utils';

export const defaultSettings: PlanSettings = {
  [ DriversCategory.GENERAL ]: {
    planStart: {
      value: dayjs().format('MMM YYYY'),
      name: t('settings.planStartMonth.name', { ns: 'planning' }),
      description: t('settings.planStartMonth.description', { ns: 'planning' }),
    },
    planDurationMonths: {
      value: 24,
      name: t('settings.planDurationMonths.name', { ns: 'planning' }),
    },
  },
};

export const validationSchema = yup.object().shape({
  [ DriversCategory.GENERAL ]: yup.object().shape({
    planStart: yup.object().shape({
      value: REQUIRED_VALIDATOR,
    }),
    planDurationMonths: yup.object().shape({
      value: getRangeValidator(1, 240)
    }),
  }),
});
