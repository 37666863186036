import React, { MutableRefObject, useEffect, useState } from 'react';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import DimensionSplitModal from '../modals/dimensionSplitModal/DimensionSplitModal';
import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';
import { Transaction } from 'types/statutory.types';

import styles from './Split.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { BudgetItem } from 'types/budget.types';
import { IServerSideGroupSelectionState } from 'ag-grid-community';
import { SplitCloseMessages, SplitMessages } from '../modals/dimensionSplitModal/splits.constants';

interface Props {
  gridRef: MutableRefObject<AgGridReact>;
  selectionChanged: MutableRefObject<() => void>;
  onSplitsUpdated: (data?: { updated: BudgetItem[] }) => void;
}

const Split = ({ gridRef, selectionChanged, onSplitsUpdated }: Props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ splitItems, setSplitItems ] = useState<(Transaction | BudgetItem)[]>([]);

  const onClose = (message?: SplitCloseMessages, data?: { updated: BudgetItem[] }) => {
    setIsOpen(false);
    if (message || data?.updated) {
      onSplitsUpdated(data);
    }
  };

  const onMessage = (message: SplitMessages, data?: { updated: BudgetItem[] }) => {
    if (message === 'split:dimensionRemoved') {
      onSplitsUpdated(data);
    }
  };

  useEffect(() => {
    selectionChanged.current = (areAllLoaded?: boolean) => {
      if (gridRef.current.props.rowModelType === 'serverSide') {
        const serverSideSelected = gridRef.current.api.getServerSideSelectionState();

        if (serverSideSelected) {
          const selectedNodes = [];
          gridRef.current.api.forEachNode(node => {
            if (isServerSideGroupSelectionState(serverSideSelected)) {
              const isInList = serverSideSelected.toggledNodes
                .find(toggledNode => toggledNode.nodeId === node.id);

              if (serverSideSelected.selectAllChildren) {
                if (areAllLoaded) {
                  if (!isInList) selectedNodes.push(node.data); 
                }
              } else {
                if (isInList) selectedNodes.push(node.data);
              }
            } else {
              const isInList = serverSideSelected.toggledNodes.includes(node.id);
              if (serverSideSelected.selectAll) {
                if (areAllLoaded) {
                  if (!isInList) selectedNodes.push(node.data);
                }
              } else {
                if (isInList) selectedNodes.push(node.data);
              }
            }
          });

          setSplitItems(selectedNodes.flat());
        }

      } else {
        setSplitItems(gridRef.current.api.getSelectedRows());
      }
    };
  }, [ ]);

  return (
    <>
      <GhostIconButton
        disabled={ !splitItems || splitItems?.length === 0 }
        onClick={ () => setIsOpen(true) }
        className={ styles.button }>
        <SplitIcon />
      </GhostIconButton>
      { splitItems?.length > 0 && <DimensionSplitModal
        splitItems={ splitItems?.filter(Boolean) }
        isOpen={ isOpen }
        onMessage={ onMessage }
        onClose={ onClose }
      /> }
      
    </>
  );
};

export default Split;

function isServerSideGroupSelectionState(arg: unknown): arg is IServerSideGroupSelectionState {
  return (arg as IServerSideGroupSelectionState).selectAllChildren !== undefined;
}
