import { ColumnState } from 'ag-grid-community';
import React, { createContext, PropsWithChildren, useContext } from 'react';

interface TableContextValues {
  defaultSorting: ColumnState[];
  sortState: ColumnState[];
  setSortState: (sorting: ColumnState[]) => void;
}

const TableContext = createContext<TableContextValues>(undefined);

interface Props {
  defaultSorting?: ColumnState[];
}

const TableContextProvider = ({ children, defaultSorting }: PropsWithChildren<Props>) => {
  const [ sortState, setSortState ] = React.useState<ColumnState[]>(defaultSorting || []);

  return <TableContext.Provider value={ {
    defaultSorting,
    sortState,
    setSortState
  } }>
    { children }
  </TableContext.Provider>;
};

const useTableContext = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error('useFinancialTable must be used within Provider');
  }
  return context;
};

export { TableContextProvider, useTableContext, TableContext };
