import React, { useCallback, useState } from 'react';
import {
  changePanelContextIfOpen,
  clearDetailedViewSettings,
  financialsSlice,
  removeViewItem,
  selectPanelTable,
  selectPeriod,
  setOneVisible,
  toggleVisibilityItem,
} from 'store/financials.slice';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './TableSelectItem.module.scss';
import { UserTemplateShort } from 'types/templates.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import type { ViewItem } from 'types/financials.types';
import { getCapsuleId } from '../tableSelect.utils';
import {
  ContextMenuItem,
  ContextMenuType } from 'components/elements/contextMenu/contextMenu.types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/newTab.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart-outline.svg';
import { ReactComponent as TableIcon } from 'assets/icons/column.svg';
import { ReactComponent as AnyIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/visibility-off.svg';
import { ReactComponent as InputsIcon } from 'assets/icons/column.svg';

import ContextMenu from 'components/elements/contextMenu/ContextMenu';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { selectCapsuleList } from 'store/financials.slice';
import { routes } from 'utils/config.utils';
import { TemplateType } from 'types/templates.types';
import { reportsService } from 'services/reports.services';
import { selectAllTemplates, selectSystemTemplate, templatesSlice } from 'store/templates.slice';
import { arrayToMap } from 'utils/mapping.utils';
import { getUUID, parseTemplateRequestData } from 'utils/templates.utils';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import clsx from 'clsx';
import { scrollViewItemIntoView } from 'utils/financials.utils';
import { INPUT_TEMPLATE_ID } from 'components/inputs/utils/inputs.utils';
import {
  useWarnOnUnsaved
} from '../../../../financialTable/hooks/useWarnOnUnsaved/useWarnOnUnsaved';

type Props = {
  item: ViewItem;
};

const TypeIconsMap = {
  'chart': <ChartIcon />,
  'table': <TableIcon />,
  'inputs': <InputsIcon />
};

const TableSelectItem = ({ item }: Props) => {
  const [ t ] = useTranslation('financials');
  const templateId = item.templateId;

  const dispatch = useAppDispatch();
  const panelTable = useAppSelector(selectPanelTable);
  const viewItems = useAppSelector(selectCapsuleList);
  const systemTemplates = useAppSelector(selectSystemTemplate);
  const template = useAppSelector(state => {
    if (item.type === 'inputs') return { ...item, title: t('template-dropdown.element.input') };
    return selectAllTemplates(state).find(temp => temp.id === templateId);
  });
  const templates = useAppSelector((state) => state.templates.templateList);
  const viewId = useAppSelector((state) => state.financials.viewId);
  const activeViewItem = useAppSelector(state => state.financials.active);

  const tableHasNewCells = useAppSelector((state) =>
    state.financials.tables[ templateId ]?.hasNewCells);

  const anyOtherTableHasNewCells = useAppSelector(state =>
    Object.entries(state.financials.tables)
      .some(([ key, value ]) => parseInt(key) !== templateId && value.hasNewCells)
  );

  const period = useAppSelector(selectPeriod(item.templateId));
  
  const [ isContextMenuVisible, setIsContextMenuVisible ] = useState(false);
  const [ params, setParams ] = useSearchParams();

  const getTitle = () => {
    return template ? template.title : '';
  };

  const { refetch } = useReportQuery({ templateId,
    period,
    shouldFetch: isContextMenuVisible
  });

  const toggleItemSelect = useCallback(() => {
    if (!item.isShown) {
      toggleVisibility();
    }

    if (params.get('active')) {
      params.delete('active');
      setParams(params);
    }

    dispatch(financialsSlice.actions.setActive({ templateId, type: item.type }));

    setTimeout(() => scrollViewItemIntoView(item.type, item.templateId));
  }, [ item.isShown, templateId, params, template, setParams ]);

  const copyTemplate = useCallback(async (id: number) => {
    const { data } = await reportsService.getSystemTemplate(id);
    const nodes = data.nodes.map((node) => ({ ...node, uuid: getUUID() }));
    const newTemplateData = {
      title: t('template-dropdown.copy-of', { title: data.title }),
      subtitle: data.subtitle,
      roots: data.roots,
      nodes: arrayToMap(nodes, n => n.id),
    };

    let maxCopyNumber = 0;
    templates.forEach((tem) => {
      if (tem.title.toLowerCase().includes(data.title.toLowerCase())) {
        const match = tem.title.match(/(\d+)$/);
        const num = match ? parseInt(match[ 1 ], 10) : 0;
        maxCopyNumber = Math.max(maxCopyNumber, num);
      }
    });
    const nextCopyNumber = maxCopyNumber + 1;

    newTemplateData.title += nextCopyNumber > 1 ? ` ${ nextCopyNumber }` : '';

    const { data: newData } =
      await reportsService
        .addUserTemplate(parseTemplateRequestData(newTemplateData));
    return newData;
  }, [ template, templates ]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: getCapsuleId(item) });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const selectFirstVisibleItem = () => {
    const firstVisible = viewItems.filter(
      viewItem => viewItem.templateId !== templateId && viewItem.isShown).at(0);
    if (firstVisible) {
      dispatch(financialsSlice.actions.setActive(firstVisible));
    } else {
      dispatch(financialsSlice.actions.clearActiveTable());
    }
  };

  const disableTable = (remove: boolean) => {
    if (remove) {
      dispatch(removeViewItem(item));
    } else {
      dispatch(toggleVisibilityItem(item));
    }
    dispatch(financialsSlice.actions.removeTables([ templateId ]));

    if (templateId === activeViewItem?.templateId) {
      selectFirstVisibleItem();
    }
  };

  const { warnModal: disableTableWarnModal, continueWithCheck: disableWithCheck } = 
    useWarnOnUnsaved<{ remove: boolean}>({
      onConfirm: ({ remove }) => disableTable(remove),
      isUnsaved: tableHasNewCells
    });

  const toggleVisibility = () => {
    if (item.isShown) {
      disableWithCheck({ remove: false });
    } else {
      dispatch(toggleVisibilityItem(item));
      dispatch(financialsSlice.actions.addTables([ templateId ]));
      dispatch(changePanelContextIfOpen(templateId, item.type));
    }

    if (templateId && !template) {
      dispatch(financialsSlice.actions.addTables([ templateId ]));
    }

    dispatch(clearDetailedViewSettings());
  };

  const { warnModal: showOnlyThisWarnModal, continueWithCheck: showOnlyThisWithCheck } = 
    useWarnOnUnsaved<void>({
      onConfirm: () => dispatch(setOneVisible(item)),
      isUnsaved: anyOtherTableHasNewCells,
    });

  const getContextMenuItems = () => {
    const options: ContextMenuItem[] = [];

    options.push({
      name: t('context-menu.remove'),
      icon: <CloseIcon />,
      action: () => {
        disableWithCheck({ remove: true });
      },
    });

    options.push({
      name: t('context-menu.toggle-visibility'),
      icon: <EyeIcon />,
      action: () => {
        toggleVisibility();
      },
    });
    if (viewId) {
      options.push({
        name: t('context-menu.open-new-tab'),
        icon: <NewTabIcon />,
        action: () => {
          open(`/view/${ viewId }?active=${ templateId ?? INPUT_TEMPLATE_ID }`);
        },
      });
    }

    options.push({
      name: t('context-menu.show-only-this'),
      icon: <EyeIcon />,
      action: () => {
        showOnlyThisWithCheck();
      },
    });

    if (item.type === 'table') {
      options.push({
        name: t('context-menu.edit-new-tab'),
        icon: <EditIcon />,
        action: async () => {
          if (systemTemplates.find((el) => el.id === templateId &&
            el.type === TemplateType.SYSTEM_DEFAULT || el.type === TemplateType.SYSTEM)) {
            const newTemplateData = await copyTemplate(templateId);

            const newTemplate: UserTemplateShort = {
              title: newTemplateData.title,
              subtitle: newTemplateData.subtitle ?? '',
              recordTitle: null,
              id: newTemplateData.id,
              type: TemplateType.USER,
              favorite: false,
            };
            dispatch(templatesSlice.actions.addTemplate( newTemplate ));
            open(`${ routes.report.templateBuilder }/${ newTemplate.id }`);
          } else {
            open(`${ routes.report.templateBuilder }/${ templateId }`);
          }
        },
      });
      options.push({
        name: t('context-menu.refresh'),
        icon: <RefreshIcon />,
        action: () => {
          if (item.isShown) {
            refetch();
          }
        },
        disabled: !item.isShown,
      });
    }

    return options;
  };

  return (
    <ContextMenu
      items={ getContextMenuItems() }
      isVisible={ isContextMenuVisible }
      type={ ContextMenuType.CAPSULE }
      onVisibleChange={ (isVisible) => setIsContextMenuVisible(isVisible) }
    > 
      { template ?
        <span
          ref={ setNodeRef }
          className={ styles.itemsContainer }
          { ...listeners }
          { ...attributes }
          style={ { ...style } } >
          <div
            onClick={ toggleItemSelect }
            className={ clsx(styles.item, {
              [ styles.active ]: activeViewItem?.templateId === templateId,
              [ styles.inactive ]: panelTable !== null && panelTable !== templateId
            } ) }>
            <DragIcon />
            { TypeIconsMap[ item.type ] ?? <AnyIcon /> }
            <span className={ styles.itemName }>{ getTitle() }</span>
            { !item.isShown && <EyeOffIcon /> }
          </div>
          { disableTableWarnModal }
          { showOnlyThisWarnModal }
        </span> : null }
    </ContextMenu>
  );
};

export default TableSelectItem;
