import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import OrganizationSelect from 'components/elements/organizationSelect/OrganizationSelect';
import styles from './TopBar.module.scss';
import { Period } from 'types/financials.types';
import { DASHBOARD_PERIOD_ID } from 'utils/dashboard.utils';
import { setDefaultPeriod, setRangeType } from 'utils/period.utils';
import RangePickerModal from 'components/elements/rangePicker/RangePickerModal';
import { topBarSlice } from 'store/topBar.slice';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import { setActiveBudgetIdCookie } from 'utils/auth.utils';
import { WorkspaceDropdown } from './workspaceDropdown/WorkspaceDropdown';
import BudgetGenerationInProgress
  from 'components/financials/budgetGenerationProvider/messages/BudgetGenerationInProgress';
import { clearBudgetGeneration } from 'store/budget.slice';
import { SearchableContextProvider } from 'context/SearchableContext';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { FeatureFlag } from '../../../types/auth.types';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
// eslint-disable-next-line max-len
import { useWarnOnUnsaved } from '../../financials/financialTable/hooks/useWarnOnUnsaved/useWarnOnUnsaved';

export const TopBar = () => {
  const { title,
    viewId,
    viewPeriod,
  } = useAppSelector((state) => state.topBar);
  const { budgets, activeBudgetId } = useAppSelector((state) => state.budget);
  const dispatch = useAppDispatch();
  const budgetGeneration = useAppSelector((state) => state.budget.budgetGeneration);

  const activeTable = useAppSelector((state) => state.financials.active?.templateId);
  const activeTableHasNewCells = useAppSelector((state) =>
    state.financials.tables[ activeTable ]?.hasNewCells);

  const { warnModal, continueWithCheck } = useWarnOnUnsaved<{ period: Period }>(
    {
      onConfirm: ({ period }) => {
        completePeriodViewChange(period);
      },
      isUnsaved: activeTableHasNewCells
    }
  );

  const { isFeatureEnabled } = useFeatureFlag();

  const currentPath = window.location.pathname;

  const onPeriodViewChange = useCallback((_period: Period) => {
    continueWithCheck({ period: _period });
  }, [ viewId, activeTableHasNewCells ]);

  const completePeriodViewChange = useCallback((_period: Period) => {
    dispatch(topBarSlice.actions.setViewPeriod(_period));
    setRangeType(viewId, false);
    setDefaultPeriod(viewId, _period, false);
  }, [ viewId ]);

  const onBudgetChange = useCallback((e: number) => {
    setActiveBudgetIdCookie(e);
    window.location.reload();
  }, []);

  const isBudgetDropdownVisible = useMemo(() => {
    return currentPath.includes('dashboard') || currentPath.includes('inputs') || viewId;

  }, [ currentPath, viewId ]);

  const isReportPage = useMemo(() => {
    return viewId && viewId !== DASHBOARD_PERIOD_ID || currentPath.includes('view');
  }, [ currentPath, viewId, currentPath ]);

  const isWorkspace = useMemo(() => {
    return currentPath.includes('dashboard');
  }, [ currentPath, viewId ]);

  useEffect(() => {
    if (budgetGeneration.status === 'ended') {
      dispatch(clearBudgetGeneration());
    }
  }, [ budgetGeneration.status ]);

  const getRangePicker = () => {
    if (isReportPage || isWorkspace) {
      return <RangePickerModal
        defaultPeriod={ viewPeriod }
        onSubmit={ onPeriodViewChange }
        actualsIsOpen={ viewPeriod?.actualsOpen }
        cadence={ viewPeriod?.cadence }
        planIsOpen={ viewPeriod?.planOpen }
        templateId={ viewId }
      />;
    }
  };

  return (
    <div className={ styles.wrapper }>
      <span className={ styles.title }>
        { title }
        { ' ' }
        {
          isWorkspace && (
            <>
              <ChevronRightIcon className={ styles.arrowIcon } />
              <SearchableContextProvider>
                <WorkspaceDropdown />
              </SearchableContextProvider>
            </>
          )
        }
      </span>
      {
        [ 'started', 'queued' ].includes(budgetGeneration.status) && (
          <BudgetGenerationInProgress
            step={ budgetGeneration.step }
            totalSteps={ budgetGeneration.totalSteps }
          />
        )
      }
      <div className={ styles.buttons }>
        {
          isBudgetDropdownVisible && !!budgets?.length ?
            <FlatSelect
              className={ styles.budgetSelect }
              options={ [ ...budgets.map((b) => ({ value: b.id, label: b.name })) ] }
              defaultValue={ activeBudgetId }
              onChange={ (e) => onBudgetChange(e) }
            /> : null
        }
        {
          !isFeatureEnabled(FeatureFlag.HIDE_ORGANIZATION_DROPDOWN) ? (
            <OrganizationSelect />
          ): null
        }
        { getRangePicker() }

        { warnModal }

      </div>
    </div>
  );
};
