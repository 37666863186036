import React, { ReactNode } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import DatePickerCellEditor from './DatePickerCellEditor';
import { DimensionCellEditor } from './DimensionCellEditor';
import NumericCellEditor from './NumericCellEditor';
import TextCellEditor from './TextCellEditor';
import SelectCellEditor from './SelectCellEditor';
import { CSS } from '@dnd-kit/utilities';

import styles from './Editors.module.scss';
import { useSortable } from '@dnd-kit/sortable';
import Button from 'components/elements/button/Button';
import clsx from 'clsx';
import AccountCellEditor from './AccountCellEditor';
import { Account } from '../../../../../types/statutory.types';
import { InputRow } from 'components/inputs/types/inputs.types';
import { getDisplayName } from '../../../../../utils/common.utils';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import BudgetItemTypeEditor from './BudgetItemTypeEditor';
import InputGroupWithIcon from './InputGroupWithIcon';
import StartEndDateEditor from './StartEndDateEditor';
import { createValidationRules } from './validation.utils';

interface FormConfig {
  disabled?: boolean;
  message?: string;
  messageType?: 'warning' | 'error' | 'info';
}

interface Props {
  field: TableColDef;
  fieldName: string;
  inputs: InputRow[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  onChange?: (fieldName: string, value: unknown) => void;
  formConfig: Record<string, FormConfig>;
  remove: () => void;
  prefixIcon?: ReactNode;
  placeholder?: string;
  errors?: FieldErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues?: () => any;
  multipleSelected?: boolean;
}

const FieldProxy = ({
  field,
  fieldName,
  inputs,
  control,
  onChange: externalOnChange,
  prefixIcon,
  remove,
  formConfig,
  placeholder,
  errors,
  getValues,
  multipleSelected,
}: Props) => {
  const getEditor = () => {
    switch (field?.cellEditor?.displayName) {
      case 'SelectCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <SelectCellEditor
                field={ fieldName }
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ onChange }
                values={ field?.cellEditorParams?.values ?? [] }
              />
            </InputGroupWithIcon> }
        />;
      case 'AccountCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <AccountCellEditor
                field={ fieldName }
                value={ value }
                errors={ errors }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ onChange }
                formatValue={ (account: Account) => {
                  return `${ account.number } ${ getDisplayName(account.name) }`;
                } }
                values={ field?.cellEditorParams?.values ?? [] } />
            </InputGroupWithIcon>
          }
        />;
      case 'DatePickerCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <DatePickerCellEditor
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                setValue={ onChange }
                errors={ errors }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
              />
            </InputGroupWithIcon>
          }
        />;
      case 'StartEndDateEditor':
        return <StartEndDateEditor
          field={ field }
          errors={ errors }
          formConfig={ formConfig }
          control={ control }
          multipleSelected={ multipleSelected }
        />;
      case 'NumericCellEditor': {
        let defaultValuePlaceholder = field?.cellEditorParams?.placeholder;
      
        if (fieldName.startsWith('input')) {
          const [ , inputKey ] = fieldName.split('_');
          const fieldInput = inputs.find(input => input.id === +inputKey);
          if (fieldInput?.defaultValue !== undefined) {
            defaultValuePlaceholder = fieldInput?.defaultValue;
          }
        }

        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <NumericCellEditor
              field={ fieldName }
              setValue={ onChange }
              value={ +value }
              errors={ errors }
              placeholder={ placeholder ?? defaultValuePlaceholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName=''
            />
          }
        />;
      }
      case 'BudgetItemTypeEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) => <>
            <BudgetItemTypeEditor value={ value } onChange={
              (val) => {
                onChange(val);
                externalOnChange?.(fieldName, val);
              }
            } />
          </>
          }
        />;
      case 'DimensionCellEditor':
      case 'TextCellEditor':
      default:
        if (field.group === 'Dimensions') {
          return <Controller
            name={ fieldName }
            control={ control }
            rules={ createValidationRules(field, getValues, multipleSelected) }
            render={ ({ field: { value, onChange } }) =>
              <DimensionCellEditor
                props={ {
                  value,
                  field: fieldName,
                  errors: errors,
                  disabled: formConfig[ fieldName ]?.disabled,
                  ...field.cellEditorParams(),
                  placeholder: placeholder ?? field?.cellEditorParams?.placeholder
                } }
                onChangeDimensionItem={ onChange }
                canBeUnassigned={ false }
              />
            }
          />;
        }

        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <TextCellEditor
              field={ fieldName }
              value={ value }
              className={ clsx({ [ styles.formula ]: fieldName === 'amountFormula' }) }
              errors={ errors }
              placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName={ field?.headerName } 
              setValue={ onChange }
            />
          }
        />;

    }
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: fieldName });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  if (!field) {
    return null;
  }

  return <>
    <div
      aria-label={ `Field ${ field?.headerName }` }
      ref={ setNodeRef }
      style={ { ...style } }
      className={ styles.fieldRow }>
      <DragIcon
        className={ clsx({ [ styles.dragging ]: isDragging }) }
        { ...attributes }
        { ...listeners }
      />
      <Label headerName={ field?.headerName } field={ fieldName }>
        { getEditor() }
        <FormMessage
          message={ formConfig[ fieldName ]?.message }
          messageType={ formConfig[ fieldName ]?.messageType }
        />
        {
          errors && errors[ fieldName ]?.message &&
          <FormMessage message={ errors[ fieldName ]?.message } messageType='error' />
        }
        <StartEndDateError field={ field } errors={ errors } />
      </Label>
      <Button
        className={ styles.removeFieldButton }
        type='link'
        aria-label='Remove field'
        tabIndex={ -1 }
        onClick={ remove }
      >
        <CloseIcon className={ styles.removeFieldIcon } />
      </Button>
    </div>
    
  </>;
};

export default FieldProxy;

const FormMessage = ({ message, messageType }: Pick<FormConfig, 'message' | 'messageType'>) => {
  if (!message) return null;
  return <span className={ clsx(styles.formMessage, styles[ messageType ]) }>{ message }</span>;
};

const Label = (
  { children, headerName, field }: { children: ReactNode; headerName?: string; field: string }
) => {
  if (field === 'startEndDate') {
    return <div className={ styles.label }>
      <span className={ styles.labelText }>{ headerName }</span>
      { children }
    </div>;
  }

  return <label className={ styles.label }>
    <span className={ styles.labelText }>{ headerName }</span>
    { children }
  </label>;
};

function StartEndDateError({ field, errors }: { field: TableColDef; errors: FieldErrors }) {
  if (!errors || field.field !== 'startEndDate') return null;

  if (errors[ 'startDate' ]?.message) {
    return <FormMessage message={ errors[ 'startDate' ]?.message } messageType='error' />;
  }

  if (errors[ 'endDate' ]?.message) {
    <FormMessage message={ errors[ 'endDate' ]?.message } messageType='error' />;
  }

  return null;
}
