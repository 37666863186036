import React, { ComponentProps, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';
import SectionDropdown from '../../elements/sectionDropdown/SectionDropdown';
import { useAppSelector } from 'store/hooks/hooks';
import { selectBudgetItemTypes } from 'store/budget.slice';
import {
  DropdownGroup,
  DropdownSection,
  ItemRenderer,
} from '../../elements/sectionDropdown/types/sectionDropdown.types';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import HighlightedText from '../../elements/highlightedText/HighlightedText';
import styles from './BudgetItemTypeDropdown.module.scss';
import { getDisplayName } from '../../../utils/common.utils';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import clsx from 'clsx';

interface DropdownBudgetItemType {
  id: number;
  title: string;
  typical: boolean;
  category: string;
}

interface Props extends Partial<ComponentProps<typeof SectionDropdown>> {
  onSelect: (id: number) => void;
  disabled?: boolean;
}

const BudgetItemTypeDropdown = ({
  onSelect,
  children,
  disabled = false,
  ...props
}: PropsWithChildren<Props>) => {
  const [ t ] = useTranslation('budget');
  const [ open, setOpen ] = useState(false);
  const budgetItemTypes = useAppSelector(selectBudgetItemTypes);

  const dropdownBudgetItemTypes: DropdownBudgetItemType[] = useMemo(() => {
    return budgetItemTypes.map((item) => ({
      ...item,
      category: getDisplayName(item.category),
      title: getDisplayName(item.name)
    }));
  }, [ budgetItemTypes ]);

  const sections: DropdownSection<DropdownBudgetItemType>[] = useMemo(() => {
    const groupedItemTypes = groupBy(dropdownBudgetItemTypes, 'category');
    return Object.entries(groupedItemTypes).map(([ key, array ]) => {
      const isNonCategory = key === 'null';
      return ({
        key: isNonCategory ? t('dropdown.sections.other') : key,
        label: isNonCategory ? t('dropdown.sections.other') : key,
        items: array
      });
    });
  }, [ dropdownBudgetItemTypes ]);

  const groups: DropdownGroup<DropdownBudgetItemType>[] = useMemo(() => {
    return [
      {
        key: 'all',
        label: t('dropdown.all'),
        sections: sections,
      },
      {
        key: 'common',
        label: t('dropdown.common'),
        sections: sections.map(section => ({
          ...section,
          items: section.items.filter(item => item.typical)
        })).filter(section => section.items.length > 0)
      }
    ];
  }, [ sections ]);

  const itemRenderer: ItemRenderer = useCallback(({ item, search, disabled: itemDisabled }) => {
    return <div className={ styles.item }>
      <HighlightedText
        text={ item.title } highlight={ search }
        className={ clsx({ [ styles.itemDisabled ]: itemDisabled }) } />
    </div>;
  }, []);

  const onSelectItem = useCallback((id: number) => {
    onSelect(id);
    setOpen(false);
  }, [ onSelect ]);

  return <SectionDropdown
    open={ open }
    onOpenChange={ setOpen }
    isSelected={ () => false }
    onSelect={ onSelectItem }
    isDisabled={ () => false }
    rowHoverActions={ [] }
    groups={ groups }
    defaultGroupKey='common'
    total={ budgetItemTypes.length }
    itemRenderer={ itemRenderer }
    placement='bottomRight'
    getPopupContainer={ () => document.body }
    dropdownClassName={ styles.dropdown }
    disabled={ disabled }
    title={ <h4 className={ styles.title }>
      { t('dropdown.title') }
    </h4> }
    { ...props }
  >
    {
      children ? children : <GhostIconButton tooltip={ t('dropdown.tooltip') } active={ open }>
        <PlusIcon />
      </GhostIconButton>
    }
  </SectionDropdown>;
};

export default BudgetItemTypeDropdown;
