import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Cadence, StatementsRequestForm } from 'types/form.types';
import DatePickersSection
  from 'components/elements/rangePicker/datePickersSection/DatePickersSection';

import styles from './RangePickerModal.module.scss';
import { Period, PeriodSectionsMode } from 'types/financials.types';
import PeriodRangeButton from '../periodRangeButton/PeriodRangeButton';
import utc from 'dayjs/plugin/utc';
import { DATE_FORMATS } from 'utils/date.utils';
import Modal from '../modal/Modal';

dayjs.extend(utc);

type Props = {
  defaultPeriod?: Period;
  onSubmit: (period: Period, defaultPeriod: boolean) => void;
  cadence?: Cadence;
  disableCadence?: boolean;
  actualsIsOpen: boolean;
  planIsOpen?: boolean;
  sections?: PeriodSectionsMode;
  templateId?: number;
  disabled?: boolean;
  children?: ReactElement;
};
const FORMAT = DATE_FORMATS[ 0 ];

const RangePickerModal = (
  {
    defaultPeriod = null,
    onSubmit,
    cadence,
    disableCadence,
    actualsIsOpen,
    planIsOpen,
    sections = 'multiple',
    templateId,
    disabled,
    children
  }: Props) => {
  const [ t ] = useTranslation('common');
  const [ visible, setVisible ] = useState(false);
  const [ selectedCadence, setSelectedCadence ] = useState<Cadence>(cadence);
  const scrollContainerRef = useRef<HTMLElement | null>(null);
  const pickerRef = useRef<HTMLFormElement | null>(null);
  useEffect(() => {
    scrollContainerRef.current = document.getElementById('scrollContainer');
  }, []);

  useEffect(() => {
    setSelectedCadence(cadence);
  }, [ cadence ]);

  const getDefaultValues = () => {
    const startDateActuals = defaultPeriod?.startDate ?
      dayjs.unix(defaultPeriod.startDate) :
      dayjs().subtract(1, 'year').startOf('year');
    const startDateActualsInput = startDateActuals.format(FORMAT);

    const endDateActuals = defaultPeriod?.endDate ?
      dayjs.unix(defaultPeriod.endDate) :
      dayjs().subtract(1, 'year').endOf('year');
    const endDateActualsInput = endDateActuals.format(FORMAT);

    const startDatePlan = defaultPeriod?.startDatePlan ?
      dayjs.unix(defaultPeriod.startDatePlan) :
      dayjs().startOf('year');
    const startDatePlanInput = startDatePlan.format(FORMAT);

    const endDatePlan = defaultPeriod?.endDatePlan ?
      dayjs.unix(defaultPeriod.endDatePlan) :
      dayjs().endOf('year');
    const endDatePlanInput = endDatePlan.format(FORMAT);

    return {
      startDateActuals,
      startDateActualsInput,
      endDateActuals,
      endDateActualsInput,
      startDatePlan,
      startDatePlanInput,
      endDatePlanInput,
      endDatePlan,
      actualsOpen: actualsIsOpen,
      planOpen: planIsOpen
    };

  };
  const methods = useForm<StatementsRequestForm>({
    defaultValues: getDefaultValues(),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const resetValues = () => {
    methods.reset(getDefaultValues());
  };

  useEffect(() => {
    resetValues();
  }, [ defaultPeriod ]);

  const handleVisibilityChange = () => {
    setVisible(prev => !prev);
  };

  const onClose = () => {
    setVisible(false);
    resetValues();
  };

  const submit = (values: StatementsRequestForm) => {
    const period: Period = {
      cadence: selectedCadence,
      startDate: values.actualsOpen ? values.startDateActuals.unix() : null,
      endDate: values.actualsOpen ? values.endDateActuals.utc().unix() : null,
      startDatePlan: values.planOpen ? values.startDatePlan.unix() : null,
      endDatePlan: values.planOpen ? values.endDatePlan.utc().unix() : null,
      actualsOpen: values.actualsOpen,
      planOpen: values.planOpen,
      fullRange: false,
      isManuallySet: true,
    };

    onSubmit(period, false);
    setVisible(false);
  };

  useEffect(() => {
    const clickSubmitButton = (event: KeyboardEvent) => {
      if (event.code === 'Enter' && visible) {
        (document.querySelector('button[type=submit]') as HTMLElement).click();
      }
    };
    document.addEventListener('keydown', clickSubmitButton);
    return () => document.removeEventListener('keydown', clickSubmitButton);
  }, []);

  return (
    <div>
      <Modal
        onConfirm={ methods.handleSubmit(submit) }
        onClose={ onClose }
        okText={ t('form.apply') }
        cancelText={ t('form.cancel') }
        closable={ false }
        isVisible={ visible }
        closeOnConfirm={ false }
        destroyOnClose
        width={ 850 }
        bodyStyle={ { padding: 0 } }
      >
        <form
          onSubmit={ methods.handleSubmit(submit) }
          className={ styles.content }
          ref={ pickerRef }
        >
          <FormProvider { ...methods }>
            <div className={ styles.top } aria-label='modal'>
              <DatePickersSection
                cadence={ selectedCadence }
                setCadence={ setSelectedCadence }
                sections={ sections }
                disableCadence={ disableCadence }
                getDefaultValues={ getDefaultValues }
                templateId={ templateId }
              />
            </div>
          </FormProvider>
        </form>
      </Modal>
      {
        children ? cloneElement(children, { onClick: handleVisibilityChange }) : <PeriodRangeButton
          onClick={ handleVisibilityChange }
          period={ defaultPeriod }
          disabled={ disabled }
        />
      }
    </div>

  );
};

export default RangePickerModal;
