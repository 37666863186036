import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ActionCreators } from 'redux-undo';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isFormulaNode, Template, TemplateNode, UserTemplateShort } from 'types/templates.types';
import { fetchAndSetTemplateList } from 'store/templates.slice';
import {
  canSaveTemplate,
  removeBatchNodes,
  selectTemplate,
  selectTemplateUndo,
  setMappedTemplate,
} from 'store/template.slice';
import { notifyError } from 'utils/notifications.utils';
import { routes } from 'utils/config.utils';
import { isNewTemplate, setLastTemplateId } from 'utils/template.utils';
import { isTemplateClear, parseTemplateRequestData } from 'utils/templates.utils';

import TemplateRowEmpty from 'components/templates/templateRow/TemplateRowEmpty';
import TemplateTitle from 'components/templates/templateTitle/TemplateTitle';
import TemplateRows from 'components/templates/templateRow/TemplateRows';
import Loader from 'components/elements/loader/Loader';
import BuilderTemplateDropdown from '../builderTemplateDropdown/BuilderTemplateDropdown';

import { ReactComponent as Undo } from 'assets/icons/undo.svg';
import { ReactComponent as Redo } from 'assets/icons/redo.svg';
import { ReactComponent as Report } from 'assets/icons/reports.svg';

import styles from './CustomTemplate.module.scss';
import CustomDragLayerRow from '../templateRow/CustomDragLayerRow';
import { reportsService } from 'services/reports.services';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { debounce } from 'lodash';
import useFormulaTotalEffect
  from 'components/templates/customTemplate/formulaBuilder/hooks/useFormulaTotalEffect';
import { RowType } from 'types/financials.types';
import AutoSave from 'components/elements/autoSave/AutoSave';
import { selectCustomFormula } from 'store/formula.slice';
import { getReportCacheTag, reportsApi } from 'store/api/reports.api';
import Button from 'components/elements/button/Button';

interface Props {
  onDeleteTemplate: (template: UserTemplateShort) => void;
}

const CustomTemplate = ({ onDeleteTemplate }: Props) => {
  const template = useAppSelector(selectTemplate);
  const customFormula = useAppSelector(selectCustomFormula);
  const templateUndo = useAppSelector(selectTemplateUndo);
  const dimensionNodes = useAppSelector(state => state.templates.dimensions);
  const canSave = useAppSelector(canSaveTemplate);
  const [ loading, setLoading ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ showAutoSave, setShowAutoSave ] = useState(false);
  const savedTemplate = useRef<Template>(null);
  const prevTemplate = useRef<Template>(null);
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('financials');
  const navigate = useNavigate();
  const templateContainerRef = useRef(null);
  const isMounted = useRef(false);
  const [ indexedRows, setIndexedRows ] = useState<number[]>([]);
  const [ selection, setSelection ] = useState({
    nodes: [],
    lastSelectedIndex: -1,
  });

  useFormulaTotalEffect();
  useEffect(() => {
    if (template.id && !isMounted.current) {
      getAndSetTemplate(template.id);
    }
  }, [ dimensionNodes ]);

  useEffect(() => {
    const clickEscButton = (event: KeyboardEvent) => {
      if ([ 'Backspace', 'Delete' ].includes(event.code) && !customFormula) {
        const isCustomFormula = Object.values(template.nodes).some(isFormulaNode);

        if (isCustomFormula) {
          const searchElement = Object.values(template.nodes)
            .filter(isFormulaNode)
            .find(item => item.rowData.formulaElements
              .find(el => selection.nodes.includes(el.templateNode || el.statutoryRow)));

          !searchElement && dispatch(removeBatchNodes(selection.nodes));
        } else dispatch(removeBatchNodes(selection.nodes));
      }

    };
    if (selection.nodes.length) {
      document.addEventListener('keydown', clickEscButton);
    } else {
      document.removeEventListener('keydown', clickEscButton);
    }
    return () => {
      document.removeEventListener('keydown', clickEscButton);
    };
  }, [ selection.nodes ]);

  useEffect(() => {
    setShowAutoSave(false);
    if (prevTemplate.current?.id !== template.id) {
      const isCopyingTemplate = prevTemplate.current?.id != null &&
        template.id == null &&
        template.title !== '';
      const forceSave = isNewTemplate(template) || isCopyingTemplate;
      isMounted.current = forceSave;
    }
    setIndexedRows([ ...template.roots.map(root => getNestedIds(root)).flat() ]);
    prevTemplate.current = template;
  }, [ template ]);

  useEffect(() => {
    if (
      canSave &&
      !loading &&
      !isSaving &&
      (savedTemplate.current !== template && !isTemplateClear(template)) &&
      isMounted.current
    ) {
      debouncedHandleSave(template);
    }
    isMounted.current = true;
  }, [ template, isSaving ]);

  const getAndSetTemplate = useCallback((templateId: number) => {
    if (isMounted.current) {
      setLoading(true);
      reportsService.getUserTemplate(templateId)
        .then(({ data }) => {
          dispatch(setMappedTemplate(data));
        }).finally(() => {
          setLoading(false);
          dispatch(ActionCreators.clearHistory());
        });
    }
  }, [ template ]);

  const haveToUpdateFormulaIds = useCallback((_template: Template) => {
    return Object.values(_template.nodes).some(
      node => node.type === RowType.FORMULA && node.rowData.id == null
    );
  }, []);

  const tables = useAppSelector(state => state.financials.tables);

  const handleSave = useCallback((templateToSave: Template) => {
    setIsSaving(true);
    const templateId = templateToSave.id;
    reportsService.saveUserTemplate(
      parseTemplateRequestData(templateToSave),
      templateId
    ).then(({ data: { id } }) => {
      if (id !== templateId)
        navigate(routes.report.templateBuilder + '/' + id);

      setLastTemplateId(id);
      if (!templateId || haveToUpdateFormulaIds(templateToSave)) {
        getAndSetTemplate(id);
      }
      savedTemplate.current = templateToSave;
      dispatch(fetchAndSetTemplateList());
      dispatch(reportsApi.util.invalidateTags([ { type: 'Report', id } ]));
      const period = tables[ id ]?.period;
      dispatch(reportsApi.util.invalidateTags([
        { type: 'StreamReport', id: getReportCacheTag(id, period) }
      ]));
      setShowAutoSave(true);
    }).catch((error) => {
      if (error.response.data.nonFieldErrors)
        notifyError(error.response.data.nonFieldErrors);
      else {
        notifyError(
          t('notifications.unexpected-error.message',
            { ns: 'common' })
        );
        throw error;
      }
    }).finally(() => {
      setIsSaving(false);
    });
  }, []);

  const debouncedHandleSave = useCallback(debounce(handleSave, 1000), []);

  const getNestedIds = (nodeId: number | string) => {
    const ids = [];
    const count = (node: TemplateNode) => {
      ids.push(node?.id);
      return node?.children?.map(child => count(template.nodes[ child ]));
    };
    count(template.nodes[ nodeId ]);
    return ids;
  };

  const onUndo = () => {
    dispatch(ActionCreators.undo());
    debouncedHandleSave(templateUndo.past[ 0 ].template);
  };

  const onRedo = () => {
    dispatch(ActionCreators.redo());
    debouncedHandleSave(templateUndo.future[ 0 ].template);
  };

  const onSelectionChange = (index: number, cmdKey: boolean, shiftKey: boolean) => {
    let selectedNodes: number[];
    let lastSelectedIndex = index;
    const rowId = indexedRows[ index ];
    if (!cmdKey && !shiftKey) {
      const foundIndex = selection.nodes.indexOf(rowId);
      if (foundIndex >= 0 && selection.nodes.length <= 1)
        selectedNodes = [];
      else
        selectedNodes = getNestedIds(rowId);
    } else if (shiftKey) {
      if (selection.lastSelectedIndex >= index) {
        selectedNodes = [
          ...selection.nodes,
          ...indexedRows.slice(index, selection.lastSelectedIndex)
            .map(item => getNestedIds(item)).flat()
        ];
      } else {
        selectedNodes = [
          ...selection.nodes,
          ...indexedRows.slice(selection.lastSelectedIndex + 1, index + 1)
            .map(item => getNestedIds(item)).flat()
        ];
      }
    } else if (cmdKey) {
      const foundIndex = selection.nodes.indexOf(rowId);
      if (foundIndex >= 0) {
        const toRemove = getNestedIds(rowId);
        selectedNodes = selection.nodes.filter(id => !toRemove.includes(id));
      } else
        selectedNodes = [ ...selection.nodes, ...getNestedIds(rowId) ];
    }

    if (selectedNodes?.length < 0) {
      lastSelectedIndex = -1;
    }

    setSelection({
      nodes: selectedNodes,
      lastSelectedIndex: lastSelectedIndex
    });
  };

  const clearSelection = useCallback(() => {
    setSelection({
      nodes: [],
      lastSelectedIndex: -1,
    });
  }, []);
  const isDefaultTemplate = useMemo(() => template.title === '', [ template ]);

  return (
    <>

      <div
        className={ styles.customTemplate }
        ref={ templateContainerRef }
      >
        <Loader isActive={ loading }/>
        <div className={ styles.topLine }>
          <div className={ styles.dropdown }>
            <BuilderTemplateDropdown setLoading={ setLoading } onDelete={ onDeleteTemplate } />
            <AutoSave show={ showAutoSave } />
          </div>
          <div className={ styles.buttons }>
            <Button
              type='link'
              className={ styles.iconButton }
              onClick={ onUndo }
              tooltip={ t('templates.undo') }
              disabled={ templateUndo.past.length <= 0 || customFormula !== null }
            >
              <Undo/>
            </Button>
            <Button
              type='link'
              className={ styles.iconButton }
              onClick={ onRedo }
              tooltip={ t('templates.redo') }
              disabled={ templateUndo.future.length <= 0 || customFormula !== null }
            >
              <Redo/>
            </Button>
            { template.id && (
              <Button
                type='link'
                disabled={ template.roots.length === 0 && template.title === 'New template' }
                tooltip={ t('templates.open-reports') }
                className={ styles.iconButton }
                onClick={ () => navigate(`/view/?template=${ template.id }`) }
              >
                <Report/>
              </Button>
            ) }
          </div>
        </div>
        <div className={ styles.content }>
          {
            !isDefaultTemplate && (
              <>
                <TemplateTitle disable={ customFormula !== null } isSubtitle={ false }/>
                <TemplateTitle disable={ customFormula !== null } isSubtitle={ true }/>
                <OutsideClickHandler display='contents' onOutsideClick={ clearSelection }>
                  <CustomDragLayerRow/>
                  <TemplateRows
                    items={ template.roots }
                    indexedRows={ indexedRows }
                    selectedNodes={ selection.nodes }
                    onSelectionChange={ onSelectionChange }
                    clearSelection={ clearSelection }
                  />
                  <TemplateRowEmpty/>
                </OutsideClickHandler>
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default CustomTemplate;
