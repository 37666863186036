import {
  formatSeriesName,
  getDatasetIndexFromSeries,
  getSeriesFormat,
  xAxisFormatter
} from 'components/charts/chart/utils/chart.utils';
import { ChartData, DataFormat } from 'components/charts/types/chart.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartSettings, EChartTooltipParams } from 'types/chart.types';
import { Period } from 'types/financials.types';
import { separateThousands } from 'utils/financials.utils';
import styles from '../chart/Chart.module.scss';
import useChartSeries from './useChartSeries';

interface Props {
  settings: ChartSettings;
  period: Period;
  templateId: number;
  cumulative: boolean;
}

const formatValue = (value: number, format: DataFormat) => {
  switch (format) {
    case 'percentage':
      return `${ Math.round(value) } %`;
    case 'currency':
      return `${ separateThousands(Math.round(value)) } €`;
    default:
      return `${ separateThousands(Math.round(value)) }`;
  }
};

const useChartTooltip = ({ settings, period, templateId, cumulative }: Props) => {
  const [ t ] = useTranslation('financials');
  const { barStacks } = useChartSeries({ period, settings, templateId, cumulative });

  const seriesTooltipElementRenderer = (el, format, total, datasetIndex) => {
    return `
        <div class="${ styles.tooltipItem }">
          <div class="${ styles.tooltipItemIdentifier }">
            <div class="${ styles.tooltipItemColor }" style="background-color: ${ el.color }">
            </div>
            <div class="${ styles.tooltipItemName }">
              <div>
                ${ formatSeriesName(el.seriesName) } 
              </div>
              ${
  settings.datasets[ datasetIndex ].style === 'stacked' ?
    `<div class=${ styles.percentage }>${ (el.value / total * 100).toFixed(0) }%</div>` :
    ''
}
            </div>
          </div>
          <div class="${ styles.tooltipItemValue }">
              ${ formatValue(el.value, format) }
          </div>
        </div>
    `;
  };

  const getTotalContent = (
    total: number,
    series: EChartTooltipParams[],
    format: DataFormat
  ) => {

    return series.length > 1
      ? `
        <div class="${ styles.tooltipTotal }">
          <div>
            ${ t('charts.tooltip.total') }
          </div>
          <div>
          </div>
          ${ formatValue(total, format) }
        </div>`
      : '';
  };

  const tooltipFormatter = useCallback((params: EChartTooltipParams): string => {
    // todo: This not works like before, but probably not good with more datasets.
    const primaryDatasetIndex = 0;
    const primaryDatasetFormat = getSeriesFormat(settings, primaryDatasetIndex);

    let secondaryDatasetIndex = 1;
    let secondaryDatasetFormat = primaryDatasetFormat;
    const secondaryDataset = params.filter(
      el => getDatasetIndexFromSeries(el.seriesName) === 1
    )[ 0 ];
    if (secondaryDataset) {
      secondaryDatasetIndex = 1;
      secondaryDatasetFormat = getSeriesFormat(settings, secondaryDatasetIndex);
    }

    const title = xAxisFormatter(params[ 0 ].axisValue, period.cadence);

    const paramsWithValues = params
      .filter(el => el.value && Math.round(el.value) !== 0)
      .filter((el) => {
        const data = el.data as ChartData;
        return !data.hideValue;
      });
    let secondaryTotal = 0;
    let primaryTotal = 0;
    const primarySeries = [];
    const secondarySeries = [];
    for (const param of paramsWithValues) {
      if (param.seriesName &&
        getDatasetIndexFromSeries(param?.seriesName) === 0) {
        primaryTotal += param.data.value;
        primarySeries.push(param);
      } else {
        secondaryTotal += param.data.value;
        secondarySeries.push(param);
      }
    }

    secondaryTotal = settings.datasets[ 1 ]?.style === 'grouped' ?
      secondaryTotal / 2 : secondaryTotal;
    primaryTotal = settings.datasets[ 0 ]?.style === 'grouped' ?
      primaryTotal / 2 : primaryTotal;

    const contentPrimary =
      primarySeries
        .map((el) => {
          return seriesTooltipElementRenderer(el, primaryDatasetFormat, primaryTotal, 0);
        }
        )
        .join('');
    const totalPrimary = getTotalContent(primaryTotal, primarySeries, primaryDatasetFormat);
    const contentSecondary =
      secondarySeries
        .map((el) =>
          seriesTooltipElementRenderer(el, secondaryDatasetFormat, secondaryTotal, 1))
        .join('');
    const totalSecondary = getTotalContent(secondaryTotal, secondarySeries, secondaryDatasetFormat);

    return `
      <div class="${ styles.tooltip }">
        <h4>${ title }</h4>
        ${ contentPrimary }
        ${ totalPrimary }
        ${ contentSecondary ? '<br />' : '' }
        ${ contentSecondary }
        ${ totalSecondary }
      </div>
    `;
  }, [ period.cadence, settings, barStacks ]);

  return {
    tooltipFormatter
  };
};

export default useChartTooltip;
