import React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from 'components/elements/modals/warnings/WarningModal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const WarnBeforeExitModal = ({ isVisible, onClose, onConfirm }: Props) => {
  const [ t ] = useTranslation('common');

  return <WarningModal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onConfirm }
    title={ t('form.cancel') }
    description={ t('warnings.exit-without-saving') }
    closeText={ t('modal.buttons.default-close') }
  />;
};

export default WarnBeforeExitModal;
