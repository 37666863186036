import React, { useCallback, useMemo, useRef, useState } from 'react';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { DimensionItem } from 'types/filterTable.types';
import { getDimensionViewName } from 'utils/financials.utils';
import { useTranslation } from 'react-i18next';
import {
  UNASSIGNED_ROW_ID
} from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';

import styles from './Editors.module.scss';
import { FieldErrors } from 'react-hook-form';

interface FieldProps {
  field: string;
  value: number;
  disabled?: boolean;
  placeholder?: string;
  errors?: FieldErrors;
}

interface ValuesProps {
  values?: DimensionItem[];
}

interface Props {
  props: FieldProps & ValuesProps;
  onChangeDimensionItem: (id: number) => void;
  canBeUnassigned: boolean;
}

export const DimensionCellEditor = ({ props, onChangeDimensionItem, canBeUnassigned }: Props) => {
  const [ t ] = useTranslation('common');
  const [ open, setOpen ] = useState(false);

  const [ activeChoice, setActiveChoice ] = useState<number>(props.value);
  const refContainer = useRef(null);

  const stopEditing = useCallback(() => {
    setOpen(false); 
  }, [ props ]);

  const handleChoiceChange = useCallback(async (id: number) => {
    setActiveChoice(id);
    onChangeDimensionItem(id);
    stopEditing();
  }, [ props, onChangeDimensionItem ]);

  const handleEscapeDropdown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      stopEditing();
    }
  }, []);

  const options = useMemo(() => {
    const UNASSIGNED_OPTION = {
      label: t('common:unassigned'),
      value: UNASSIGNED_ROW_ID,
    };
    const commonOptions = props.values.map((item: DimensionItem) => ({
      label: getDimensionViewName(item),
      value: item.id,
    }));

    if (!canBeUnassigned) return commonOptions;
    return [ ...commonOptions, UNASSIGNED_OPTION ];
  }, [ props.values, canBeUnassigned ]);

  return (
    <div ref={ refContainer } style={ { height: '100%' } }>
      <SearchableSelect
        open={ open }
        className={ styles.searchableSelect }
        onDropdownVisibleChange={ setOpen }
        onFocus={ () => setOpen(true) }
        disabled={ props.disabled }
        placeholder={ props.placeholder }
        autoFocus={ false }
        status={ props.errors[ props.field ] ? 'error' : null }
        getPopupContainer={ trigger => trigger }
        callback={ handleEscapeDropdown }
        options={ options }
        defaultValue={ activeChoice }
        popupClassName='ag-custom-component-popup'
        onChange={ handleChoiceChange }
      />
    </div>
  );
};
