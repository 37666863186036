import React, { useEffect, useRef } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import styles from './InvoicesTable.module.scss';
import { RowType } from 'types/revenueRecognition.types';
import { isUnassignedNode } from './invoicesTable.utils';
import { Tooltip } from 'antd';
import { getTransactionName, isProductProxy } from 'utils/financials.utils';

const CustomCellRenderer = (props: ICellRendererParams) => {
  const myRef = useRef(null);

  useEffect(() => {
    props.registerRowDragger(myRef.current);
  });

  const isUnassigned = isUnassignedNode(props?.node);
  const isInvoiceCell = props?.node?.data?.type === 'INVOICE' || null;
  const isEmptyProduct = props?.node?.allLeafChildren.length === 1 && !isInvoiceCell;

  const hasMemo = props?.data?.memo != null;
  const showTooltip = hasMemo && props?.data?.type === RowType.INVOICE;

  return (
    <Tooltip title={ showTooltip ? props?.data?.memo : null }>
      <div
        className={ `
      ${ styles.customCell }
      ${ isInvoiceCell ? styles.invoiceCell : '' }
      ${ isProductProxy(props?.node) ? styles.proxyCell : '' }
      ${ ( isEmptyProduct && isUnassigned ) || isEmptyProduct ? styles.emptyProduct : '' }
      ` }>
        { isProductProxy(props?.node) ? props.value :
          getTransactionName(props?.data?.memo, props?.data?.transactionDate) }
        {
          !isInvoiceCell && (
            <>
              <span>&bull;</span>
              <span>
                { props.node.allChildrenCount }
              </span>
            </>
          )
        }
        <div ref={ myRef }
          className={ `
        ${ styles.dragHandler }
        ${ !isInvoiceCell ? styles.productCell : '' }
        ` }>
        </div>
      </div>
    </Tooltip>
  );
};

export default CustomCellRenderer;
