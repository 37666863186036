import React from 'react';
import { DropdownData, ItemRenderer, RowHoverAction } from '../types/sectionDropdown.types';
import styles from './DropdownElement.module.scss';
import { useSearchable } from '../../../../context/SearchableContext';
import clsx from 'clsx';

interface Props<T extends DropdownData> {
  item: T;
  rowHoverActions: RowHoverAction[] | ((id: number | string) => RowHoverAction[]);
  onClick: () => void;
  active: boolean;
  disabled: boolean;
  itemRenderer: ItemRenderer;
  selectedGroup: boolean;
  focused?: boolean;
}

const DropdownElement = <T extends DropdownData>({
  item,
  rowHoverActions,
  onClick,
  active,
  disabled,
  itemRenderer,
  selectedGroup,
  focused
}: Props<T>) => {
  const { state: { search } } = useSearchable();

  const actions = rowHoverActions instanceof Function ? rowHoverActions(item.id) : rowHoverActions;

  return <div
    onClick={ onClick }
    className={ clsx(styles.element, {
      [ styles.selected ]: selectedGroup,
      [ styles.focused ]: focused,
    }) }
  >
    <div className={ styles.identifiers }>
      {
        itemRenderer({
          item,
          active,
          search,
          disabled,
        })
      }
    </div>
    <div className={ styles.actions }>
      {
        actions.map((action, index) => (
          <button
            className={ `${ styles.actionButton }  ${
              (action.isHidden && action.isHidden(item.id)) ?
                styles.actionButtonHidden : null
            }` }
            key={ `action-${ index }` }
            onClick={ (e) => {
              e.stopPropagation();
              action.onClick(item.id);
            } }
          >
            { action.getIcon(item.id) }
          </button>
        ))
      }
    </div>
  </div>;
};

export default DropdownElement;
