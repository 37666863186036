import { RefObject, useCallback } from 'react';
import { CellRange, IRowNode } from 'ag-grid-community';
import { AG_GRID_AUTO_COLUMN, getFieldType, trimReportType } from 'utils/grid.utils';
import { ReportType } from 'types/templates.types';
import dayjs, { OpUnitType } from 'dayjs';
import { ParamsByReportType, Period, RowType } from 'types/financials.types';
import { TransactionLineRequestParams } from 'services/statutory.service';
import {
  UNASSIGNED_ROW_NAME,
} from '../../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import { getTransactionQuery, isProductProxy } from 'utils/financials.utils';
import { groupBy } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { range } from 'utils/array.utils';
import { isRangeSelection } from 'components/financials/utils/selection.utils';

const FORBIDDEN_ROW_TYPES: RowType[] = [
  RowType.TOTAL,
  RowType.SUBTOTAL,
  RowType.GROUP,
  RowType.SPACER,
  RowType.HALF_SPACER,
  RowType.TITLE
];

type PeriodByReportType = {
  [key in ReportType]: Period | null;
};

interface Props {
  templateId: number;
  period: Period;
  gridRef: RefObject<AgGridReact>;
}

const useTransactionRequestParams = ({ templateId, period, gridRef }: Props) => {
  const isNodeIncluded = useCallback((node: IRowNode, nodes: IRowNode[]): boolean => {
    if (nodes.includes(node)) {
      return true;
    }
    if (node.level === 0) {
      return false;
    }
    const parentNode = node.parent;
    if (!parentNode) {
      return false;
    }
    return isNodeIncluded(parentNode, nodes);
  }, []);

  const filterNodesChildren = useCallback((rows: IRowNode[]): IRowNode[] => {
    const rootNodes: IRowNode[] = [];
    const sortedNodes = rows.sort((a, b) => a.level - b.level);
    sortedNodes.forEach(node => {
      if (!isNodeIncluded(node, rootNodes)) {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }, []);

  const getPeriodFromFields = useCallback((fields: string[]): PeriodByReportType => {
    const actualFields = [];
    const planFields = [];
    fields.forEach((field) => {
      if (getFieldType(field) === ReportType.ACTUAL) {
        actualFields.push(trimReportType(field, ReportType.ACTUAL));
      } else if (getFieldType(field) === ReportType.PLAN) {
        planFields.push(trimReportType(field, ReportType.PLAN));
      }
    });

    return {
      [ ReportType.ACTUAL ]: {
        cadence: period.cadence,
        ...(actualFields.length > 0 ? {
          startDate: dayjs(actualFields.at(0)).unix(),
          endDate: dayjs(actualFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        }: {}),
        ...planFields.length > 0 ? {
          startDatePlan: dayjs(planFields.at(0)).unix(),
          endDatePlan: dayjs(planFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        } : {},
        isManuallySet: period.isManuallySet,
      },
      [ ReportType.PLAN ]: planFields.length > 0 ? {
        cadence: period.cadence,
        startDatePlan: dayjs(planFields.at(0)).unix(),
        endDatePlan: dayjs(planFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        isManuallySet: period.isManuallySet,
      } : null,
    };
  }, [ period ]);

  const selectionIsCellRange = useCallback((selection): selection is CellRange => {
    return selection?.startRow !== undefined;
  }, []);

  const getSelectedPeriod = useCallback(
    (selection: CellRange | IRowNode): PeriodByReportType => {
      if (selectionIsCellRange(selection)) {
        const columns = selection.columns.filter(column => {
          const colDef = column.getUserProvidedColDef();
          return colDef?.headerComponentParams && !colDef.headerComponentParams.isDynamicColumn;
        });
        if (!columns.length) {
          const fields = gridRef.current.columnApi.getColumns().map(column => column.getColId());
          const reportFields = fields.filter(
            field => field.startsWith(ReportType.ACTUAL) || field.startsWith(ReportType.PLAN)
          );
          return getPeriodFromFields(reportFields);
        }

        const fields = columns.map(column => column.getColDef().field);
        return getPeriodFromFields(fields);
      } else {
        const fields = gridRef.current.columnApi.getColumns().map(column => column.getColId());
        const reportFields = fields.filter(
          field => field.startsWith(ReportType.ACTUAL) || field.startsWith(ReportType.PLAN)
        );
        return getPeriodFromFields(reportFields);
      }
    }, [ period, getPeriodFromFields ]);

  const getGroupedTransactionQuery = useCallback((
    group: IRowNode[],
    queryPeriod: Period
  ): TransactionLineRequestParams[][] => {
    let nodes = [ ...group ];
    const query: TransactionLineRequestParams[][] = [];
    const unassignedRow = nodes.find(row => row.key === UNASSIGNED_ROW_NAME);
    if (unassignedRow) {
      nodes = nodes.filter(row => row.key !== UNASSIGNED_ROW_NAME);
      query.push(getTransactionQuery(
        [ unassignedRow ],
        templateId,
        queryPeriod
      ));
    }
    if (nodes.length) {
      query.push(getTransactionQuery(nodes, templateId, queryPeriod));
    }
    return query;
  }, []);

  const getParamsByType = useCallback((rows: IRowNode[], selectedPeriod: Period) => {
    const _params: TransactionLineRequestParams[] = [];
    const rowsByType = groupBy(rows, 'data.type');
    const types = Object.keys(rowsByType);
    types.forEach(type => {
      const typeRows = rowsByType[ type ];
      const query = getGroupedTransactionQuery(typeRows, selectedPeriod);
      _params.push(...query[ 0 ]);
    });

    return _params;
  }, []);

  const getParamsByRowLevel = useCallback((rows: IRowNode[], selectedPeriod: Period) => {
    if (!selectedPeriod) {
      return [];
    }
    const _params: TransactionLineRequestParams[] = [];

    rows.forEach(row => {
      if (row.level === 0) {
        const query = getParamsByType([ row ], selectedPeriod);
        _params.push(...query);
      } else {
        const query = getGroupedTransactionQuery([ row ], selectedPeriod);
        _params.push(...query[ 0 ]);
      }
    });

    return _params;
  }, []);

  const isProperCellRangeSelected = useCallback((selection: CellRange[] | IRowNode[]) => {
    if (!selection) {
      return false;
    }

    if (!isRangeSelection(selection)) {
      return true;
    }

    return selection.some(cellRange => {
      const columns = cellRange.columns.filter(col => col.getColId() !== AG_GRID_AUTO_COLUMN);
      return columns.length > 0;
    });
  }, []);

  const getSelectedRows = useCallback((selection: CellRange | IRowNode): IRowNode[] => {
    if (!selectionIsCellRange(selection)) {
      return [ selection ];
    } else {
      const rowIndexes = range(
        selection?.startRow?.rowIndex,
        selection?.endRow?.rowIndex
      );
      const indexes = rowIndexes.map(
        rowIndex => gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex)
      );
      return indexes;
    }

  }, []);

  const getRequestParams = useCallback((
    selection: CellRange[] | IRowNode[]
  ): ParamsByReportType => {
    // Here we create params for actuals and plan based on range selection.
    // Range selection contain information about columns (period) and rows (filters)
    // We need to have an object with actuals and plan
    // Each will have a list of params
    //   Each param can have different periods
    const returnParams: ParamsByReportType = {
      [ ReportType.ACTUAL ]: [],
      [ ReportType.PLAN ]: [],
    };

    if (!isProperCellRangeSelected(selection)) {
      return null;
    }
    for (const selectionElement of selection) {
      // Rows selected in one range
      const rows = getSelectedRows(selectionElement).filter(
        row => !FORBIDDEN_ROW_TYPES.includes(row.data?.type)
      ).flatMap(row => {
        // If we have ProductProxy selected, we need to get all children
        if (isProductProxy(row)) {
          return row.childrenAfterFilter.filter(
            child => !FORBIDDEN_ROW_TYPES.includes(child.data?.type)
          );
        } else {
          return [ row ];
        }
      });

      if (!rows.length) {
        return null;
      }
      const nodes = filterNodesChildren(rows);

      const selectedPeriod = getSelectedPeriod(selectionElement);
      returnParams[ ReportType.ACTUAL ] = returnParams[ ReportType.ACTUAL ].concat(
        getParamsByRowLevel(nodes, selectedPeriod[ ReportType.ACTUAL ]));
      returnParams[ ReportType.PLAN ] = returnParams[ ReportType.PLAN ].concat(
        getParamsByRowLevel(nodes, selectedPeriod[ ReportType.PLAN ]));
    }
    return returnParams;

  }, [
    getSelectedRows,
    getSelectedPeriod,
    isProperCellRangeSelected,
    templateId
  ]);

  return { getRequestParams };
};

export default useTransactionRequestParams;
