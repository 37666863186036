import React from 'react';
import WarnBeforeExitModal from 'components/elements/modals/warnings/WarnBeforeExitModal';
import useUnsavedChangesWarning from 'hooks/useUnsavedChanges';
import { memo } from 'react';
import { useBlocker } from 'react-router-dom';

interface Props {
  willLooseData: boolean;
}

const DataLossPrevention = memo(({ willLooseData }: Props) => {
  useUnsavedChangesWarning(willLooseData);

  const blocker = useBlocker(( { currentLocation, nextLocation }) => {
    if (!willLooseData) {
      return false;
    } else {
      return currentLocation.pathname !== nextLocation.pathname;
    }
  });

  return <WarnBeforeExitModal
    isVisible={ blocker?.state === 'blocked' }
    onClose={ () => blocker?.reset?.() }
    onConfirm={ () => blocker?.proceed?.() }
  />;
});

DataLossPrevention.displayName = 'DataLossPrevention';

export default DataLossPrevention;
