import { Cadence } from 'types/form.types';
import { DimensionItemRelation } from 'types/statutory.types';
import { DisplayName } from '../../../types/app.types';

// t(`inputs:data-type.integer`)
// t(`inputs:data-type.number`)
// t(`inputs:data-type.percentage`)
// t(`inputs:data-type.currency`)
// t(`inputs:data-type.date`)
// t(`inputs:data-type.date_offset_days`)
// t(`inputs:data-type.date_offset_months`)
export enum InputDataType {
  INTEGER = 'integer',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
  DATE = 'date',
  DATE_OFFSET_DAYS = 'date_offset_days',
  DATE_OFFSET_MONTHS = 'date_offset_months',
}

// t(`inputs:source.system`)
// t(`inputs:source.user`)
export enum InputSource {
  SYSTEM = 'system',
  USER = 'user',
}

export interface InputCategory {
  id: number;
  name: DisplayName;
}

export type InputValue = string;

export type InputCustomValue = {
  period: string;
  value: InputValue;
  plan: boolean;
};

export interface InputRow extends DimensionItemRelation {
  id: number;
  name: DisplayName;
  alias: string | null;
  canOverride: boolean;
  isVatPercentage: boolean;
  category: number;
  dataType: InputDataType;
  cadence: Cadence;
  source: InputSource;
  defaultValue: InputValue;
}

export interface InputRowWithValues extends InputRow {
  actualValues: InputCustomValue[];
  planValues: InputCustomValue[];
}
