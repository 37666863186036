import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { IRowNode } from 'ag-grid-community';

import type { LeftPanelType } from 'types/app.types';
import type {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { GroupSettings } from 'components/elements/tableWrapper/types/table.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { clearLeftPanel, setLeftPanel } from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';
import LeftPanelHeader
  from 'components/panels/leftPanelHeader/LeftPanelHeader';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './FinancialsLeftPanel.module.scss';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import SortSection from './sortSection/SortSection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import FiltersSection from './filterSection/FilterSection';
import { isProductProxy } from 'utils/financials.utils';

const allTabs: LeftPanelType[] = [ 'filter', 'label', 'sort', 'group' ];

interface Props {
  nodes: IRowNode[] | null;
  gridRef: MutableRefObject<AgGridReact>;
  assignLabels: AssignFunction;
  unassignLabels: UnassignFunction;
  disableTabs?: LeftPanelType[];
}
const FinancialsLeftPanel = ({
  nodes, gridRef, assignLabels, unassignLabels, disableTabs = []
}: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.leftPanel);
  const activeItem = useAppSelector(state => state.financials.active);

  const node = getSortNode(nodes);

  const availableTabs = useMemo(
    () => allTabs.filter(tab => !disableTabs.includes(tab)), [ disableTabs ]
  );

  const isActive = useMemo(() => {
    return (availableTabs as unknown[]).includes(panelType) &&
      templateId === activeItem?.templateId;
  }, [ templateId, panelType, activeItem?.templateId, availableTabs ]);

  const onChange = useCallback((key: LeftPanelType | undefined | null) => {
    if (key == null) clearLeftPanel();

    dispatch(setLeftPanel({ ...(activeItem ?? {}), panelType: key as LeftPanelType }));
  }, [ templateId ]);

  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  return <PanelWrapper type='left' isActive={ isActive } >
    <LeftPanelHeader
      availableTabs={ availableTabs }
      onChange={ onChange }
      onClose={ onClose }
      activeKey={ panelType as LeftPanelType }
    />

    {
      panelType === 'sort' && (
        <div className={ clsx(panelStyles.panelBody, styles.sort) }>
          <SortSection
            node={ node }
            availableTabs={ availableTabs }
            gridRef={ gridRef }
          />
        </div>
      )
    }

    {
      panelType === 'group' && (
        <div className={ clsx(panelStyles.panelBody, styles.sort) }>
          <GroupSelection
            isDisabled={ availableTabs && !availableTabs.includes('group') }
            gridRef={ gridRef }
            onSelect={ onGroupSelect }
          />
        </div>
      )
    }

    {
      (panelType == 'label' || panelType == 'filter') && (
        <FiltersSection
          gridRef={ gridRef }
          panelType={ panelType }
          assignLabels={ assignLabels }
          unassignLabels={ unassignLabels }
        />
      )
    }

  </PanelWrapper>;
};

export default FinancialsLeftPanel;

function getSortNode (nodes: IRowNode[] | null): IRowNode | null {
  if (!nodes || nodes.length === 0) return null;
  if (nodes.length === 1) return nodes.at(0);

  if (
    nodes.at(0)?.parent && isProductProxy(nodes.at(0).parent)
    && nodes.at(0).parent.isSelected()
  ) {
    return nodes.at(0).parent;
  }

  return nodes?.at(0) ?? null;
}
