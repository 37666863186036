import { ItemToMap } from 'utils/mapping.utils';
import { TransactionType } from 'services/organizations.service';
import { Account, Counterparty, DimensionItemRelation } from './statutory.types';
import { InvoicingFrequency } from './contracts.types';
import { PlanSettingsValues, } from './planning.types';
import { DisplayName } from './app.types';

export interface BudgetStore {
  budgets: Budget[];
  activeBudgetId: number;
  budgetItemTypes: BudgetItemType[];
  budgetItemCategories: DisplayName[];
  budgetItemCategoryInputs: { [key: string]: number[] };
  budgetItemTypesMap: ItemToMap<BudgetItemType>;
  budgetSettings: PlanSettingsValues;
  budgetGeneration: BudgetGeneration;
}

export interface Budget {
  id: number;
  name: string;
  startDate: string;
  durationMonths: number;
}

export interface BudgetItemType {
  id: number;
  name: DisplayName;
  badgeType: 'income' | 'expense' | 'other';
  category: DisplayName;
  vatPercent: string;
  typical: boolean;
  memo: DisplayName;
  defaultAccounts: BudgetItemAccounts;
  budgetItems: BudgetItem[];
  transactions: number[];
  relatedInputs: number[];
}

export const isBudgetItemType = (item: unknown): item is BudgetItemType => {
  return (item as BudgetItemType)?.defaultAccounts !== undefined;
};

export interface BudgetItemSplit {
  dimensionItem: number;
  percentage: number;
}

export interface BudgetItemAccounts {
  primary: Account['id'];
  counter?: Account['id'];
}

export interface BudgetItem extends
  DimensionItemRelation
{
  id: number;
  itemType: number;
  accounts: BudgetItemAccounts;
  counterparty: Counterparty;
  memo: string;
  invoicingFrequency: InvoicingFrequency;
  invoicingDate: number;
  amountFormula: string;
  startDate: string;
  endDate: string;
  transactionLines: number[];
  dimensionSplit: BudgetItemSplit[] | null;
  inputs: { [key: string]: string };
}

export type BudgetItemUpdateRequest = Omit<
Partial<BudgetItem>, 'id' | 'account' | 'counterparty'> & {
  id?: number;
  accounts: BudgetItemAccounts;
  counterparty: number;
};

export interface BudgetClearDimensionsRequest {
  budgetItems: BudgetItem['id'][];
  dimensions: number[];
}

export interface BudgetItemTypeTransaction {
  id: number;
  name: string;
  transactionType: TransactionType;
  first_transaction_date: string;
}

export interface BudgetItemParams {
  itemType?: number;
}

export interface BudgetGeneration {
  status: 'not_started' | 'queued' | 'started' | 'ended' | 'error';
  step: number;
  error: boolean;
  totalSteps: number;
}
