import { CellRange, Column, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Entry } from 'components/elements/tableInlineEdit/budgetItemPopover/budgetOverview.utils';
import { range } from 'lodash';
import { RowType } from 'types/financials.types';
import { ReportType } from 'types/templates.types';
import { isProductProxy } from 'utils/financials.utils';

export function getValuesCellsInRows(
  rows: IRowNode[],
  reportType: ReportType,
  columnsRanges?: string[][],
) {
  const cells: { node: IRowNode; column: string }[] = [];

  rows.forEach((row) => {
    if (isProductProxy(row)) {
      if (columnsRanges) {
        columnsRanges.forEach((columns) => {
          columns
            .filter((column) => column.startsWith(reportType))
            .forEach((column) => {
              if (row.aggData[ column ]) {
                cells.push({ node: row, column: column });
              }
            });
        });
      }
    }

    if (!row.data?.[ reportType ]) return;

    const reportEntries = Object.entries(row.data?.[ reportType ] ?? {})
      .filter(([ , value ]) => value)
      .map(([ key ]) => `${ reportType }__${ key }`);

    if (reportEntries.length) {

      if (columnsRanges) {
        columnsRanges.forEach((columns) => {
          columns.forEach((column) => {
            if (reportEntries.includes(column)) {
              cells.push({ node: row, column: column });
            }
          });
        });
      } else {
        reportEntries.forEach((entry) => {
          cells.push({ node: row, column: entry });
        });
      }
    }
  });

  return cells;
}

export function getValueCellsInRange(
  cellRanges: CellRange[],
  agGrid: AgGridReact,
  reportType: ReportType
) {
  return getValuesCellsInRows(
    getListOfSelectedRows(cellRanges , agGrid),
    reportType,
    cellRanges.map((cellRange) => cellRange.columns.map((column) => column.getColId()))
  );
}

export function getValueCellsInRowsSet(
  rows: IRowNode[],
  agGrid: AgGridReact,
  reportType: ReportType
) {
  const cells: { node: IRowNode; column: Column }[] = [];
  rows.forEach((node) => {
    Object.entries(node.data?.[ reportType ] ?? {})
      .forEach(([ key, value ]: [ string, Entry ]) => {
        if (value) {
          const column = agGrid.columnApi.getColumn(`${ reportType }__${ key }`);
          cells.push({ node, column });
        }
      });
  });

  return cells;
}

export function getCellsWithValueInSelection(
  selection: CellRange[] | IRowNode[],
  agGrid: AgGridReact,
  reportType: ReportType
) {
  if (isRangeSelection(selection)) {
    return getValueCellsInRange(selection, agGrid, reportType);
  } else {
    getValueCellsInRowsSet(selection, agGrid, reportType);
  }
}

export function getListOfSelectedRowsInRange(cellRange: CellRange) {
  const startRowIndex = cellRange.startRow?.rowIndex;
  const endRowIndex = cellRange.endRow?.rowIndex;
  const direction = endRowIndex - startRowIndex;

  return range(
    direction > 0 ? startRowIndex : endRowIndex,
    (direction > 0 ? endRowIndex : startRowIndex) + 1
  );
}

export function getListOfSelectedRows(selection: CellRange[] | IRowNode[], agGrid: AgGridReact) {
  if (isRangeSelection(selection)) {
    if (!isNotEmptySelection(selection)) return [];

    return Array.from(
      new Set(selection.flatMap((cellRange) => getListOfSelectedRowsInRange(cellRange)))
    )
      .map(i => agGrid?.api.getDisplayedRowAtIndex(i))
      .filter((row) => row);
  } else {
    return selection;
  }
}

export function areAllSelectedNewRows(
  selection: CellRange[] | IRowNode[],
  agGrid: AgGridReact,
  options: { requireValues: boolean } = { requireValues: false }
) {
  if (isRangeSelection(selection)) {
    const selectedRows = getListOfSelectedRows(selection, agGrid);

    if (!selectedRows.length) return false;

    if (selectedRows.some((row) => row?.data?.type !== RowType.NEW_BUDGET_ITEM)) {
      return false;
    }

    if (options.requireValues) {
      return getValueCellsInRange(selection, agGrid, ReportType.PLAN).length > 0;
    }

    return true;
  } else {
    if (!selection?.length) return false;
    return selection
      .every((row) => {
        if (
          options.requireValues &&
          !row.data.rowData.amount &&
          !row.data.rowData.amountFormula
        ) {
          return false;
        }
        return row?.data?.type === RowType.NEW_BUDGET_ITEM;
      });
  }
}

export function isRangeSelection(selection: CellRange[] | IRowNode[]): selection is CellRange[] {
  return (selection.at(0) as CellRange)?.columns !== undefined;
}

export function isNotEmptySelection (selection: CellRange[]) {
  return selection.at(0)?.startRow?.rowIndex != null && selection.at(0)?.endRow?.rowIndex != null;
}
