import React, { useMemo } from 'react';
import styles from './CommandBar.module.scss';
import CommandBarProxyButton from
  'components/commandBar/components/proxy/CommandBarProxy';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import { FilterCapsuleList } 
  from 'components/financials/filterCapsule/filterCapsuleList/FilterCapsuleList';
import { trimElementsOfType } from './utils/commandBar.utils';
import clsx from 'clsx';

export const COMMAND_BAR_PORTAL_ID = 'commandBarPortal';

const CommandBar = () => {
  const { state: { hidden, utils, filterCapsules } } = useCommandBar();
  const shownUtils = useMemo(() => utils.filter(b => !b.hidden), [ utils ]);

  const left = useMemo(() => {
    return trimElementsOfType(
      shownUtils.filter(button => button.placement === 'left'),
      /^divider.*/
    );
  }, [ shownUtils ]);

  const right = useMemo(() => {
    return shownUtils.filter(button => button.placement === 'right');
  }, [ shownUtils ]);

  return <div>
    <div className={ `${ styles.bar } ${ hidden ? styles.hidden : '' }` } id='commandBar'>
      <div className={ styles.left }>
        <div className={ styles.buttons }>
          {
            left.map((util) => {
              return <CommandBarProxyButton util={ util } key={ util.key }/>;
            })
          }
        </div>
        <div className={ styles.addons }>
          <div id={ COMMAND_BAR_PORTAL_ID }></div>
        </div>
      </div>
      <div className={ styles.right }>
        {
          right.map((util) => {
            return <CommandBarProxyButton util={ util } key={ util.key }/>;
          })
        }
      </div>
    </div>
    <div className={
      clsx(
        styles.bar,
        styles.secondRow,
        { [ styles.hidden ]: !filterCapsules.length }
      )
    }>
      <FilterCapsuleList filterCapsules={ filterCapsules }/>
    </div>
  </div>;
};

export default CommandBar;
