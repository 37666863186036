import React, { useCallback, useMemo, useState } from 'react';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import useTableRenderer from 'components/elements/tableWrapper/hooks/useTableRenderer';
import useTableColumns from 'components/elements/tableWrapper/hooks/useTableColumns';
import {
  GroupOption,
  PanelSetting,
  Redirect,
  TableColDef,
} from 'components/elements/tableWrapper/types/table.types';
import { getUppercaseAlphabet } from 'utils/common.utils';
import { isEqual, upperFirst } from 'lodash';
import { defaultFilter, isTransactionRow } from 'utils/financials.utils';
import PdfViewerPanel from '../panels/PdfViewerPanel';
import { useTranslation } from 'react-i18next';
import {
  EditableCallbackParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Transaction } from 'types/statutory.types';
import { displayNameComparator } from 'utils/sorting.utils';
import { ReactComponent as PdfSign } from 'assets/icons/pdfSign.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import { ReactComponent as ColumnsIcon } from 'assets/icons/column.svg';
import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';
import {
  Dimension,
  DimensionItem,
  EXTERNAL_BREAKDOWN_TYPES,
  FilterList
} from 'types/filterTable.types';
import DetailedViewLeftPanel from '../panels/DetailedViewLeftPanel';
import { AgGridReact } from 'ag-grid-react';
import { Period } from 'types/financials.types';
import { TablePanelProps } from '../panels/types/panel.types';
import {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import useLabeler, { GatheredLabels } from 'hooks/useLabeler';
import useTransactions from './useTransactions';
import Button from 'components/elements/button/Button';
import { financialsSlice, updateDetailedViewSettings } from 'store/financials.slice';
import {
  UNASSIGNED_ROW_ID,
} from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import organizationsService, { SSRMParams } from 'services/organizations.service';

interface Props {
  isGridReady: boolean;
  gridRef: React.RefObject<AgGridReact>;
  search: string;
  onShowCostLabelerModal: (nodes: IRowNode[], gatheredLabels: GatheredLabels) => void;
  templateId: number;
  period: Period;
  params: TransactionLineRequestParams[];
  filter: FilterList;
  setRedirect: React.Dispatch<React.SetStateAction<Redirect>>;
  sectionKey: string;
  tableKey: string;
  baseRequestParams: SSRMParams;
}

/**
 * For server side row model we need to provide specific field names for each column.
 * That does not correspond directly to where the value is regularly in Transaction model,
 * so we need to map it.
 */
const fieldNameMapper = (fieldName: string) => {
  return {
    'transaction.transaction_number': 'transactionNumber',
    'transaction.status': 'transactionStatus',
    'vat_deduction_percentage': 'vatDeductionPercentage',
    'tax_rate': 'taxRate',
    'invoice.invoice_date': 'invoicingDate',
    'invoice.start_date': 'startDate',
    'invoice.end_date': 'endDate',
    'transaction.transaction_source': 'transactionSource',
  }[ fieldName ];
};

/**
 * In table values we get either:
 *  - value grouping that is a key in the field mapper dictionary
 *  - value from the field as provided in transaction model
 */
const fieldNameMapperValueGetter = (_params: ValueGetterParams) => {
  const field = _params.colDef.field;
  return _params.data[ field ] || _params.data[ fieldNameMapper(field) ];
};

const useTransactionsTable = (
  {
    search,
    isGridReady,
    gridRef,
    onShowCostLabelerModal,
    templateId,
    period,
    params,
    filter,
    setRedirect,
    sectionKey,
    tableKey,
    baseRequestParams,
  }: Props) => {
  const dispatch = useAppDispatch();
  const {
    loading,
    fetchTransactions,
  } = useTransactions({ period });
  const { dimensions, dimensionMap } = useAppSelector(state => state.breakdowns);
  const [ stagingFilter, setStagingFilter ] = useState<FilterList>(defaultFilter);
  const {
    // unassignTransactionsFromBreakdownItem,
    // assignTransactionsToBreakdownItem
    labelTransactions,
  } = useLabeler(templateId);

  const [ t ] = useTranslation('tableWrapper');
  const {
    dateFormatter,
    percentageFormatter,
  } = useTableFormatter();
  const { renderField, defaultCellRenderer, amountRenderer } = useTableRenderer({ search });
  const {
    numberColumnDef,
    getDateColDef,
    accountColumnDef,
    counterpartyColDef,
    getDimensionColumns,
    getRedirectColDef,
  } = useTableColumns({ search });

  const onChangeDimensionItem = useCallback(async (
    node: IRowNode<Transaction>,
    id: number,
    dimension: number
  ) => {
    const possibleItems = dimensionMap[ dimension ]?.items.map(di => di.id) || [];
    const gatheredLabels = {
      dimensionItems: [],
      unassignedDimensions: []
    };
    if (id === UNASSIGNED_ROW_ID) {
      gatheredLabels.unassignedDimensions.push(dimension);
      // await unassignTransactionsFromBreakdownItem([ node.data ], { id: dimension });

      // Since we only have access to assigned dimension items, and (currently) only on item of
      // dimension can be assigned - we remove any assigned item that belongs to this dimension
      node.setData({
        ...node.data,
        dimensionItemIds: node.data.dimensionItemIds.filter(d => !possibleItems.includes(d))
      });
    } else {
      // Remove any assigned item that belongs to this dimension
      const dimensionItemIds = node.data.dimensionItemIds.filter(
        d => !possibleItems.includes(d));
      // await assignTransactionsToBreakdownItem([ node.data ], { id });
      gatheredLabels.dimensionItems.push(id);
      node.setData({ ...node.data, dimensionItemIds: [ ...dimensionItemIds, id ] });
    }
    await labelTransactions([ node.data ], gatheredLabels);
  }, []);

  const canBeAssigned = useCallback((
    editableParams: EditableCallbackParams<Transaction>,
    dimension: Dimension
  ) => {
    return dimension.canBeAssigned || (
      EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type) &&
      editableParams.data.budgetItem != null
    );
  }, []);

  const canBeUnassigned = useCallback((
    editableParams: EditableCallbackParams<Transaction>,
    dimension: Dimension
  ) => {
    return dimension.canBeUnassigned || (
      EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type) &&
      editableParams.data.budgetItem != null
    );
  }, []);

  const dimensionColumns = useMemo(() => {
    return getDimensionColumns({
      onChangeDimensionItem,
      canBeAssigned,
      canBeUnassigned,
      removeRelationColumns: true
    });
  }, [ getDimensionColumns, onChangeDimensionItem ]);

  const groupOptions: GroupOption[] = useMemo(() => {
    const dimensionGroupOptions = [
      ...dimensionColumns.map(dim => ({
        name: dim.headerName,
        groupName: t('common:dimensions'),
        field: dim.field,
        availableChoices: getUppercaseAlphabet(),
      })),
      {
        name: accountColumnDef.headerName,
        groupName: t('common:dimensions'),
        field: accountColumnDef.field,
      },
      {
        name: counterpartyColDef.headerName,
        groupName: t('common:dimensions'),
        field: counterpartyColDef.field,
        availableChoices: getUppercaseAlphabet(),
      },
    ].sort((a, b) => displayNameComparator(a.name, b.name));
    return [
      ...dimensionGroupOptions,
      {
        name: t('transactions-table.column.transaction-number'),
        groupName: t('common:transaction'),
        field: 'transaction.transaction_number',
      },
      {
        name: t('transactions-table.column.transaction-source'),
        groupName: t('common:transaction'),
        field: 'transaction.transaction_source',
        availableChoices: null,
      },
      {
        name: t('transactions-table.column.transaction-status'),
        groupName: t('common:transaction'),
        field: 'transaction.status',
        availableChoices: null,
      },
      {
        name: t('transactions-table.column.vat-percentage'),
        groupName: t('common:transaction'),
        field: 'tax_rate',
      },
      {
        name: t('transactions-table.column.vat-deduction-percentage'),
        groupName: t('common:transaction'),
        field: 'vat_deduction_percentage',
      },
      {
        name: t('transactions-table.column.startDate'),
        groupName: t('common:date'),
        field: 'invoice.start_date',
      },
      {
        name: t('transactions-table.column.endDate'),
        groupName: t('common:date'),
        field: 'invoice.end_date',
      }
    ];
  }, [ dimensionColumns, accountColumnDef, counterpartyColDef ]);

  const columnDefs = useMemo((): TableColDef[] => {
    const columns = [
      {
        ...renderField('transaction.transaction_date',
          t('transactions-table.column.transaction-date')),
        flex: 0,
        width: 120,
        valueGetter: (_params: ValueGetterParams) => {
          return _params.data?.transactionDate;
        },
        suppressHide: true,
        valueFormatter: dateFormatter,
        getQuickFilterText: dateFormatter,
        group: t('common:transaction'),
      },
      {
        ...renderField('split', t('transactions-table.column.split')),
        flex: 0,
        sortable: false,
        width: 80,
        cellRenderer: defaultCellRenderer,
        valueGetter: (_params: ValueGetterParams) => {
          if (isTransactionRow(_params.node)) {
            if (_params.node.data.dimensionItems.some(item => item.percentage !== '100.00'))
              return <SplitIcon />;
          }
          return '';
        },
        suppressHide: false,
        group: t('common:transaction'),
      },
      {
        ...accountColumnDef,
      },
      {
        ...counterpartyColDef,
        group: t('common:dimensions'),
        cellDataType: 'text',
      },
      {
        ...renderField('memo', t('transactions-table.column.memo')),
        flex: 0,
        width: 350,
        maxWidth: 800,

        // This allows for translating some system memos from backend
        valueFormatter: (_params: ValueFormatterParams) => t(_params.value, { ns: 'financials' }),
        suppressHide: true,
        group: t('common:transaction'),
        cellDataType: 'text',
      },
      {
        ...renderField('amount', t('transactions-table.column.amount')),
        flex: 0,
        width: 120,
        suppressHide: true,
        ...numberColumnDef,
        cellRenderer: amountRenderer,
        showTotal: true,
        showSubtotal: true,
      },
      {
        ...renderField('tax_rate', t('transactions-table.column.vat-percentage')),
        flex: 0,
        width: 80,
        valueFormatter: percentageFormatter,
        valueGetter: fieldNameMapperValueGetter,
        getQuickFilterText: percentageFormatter,
        ...numberColumnDef,
        group: t('common:transaction'),
      },
      {
        ...renderField(
          'vat_deduction_percentage',
          t('transactions-table.column.vat-deduction-percentage')
        ),
        wrapHeaderText: true,
        flex: 0,
        width: 120,
        valueGetter: fieldNameMapperValueGetter,
        valueFormatter: percentageFormatter,
        getQuickFilterText: percentageFormatter,
        ...numberColumnDef,
        group: t('common:transaction'),
      },
      {
        ...renderField('vat_amount', t('transactions-table.column.vat-amount')),
        flex: 0,
        width: 100,
        wrapHeaderText: true,
        valueGetter: (_params: ValueGetterParams) => {
          if (_params.node.footer || _params.node.rowPinned) {
            return null;
          }
          const vat = _params.data?.taxRate;
          const amount = _params.data?.amount;
          const vatAmount = vat ? amount * vat / 100 : 0;
          return vatAmount.toFixed(2);
        },
        ...numberColumnDef,
        group: t('common:transaction'),
      },
      {
        ...renderField('transaction.transaction_number',
          t('transactions-table.column.transaction-number')),
        valueGetter: fieldNameMapperValueGetter,
        valueFormatter: (_params: ValueFormatterParams) => _params.value,
        flex: 0,
        width: 140,
        wrapHeaderText: true,
        cellRendererParams: {
          group: true,
        },
        ...numberColumnDef,
        group: t('common:transaction'),
      },
      {
        ...renderField('transaction.transaction_source',
          t('transactions-table.column.transaction-source')),
        flex: 0,
        width: 140,
        wrapHeaderText: true,
        valueGetter: fieldNameMapperValueGetter,
        valueFormatter: (_params: ValueFormatterParams) => upperFirst(_params.value),
        group: t('common:transaction'),
        cellDataType: 'text',
      },
      {
        ...renderField('transaction.status', t('transactions-table.column.transaction-status')),
        flex: 0,
        width: 140,
        wrapHeaderText: true,
        valueGetter: fieldNameMapperValueGetter,
        valueFormatter: (_params: ValueFormatterParams) =>
          _params.value ? upperFirst(_params.value.toString().replaceAll('_', ' ')) : '',
        group: t('common:transaction'),
      },
      {
        ...getDateColDef('invoice.start_date', t('transactions-table.column.startDate')),
        group: t('common:date'),
        valueGetter: fieldNameMapperValueGetter,
      },
      {
        ...getDateColDef('invoice.end_date', t('transactions-table.column.endDate')),
        group: t('common:date'),
        valueGetter: fieldNameMapperValueGetter,
      },
      {
        ...getDateColDef('invoice.invoice_date', t('transactions-table.column.invoicing-date')),
        valueGetter: fieldNameMapperValueGetter,
        wrapHeaderText: true,
        group: t('common:transaction'),
      }
    ];

    return [
      getRedirectColDef('budgetItem', (node) => {
        setRedirect({
          rowNodeIds: [ node.data.budgetItem ],
          clear: () => setRedirect(null),
          sectionKey: sectionKeys.budgeting,
        });
      }),
      ...columns,
      ...dimensionColumns
    ];
  }, [
    search,
    renderField,
    getDateColDef,
    dimensions,
    dimensionColumns
  ]);

  const getServerSideParams = (): SSRMParams => {
    const filterModel = gridRef.current.api?.getFilterModel();
    return {
      ...baseRequestParams,
      filterModel: {
        ...filterModel,
        ...baseRequestParams.filterModel
      }
    };
  };

  const unassignLabels: UnassignFunction = useCallback(async (
    nodes: IRowNode<Transaction>[],
    dimension: Dimension,
    showModal
  ) => {
    if (showModal) {
      const _gatheredLabels = {
        dimensionItems: [],
        unassignedDimensions: [ dimension.id ]
      };
      onShowCostLabelerModal(nodes, _gatheredLabels);
    } else {
      const serverSideSelectionState = gridRef.current.api.getServerSideSelectionState();
      const areAllSelected = serverSideSelectionState[ 'selectAll' ];
      if (areAllSelected) {
        const toggledRows = serverSideSelectionState[ 'toggledNodes' ].map(
          nodeId => gridRef.current.api?.getRowNode(nodeId)
        );
        const excludedTransactionLineIds = toggledRows.map(row => row.data.id);
        const requestParams = getServerSideParams();
        await organizationsService.clearDimensions({
          ...requestParams.filterModel,
          excludedTransactionLineIds,
        }, dimension.id);
        dispatch(financialsSlice.actions.setLabelingTimestamp(
          { templateId: templateId, value: Date.now() }));
      }
      const transactionNodes = nodes.map(node => node.data);
      await labelTransactions(transactionNodes, { unassignedDimensions: [ dimension.id ] });
      await fetchTransactions({ filter, params });

      gridRef.current.api.deselectAll();
      gridRef.current.api.onFilterChanged();
    }
  }, [ fetchTransactions, filter, params ]);

  const assignLabels: AssignFunction = useCallback(async (
    nodes: IRowNode<Transaction>[],
    dimensionItem: DimensionItem,
    showModal
  ) => {
    if (showModal) {
      onShowCostLabelerModal(nodes, { dimensionItems: [ dimensionItem.id ] });
    } else {
      const serverSideSelectionState = gridRef.current.api.getServerSideSelectionState();
      const areAllSelected = serverSideSelectionState[ 'selectAll' ];
      if (areAllSelected) {
        const toggledRows = serverSideSelectionState[ 'toggledNodes' ].map(
          nodeId => gridRef.current.api?.getRowNode(nodeId)
        );
        const excludedTransactionLineIds = toggledRows.map(row => row.data.id);
        const requestParams = getServerSideParams();
        await organizationsService.addDimensionToTransaction({
          dimensionItem: dimensionItem.id,
          ...requestParams.filterModel,
          excludedTransactionLineIds,
        });
        dispatch(financialsSlice.actions.setLabelingTimestamp(
          { templateId: templateId, value: Date.now() }
        ));
      } else {
        const transactionNodes = nodes.map(node => node.data);
        await labelTransactions(transactionNodes, { dimensionItems: [ dimensionItem.id ] });
        await fetchTransactions({ filter, params });
      }
      gridRef.current.api.deselectAll();
      gridRef.current.api.onFilterChanged();

    }
  }, [ fetchTransactions, filter, params, baseRequestParams ]);

  const setFilter = useCallback((_filter: FilterList) => {
    dispatch(updateDetailedViewSettings({ filter: _filter }));
  }, []);

  const renderRowOperationPanel = useCallback((
    mode: 'label' | 'filter' | 'sort' | 'group' | 'columns',
    props: TablePanelProps<Transaction>
  ) => {
    return <DetailedViewLeftPanel
      availableTabs={ [ 'label', 'filter', 'sort', 'group', 'columns' ] }
      groupOptions={ groupOptions }
      mode={ mode }
      isGridReady={ isGridReady }
      gridRef={ gridRef }
      stagingFilter={ stagingFilter }
      setStagingFilter={ setStagingFilter }
      filter={ filter }
      setFilter={ setFilter }
      unassignLabels={ unassignLabels }
      assignLabels={ assignLabels }
      tableKey={ tableKey }
      dimensionsPanelConfig={ { hideLazyLabelSetting: true } }
      sectionKey={ sectionKey }
      { ...props }
    />;
  }, [ stagingFilter, filter, sectionKey, tableKey, isGridReady, unassignLabels, assignLabels ]);

  const clearFilters = useCallback(() => {
    setFilter(defaultFilter);
    setStagingFilter(defaultFilter);
  }, []);

  const panels = useMemo((): PanelSetting<Transaction>[] => {
    return [
      {
        key: 'pdf-viewer',
        render: (props) => <PdfViewerPanel { ...props } />,
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.pdf') }>
          <PdfSign/>
        </GhostIconButton>,
        buttonPosition: 3,
      },
      {
        key: 'filter',
        render: (props) => renderRowOperationPanel('filter', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.filter') }>
          <FilterIcon/>
        </GhostIconButton>,
        additionalButton: !isEqual(filter, defaultFilter) ? <Button
          onClick={ clearFilters }
          type='text'
        >
          { t('common:form.clear-all-filters') }
        </Button> : null,
        defaultWidth: 370,
        buttonPosition: 4,
      },
      {
        key: 'label',
        render: (props) => renderRowOperationPanel('label', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.label') }>
          <LabelIcon/>
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 5,
      },
      {
        key: 'sort',
        render: (props) => renderRowOperationPanel('sort', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.sort') }>
          <SortIcon/>
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 6,
      },
      {
        key: 'group',
        render: (props) => renderRowOperationPanel('group', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.group') }>
          <GroupIcon/>
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 7,
      },
      {
        key: 'columns',
        render: (props) => renderRowOperationPanel('columns', props),
        button: <GhostIconButton tooltip={ t('common:command-bar.tooltip.columns') }>
          <ColumnsIcon/>
        </GhostIconButton>,
        defaultWidth: 370,
        buttonPosition: 8,
      },
    ];
  }, [ renderRowOperationPanel, filter ]);

  return {
    panels,
    columnDefs,
    groupOptions,
    fetchTransactions,
    loading,
  };
};

export default useTransactionsTable;
