import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './Editors.module.scss';
import { Input, InputRef } from 'antd';
import { FieldErrors } from 'react-hook-form';
import clsx from 'clsx';

interface Props {
  value: string;
  field: string;
  headerName: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  errors: FieldErrors;
  className?: string;
}

const TextCellEditor = (props: Props) => {
  const [ value, setValue ] = useState(props.value);
  const refInput = useRef<InputRef>(null);

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    props.setValue(value);
  }, [ value ]);

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <Input
      ref={ refInput }
      value={ value }
      name={ props.field }
      disabled={ props.disabled }
      placeholder={ props.placeholder }
      onChange={ (e) => setValue(e.target.value) }
      status={ props.errors[ props.field ] ? 'error' : null }
      className={ clsx(styles.textInput, props.className) }
    />
  );
};

TextCellEditor.displayName = 'TextCellEditor';
export default TextCellEditor;
