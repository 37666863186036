import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import SearchableSelect from '../../../searchableSelect/SearchableSelect';
import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import clsx from 'clsx';

const SELECT_KEY_BINDING = { code: 'Enter' };

type SelectValue = Value | number | string;

type Value = {
  id: number | string;
  name: number | string;
};
interface ValuesProps {
  values: SelectValue[];
}

const SelectCellEditor = forwardRef((
  props: ICellEditorParams & ValuesProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  const value = props.value;
  const column = props.column.getColId();
  const [ open, setOpen ] = useState(true);

  const options = useMemo(() => {
    return props.values.map((_value: Value | string) => ({
      value: typeof _value === 'object' ? _value?.id : _value,
      label: props.formatValue ? props.formatValue(_value) :
        typeof _value === 'object' ? _value?.name : _value,
    }));
  }, [ props.values, props.formatValue ]);

  const updateValue = useCallback((val) => {
    props.node.setDataValue(column, val);
  }, [ column ]);

  const setFocusOnCell = useCallback(() => {
    const focusedCell = props.api.getFocusedCell();
    props.api.clearFocusedCell();
    props.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column, focusedCell.rowPinned);
  }, []);

  const isObjectArray = (array: SelectValue[]): array is Value[] => {
    return typeof array[ 0 ] === 'object';
  };

  const onChange = useCallback((key: number) => {
    if (isObjectArray(props.values)) {
      const _value = props.values.find((el) => el.id === key);
      updateValue(_value);
    } else {
      updateValue(key);
    }
  }, [ props.values, updateValue ]);

  const openDropdown = useCallback(() => setOpen(true), []);

  useKeyPressListener({ keyBinding: SELECT_KEY_BINDING, cb: openDropdown, enabled: open });

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
    if (!_open) {
      setFocusOnCell();
    }
  }, []);

  return (
    <SearchableSelect
      open={ open }
      onDropdownVisibleChange={ onOpenChange }
      dropdownMatchSelectWidth={ 450 }
      autoFocus={ true }
      value={ typeof value === 'object' ? value?.id : value }
      options={ options }
      popupClassName='ag-custom-component-popup'
      className={ clsx(styles.fullCellInput, styles.primaryBorder) }
      onChange={ onChange }
      callback={ (e) => {
        if (e.key === 'Tab') {
          e.preventDefault();
          props.api.tabToNextCell();
        }
      } }
    />
  );
});

SelectCellEditor.displayName = 'SelectCellEditor';
export default SelectCellEditor;
