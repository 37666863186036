import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Card } from 'antd';
import clsx from 'clsx';

import { GroupOption, GroupSettings } from 'components/elements/tableWrapper/types/table.types';
import type { LeftPanelType, PanelType } from 'types/app.types';
import { appSlice } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import ColumnSelection from 'components/panels/columnSelection/ColumnSelection';
import LeftPanelHeader
  from 'components/panels/leftPanelHeader/LeftPanelHeader';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';
import SearchBar from 'components/elements/searchBar/SearchBar';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './InputsLeftPanel.module.scss';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import SortSelection,
{ convertColumnsStateToSortForm } from 'components/panels/sortSelection/SortSelection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { useTableContext } from 'context/TableContext';

const AVAILABLE_TABS: LeftPanelType[] = [ 'sort', 'group', 'columns' ];

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  groupOptions?: GroupOption[];
}
const RulesLeftPanel = ({ gridRef, groupOptions }: Props) => {

  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);

  const isActive = activePanelType != null;

  const onChange = (key: PanelType) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  const onClose = () => {
    dispatch(appSlice.actions.setLeftPanel(null));
  };

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  const { sortState, defaultSorting } = useTableContext();

  if (!gridRef.current) {
    return null;
  }

  return <PanelWrapper
    type='left'
    isActive={ isActive }
  >
    <Card bordered={ false } className={ clsx(panelStyles.card, styles.card) }>
      <LeftPanelHeader
        availableTabs={ AVAILABLE_TABS }
        onChange={ onChange }
        onClose={ onClose }
        activeKey={ activePanelType as LeftPanelType }
      />

      {
        activePanelType === 'columns' && (
          <>
            <SearchBar
              className={ panelStyles.searchBox }
              showSeparator={ false }
              showResults={ false }
            />
            <div className={ clsx(panelStyles.panelBody) }>
              <ColumnSelection
                isGridReady
                gridRef={ gridRef }
                sectionKey='labelingRules'
                tableKey='labelingRules'
              />
            </div>
          </>
        )
      }

      { 
        (activePanelType === 'sort') && (
          <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
            <SortSelection
              isDisabled={ AVAILABLE_TABS && !AVAILABLE_TABS.includes('sort') }
              gridRef={ gridRef }
              sortState={ convertColumnsStateToSortForm(sortState) }
              defaults={ convertColumnsStateToSortForm(defaultSorting) }
            />
          </div>
        )               
      }

      { 
        (activePanelType === 'group') && (
          <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
            <GroupSelection
              onSelect={ onGroupSelect }
              isDisabled={ AVAILABLE_TABS && !AVAILABLE_TABS.includes('group') }
              gridRef={ gridRef }
              options={ groupOptions }
            />
          </div>
        )               
      }

    </Card>
  </PanelWrapper>;
};

export default RulesLeftPanel;
