import React, {
  forwardRef, useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import styles from './Editors.module.scss';
import { Input, InputRef, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormulaRow } from 'components/formulaRow/FormulaRowContext';

interface ValidationProps {
  maxLength: number;
}

const TextCellEditor = forwardRef((props: ICellEditorParams & ValidationProps , ref) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState(props.value);
  const refInput = useRef<InputRef>(null);
  const field = props.column.getColDef().headerName;
  const { dispatch, state: { open, formula } } = useFormulaRow();
  const isFormulaRowCell = props.column.getColId() === 'amountFormula';

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    if (isFormulaRowCell && value?.toString().startsWith('=')) {
      const _value = getValueOrLimit(value);
      dispatch({ type: 'SAVE', payload: _value });
    }
  }, [ value ]);

  const getValueOrLimit = useCallback((val: string) => {
    return val?.toString().slice(0, props.maxLength) ?? '';
  }, []);

  useEffect(() => {
    if (open && formula != null && formula != value?.toString()) {
      setValue(formula);
      setTimeout(props.stopEditing, 0);
    }
  }, [ open, formula ]);

  useEffect(() => {
    if (isFormulaRowCell && value?.toString().startsWith('=')) {
      dispatch({ type: 'OPEN', payload: value });
    }

    return () => {
      if (isFormulaRowCell) {
        dispatch({ type: 'CLOSE' });
      }
    };
  }, [ value ]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return getValueOrLimit(value);
      },
    };
  });

  const valid = useMemo(() => {
    if (!props.maxLength) {
      return true;
    }
    if (!value) {
      return true;
    }
    return value.length <= props.maxLength;
  }, [ props, value ]);

  const onKeyDownCapture = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const isStartOfFormula = isFormulaRowCell && e.key === '=';

    if (isStartOfFormula && !open) {
      dispatch({ type: 'OPEN', payload: value });
    }

    if (isFormulaRowCell && e.key === 'Enter') {
      dispatch({ type: 'CLOSE' });
    }
  }, [ open, value ]);

  return (
    <Tooltip
      open={ !valid }
      title={ t('notifications.maxLength.message', { field, length: props.maxLength }) }
    >
      <Input
        ref={ refInput }
        value={ value }
        onKeyDown={ onKeyDownCapture }
        onChange={ (e) => setValue(e.target.value) }
        status={ valid ? null : 'error' }
        className={ styles.textInput }
      />
    </Tooltip>
  );
});

TextCellEditor.displayName = 'TextCellEditor';
export default TextCellEditor;
