import { MutableRefObject, useCallback, useEffect } from 'react';

interface KeyBinding {
  withShift?: boolean;
  withCtrl?: boolean;
  withAlt?: boolean;
  withMeta?: boolean;
}

export interface KeyBindingWithCode extends KeyBinding {
  code: KeyboardEvent['code'];
  key?: never;
}

export interface KeyBindingWithKey extends KeyBinding {
  code?: never;
  key: KeyboardEvent['key'];
}

interface Args {
  ref?: MutableRefObject<HTMLElement | Document>;
  cb: (event: KeyboardEvent) => void;
  keyBinding: KeyBindingWithCode | KeyBindingWithKey;
  alternativeKeyBinding?: KeyBindingWithCode | KeyBindingWithKey;
  enabled?: boolean;
}

const useKeyPressListener = ({
  keyBinding, ref, cb, alternativeKeyBinding, enabled = true
}: Args) => {
  const _ref = ref ?? { current: document };

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (!_ref?.current?.contains(event.target as Node)) return;

    if (keyBindMatcher(keyBinding, event) || keyBindMatcher(alternativeKeyBinding, event)) {
      event.preventDefault();
      cb(event);
    }

    return undefined;
  }, [ cb, keyBindMatcher, keyBinding, alternativeKeyBinding, _ref ]);

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [ enabled, onKeyDown ]);
};

export default useKeyPressListener;

function keyBindMatcher(keyBinding: KeyBindingWithCode | KeyBindingWithKey, event: KeyboardEvent) {
  if (!keyBinding) return false;

  if (
    keyBinding.withShift && !event.shiftKey ||
    !keyBinding.withShift && event.shiftKey
  ) return;
  
  if (
    keyBinding.withCtrl && !event.ctrlKey ||
    !keyBinding.withCtrl && event.ctrlKey
  ) return;
  
  if (
    keyBinding.withAlt && !event.altKey ||
    !keyBinding.withAlt && event.altKey
  ) return;
  
  if (
    keyBinding.withMeta && !event.metaKey ||
    !keyBinding.withMeta && event.metaKey
  ) return;

  if (keyBinding.code) {
    return keyBinding.code === event.code;
  }

  return keyBinding.key === event.key;
}
