import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateType, UserTemplateShort } from 'types/templates.types';
import { selectAllTemplates, selectTemplateList } from 'store/templates.slice';
import {
  selectTemplate,
} from 'store/template.slice';
import { ReactComponent as Arrow } from 'assets/icons/chevron-down.svg';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import { ReactComponent as NewBrowser } from 'assets/icons/newBrowser.svg';
import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';

import styles from './BuilderTemplateDropdown.module.scss';
import { routes } from 'utils/config.utils';
import { useAppSelector } from '../../../store/hooks/hooks';
import TemplateDropdown from '../../elements/templateDropdown/TemplateDropdown';
import useTemplateService from '../hooks/useTemplateService';
import { RowHoverAction } from '../../elements/sectionDropdown/types/sectionDropdown.types';
import { selectCustomFormula } from 'store/formula.slice';

interface Props {
  setLoading: (loading: boolean) => void;
  onDelete: (template: UserTemplateShort) => void;
}

const BuilderTemplateDropdown = ({ setLoading, onDelete }: Props) => {
  const [ t ] = useTranslation('financials');
  const activeTemplate = useAppSelector(selectTemplate);
  const templateList = useAppSelector(selectTemplateList);
  const allTemplates = useAppSelector(selectAllTemplates);
  const customFormula = useAppSelector(selectCustomFormula);
  const user = useAppSelector(state => state.auth.user);
  const [ open, setOpen ] = useState(false);
  const {
    handleTemplateChange,
    isSystemTemplate,
    copyTemplate
  } = useTemplateService();

  const rowHoverActions: RowHoverAction[] = useMemo(() => ([
    {
      getIcon: () => <NewBrowser role='button' aria-label='open-report' />,
      onClick: (id: number) => {
        window.open(`${ routes.report.templateBuilder }/${ id }`, '_blank');
        setOpen(false);
      }
    },
    {
      getIcon: () => <Duplicate role='button' aria-label='duplicate' />,
      onClick: (id: number) => {
        copyTemplate(id);
        setOpen(false);
      }
    },
    {
      getIcon: () => <Bin role='button' aria-label='delete-template' />,
      onClick: (id: number) => {
        const template = templateList.find(el => el.id === id);
        onDelete(template);
        setOpen(false);
      },
      isHidden: (id: number) => isSystemTemplate(id)
    }
  ]), [ templateList ]);

  const onSelect = useCallback((id: number) => {
    if (id === activeTemplate.id) {
      return setOpen(false);
    }
    const selectedTemplate = allTemplates.find(el => el.id === id);
    if (selectedTemplate && selectedTemplate.type !== TemplateType.USER && !user.isSuperuser) {
      copyTemplate(id);
      return setOpen(false);
    }
    setLoading(true);
    handleTemplateChange(id).finally(() => {
      setLoading(false);
      setOpen(false);
    });
  }, [ activeTemplate, handleTemplateChange ]);

  return (
    <div role='button' aria-label='dropdown-button'>
      <TemplateDropdown
        showOther={ false }
        isSelected={ element => element === activeTemplate.id }
        onSelect={ onSelect }
        isDisabled={ () => false }
        multiSelect={ false }
        rowHoverActions={ rowHoverActions }
        disabled={ customFormula !== null }
        open={ open }
        onOpenChange={ (_open) => setOpen(_open) }
      >
        <div className={ `${ styles.dropdownButton }` }>
          <div className={ styles.title }>
            { activeTemplate.title || t('managerTemplateDropDown.empty-template') }
          </div>
          <Arrow width={ 18 } height={ 18 }/>
        </div>
      </TemplateDropdown>
    </div>
  );
};

export default BuilderTemplateDropdown;
