import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import { Card } from 'antd';

import type { LeftPanelType, PanelType } from 'types/app.types';
import {
  GroupOption, GroupSettings
} from 'components/elements/tableWrapper/types/table.types';
import { appSlice } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import LeftPanelHeader
  from 'components/panels/leftPanelHeader/LeftPanelHeader';
import SearchBar from 'components/elements/searchBar/SearchBar';
import ColumnSelection from 'components/panels/columnSelection/ColumnSelection';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './InputsLeftPanel.module.scss';
import DimensionsView
  from '../../elements/dimensionLeftPanel/listView/dimensionsView/DimensionsView';
import type { FilterList } from '../../../types/filterTable.types';
import FinancialFooter from '../../elements/dimensionLeftPanel/footer/FinancialFooter';
import { clearDimensionFilter, inputsSlice } from '../../../store/inputs.slice';
import { INPUT_TEMPLATE_ID } from '../utils/inputs.utils';
import SortSelection,
{ convertColumnsStateToSortForm } from 'components/panels/sortSelection/SortSelection';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { useTableContext } from 'context/TableContext';

const AVAILABLE_TABS: LeftPanelType[] = [ 'sort', 'group', 'columns', 'filter' ];

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  groupOptions?: GroupOption[];
}
const InputsLeftPanel = ({ gridRef, groupOptions = [] }: Props) => {

  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);
  const filters = useAppSelector(store => store.inputs.filters);
  const [ stagingFilter, setStagingFilter ] = useState<FilterList>([]);

  const { defaultSorting, sortState } = useTableContext();

  const isActive = AVAILABLE_TABS.includes(activePanelType as LeftPanelType);
  useEffect(() => {
    if (filters) {
      setStagingFilter(filters);
    }
  }, [ filters ]);

  const onChange = (key: PanelType) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  const onClose = () => {
    dispatch(appSlice.actions.setLeftPanel(null));
  };

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  if (!gridRef.current) {
    return null;
  }

  const setFilters = (newFilters: FilterList) => {
    setStagingFilter(newFilters);
    dispatch(inputsSlice.actions.setFilters(newFilters));
  };

  return <PanelWrapper
    type='left'
    isActive={ isActive }
  >
    <Card bordered={ false } className={ clsx(panelStyles.card, styles.card) }>
      <LeftPanelHeader
        availableTabs={ AVAILABLE_TABS }
        onChange={ onChange }
        onClose={ onClose }
        activeKey={ activePanelType as LeftPanelType }
      />

      {
        activePanelType === 'columns' && (
          <SearchBar
            className={ panelStyles.searchBox }
            showSeparator={ false }
            showResults={ false }
          />)
      }

      <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>    
        { 
          (activePanelType === 'sort') && (
            <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
              <SortSelection
                isDisabled={ AVAILABLE_TABS && !AVAILABLE_TABS.includes('sort') }
                gridRef={ gridRef }
                defaults={ convertColumnsStateToSortForm(defaultSorting) }
                sortState={ convertColumnsStateToSortForm(sortState) }
              />
            </div>
          )               
        }

        { 
          (activePanelType === 'group') && (
            <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
              <GroupSelection
                onSelect={ onGroupSelect }
                isDisabled={ AVAILABLE_TABS && !AVAILABLE_TABS.includes('group') }
                gridRef={ gridRef }
                options={ groupOptions }
              />
            </div>
          )               
        }

        {
          activePanelType === 'filter' && (
            <DimensionsView
              mode='filter'
              templateId={ null }
              filters={ filters }
              stagingFilter={ stagingFilter }
              setStagingFilter={ setStagingFilter }
              classContainer={ styles.dimensionsList }
              gridRef={ gridRef }
              onClearFilters={ (event: MouseEvent, dimensionId) => {
                event.stopPropagation();
                if (dimensionId) {
                  dispatch(clearDimensionFilter(dimensionId));
                } else {
                  setFilters([]);
                }

              } }
              defaultUnassignNodesCallback={ () => null }
              assignLabels={ () => null }
            />
          )
        }

        {
          activePanelType === 'filter' && (
            <FinancialFooter
              filters={ filters }
              stagingFilter={ stagingFilter }
              onApplyFilters={ () => setFilters(stagingFilter) }
              onClearFilters={ () => {
                setFilters([]);
              } }
            />
          )
        }

        { activePanelType === 'columns' && (
          <ColumnSelection
            isGridReady
            gridRef={ gridRef }
            tableKey={ INPUT_TEMPLATE_ID.toString() }
          />
        ) } 
      </div>

    </Card>
  </PanelWrapper>;
};

export default InputsLeftPanel;
