export const emptyLabel = {
  dimensionItemId: null,
  percentage: 0,
  nominalValue: null,
  draft: true
};

export const emptyDimension = {
  dimension: null,
  labels: [ emptyLabel, emptyLabel ],
  draft: true
};

export type SplitCloseMessages = 'split:created' | 'split:deleted';
export type SplitMessages = 'split:dimensionRemoved';
