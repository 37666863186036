import styles from './ShimmerText.module.scss';
import React from 'react';

interface Props {
  text: string;
}

/**
 * Component that will make a shimmer effect from left to right on the text,
 * indicating loading or waiting action.
 */
const ShimmerText = ({ text }: Props) => {
  return (
    <span className={ styles.shimmerSpan }>
      { text }
      <span className={ styles.shimmer }></span>
    </span>
  );
};

export default ShimmerText;
