import React, { useMemo } from 'react';
import Modal from 'components/elements/modal/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-bin.svg';

import styles from './ConfirmRowsDelete.module.scss';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { getDisplayName } from '../../../../utils/common.utils';
import { IRowNode } from 'ag-grid-community';
import { getValuesCellsInRows } from 'components/financials/utils/selection.utils';
import { Account } from 'types/statutory.types';
import dayjs from 'dayjs';
import { DATE_FORMATS } from 'utils/date.utils';
import { ReportType } from 'types/templates.types';

interface Props {
  isVisible: boolean;
  rows: IRowNode[];
  columns?: string[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmRowsDelete = ({ rows, isVisible, columns, onClose, onConfirm }: Props) => {
  const [ t ] = useTranslation('common');

  let description = '';

  const elementsToRemove = useMemo(() => {
    if (!rows) return [];

    if (columns?.length) {
      return getValuesCellsInRows(rows, ReportType.PLAN, [ columns ]);
    } else {
      return rows.map((node) => ({ node, column: null }));
    }
  }, [ rows, columns ]);

  if (elementsToRemove.length === 1) {
    if (columns?.length) {
      description = t('warnings.delete-cells', { memo: rows.at(0).data.rowData.memo ?? '...' });
    } else {
      description = t('warnings.delete-rows', { memo: rows.at(0).data.rowData.memo ?? '...' });
    }
  } else {
    if (columns?.length) {
      description = t('warnings.delete-columns-multiple', { count: elementsToRemove.length });
    } else {
      description = t('warnings.delete-rows-multiple', { count: elementsToRemove.length });
    }
  }

  const accountMap = useAppSelector((state) => state.breakdowns.accountMap);

  const getAccountDisplay = (id: Account[ 'id' ]) => {
    const account = accountMap[ id ];
    if (!account) return '';
    return `${ account?.number } ${ getDisplayName(account?.name) }`;
  };

  return <Modal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onConfirm }
    okText={ t('delete') }
    isDangerous
    destroyOnClose
    title={ <div className={ styles.title }>
      <TrashIcon />
      {
        columns && columns.length
          ? t('warnings.delete-columns-title', { count: elementsToRemove.length })
          : t('warnings.delete-rows-title', { count: elementsToRemove.length })
      }
    </div> }
    description={ description }
  >
    <div className={ styles.modalContent }>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>{ t('date') }</th>
            <th>{ t('financials:inline-edit.fields.account') }</th>
            <th>{ t('financials:inline-edit.fields.counterparty') }</th>
            <th>{ t('financials:inline-edit.fields.memo') }</th>
          </tr>
        </thead>
        <tbody>
          {
            elementsToRemove.map(({ node, column }) => (
              <tr key={ `${ node.id }__${ column }` }>
                <td>
                  { column ?
                    getDateFromColumn(column) :
                    formatDate(node.data.rowData.startDate)
                  }
                </td>
                <td>{ getAccountDisplay(node.data.rowData?.accounts?.primary) }</td>
                <td>{ node.data.rowData.counterparty?.name }</td>
                <td>{ node.data.rowData.memo }</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  </Modal>;
};

export default ConfirmRowsDelete;

function getDateFromColumn (column: string) {
  return formatDate(column.split('__').at(-1));
}

function formatDate (date: string) {
  if (!date) return '';

  return dayjs(date).format(DATE_FORMATS.at(0));
}