import clsx from 'clsx';
import React, { ReactNode, PropsWithChildren } from 'react';
import styles from './Editors.module.scss';

interface Props {
  prefixIcon: ReactNode;
  className?: string;
  hasValue: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const InputGroupWithIcon = ({
  prefixIcon,
  hasValue,
  children,
  className,
  disabled
}: PropsWithChildren<Props>
) => {
  return <div className={ clsx(
    styles.inputWithIconWrapper,
    {
      [ styles.withPrefix ]: !!prefixIcon,
      [ styles.placeholder ]: !hasValue || disabled
    },
    className
  ) }>
    { prefixIcon && <span className={ styles.prefixIcon }>
      { prefixIcon }
    </span> }
    { children }        
  </div>;
};

export default InputGroupWithIcon;
