import React, { useEffect, useMemo } from 'react';
import { Progress } from 'antd';
import styles from './Messages.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { endBudgetGeneration } from 'store/budget.slice';
import ShimmerText from '../../../elements/shimmerText/ShimmerText';
import { useTranslation } from 'react-i18next';

interface Props {
  step: number;
  totalSteps: number;
}

const BudgetGenerationInProgress = ({ step, totalSteps }: Props) => {
  const percent = useMemo(() => {
    return Math.round((step / totalSteps) * 100) || 0;
  }, [ step, totalSteps ]);
  const currentBudgetGenerationStatus = useAppSelector((state) => state.budget.budgetGeneration);

  const [ t ] = useTranslation('financials');

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      if (currentBudgetGenerationStatus.status !== 'queued') {
        dispatch(endBudgetGeneration());
      }
    };
  }, []);

  return (
    <div className={ `${ styles.message } ${ styles.messageInProgress }` }>
      <Progress
        strokeColor='#4CAF50'
        percent={ percent }
        showInfo={ false }
      />
      <span className={ styles.percent }>
        <ShimmerText text={ t('planning:generation-status.calculating') + ' ' + percent + ' %' }/>
      </span>
    </div>
  );
};

export default BudgetGenerationInProgress;
