import React, { RefObject, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Card } from 'antd';
import { AgGridReact } from 'ag-grid-react';

import type { LeftPanelType, PanelType } from 'types/app.types';
import type { TablePanelProps } from './types/panel.types';
import type { FilterList } from '../../../../../types/filterTable.types';
import type { Transaction } from '../../../../../types/statutory.types';
import type {
  GroupOption, GroupSettings
} from 'components/elements/tableWrapper/types/table.types';
import type {
  AssignFunction,
  UnassignFunction,
} from '../../../../elements/dimensionLeftPanel/labelingTypes';
import SearchBar from '../../../../elements/searchBar/SearchBar';
import DimensionsView
  from '../../../../elements/dimensionLeftPanel/listView/dimensionsView/DimensionsView';
import { useFinancialTable } from '../../../../../context/FinancialTableContext';
import DetailedViewFooter from '../../../../elements/dimensionLeftPanel/footer/DetailedViewFooter';
import LeftPanelHeader from
  'components/panels/leftPanelHeader/LeftPanelHeader';
import { SearchableContextProvider } from 'context/SearchableContext';
import ColumnSelection from 'components/panels/columnSelection/ColumnSelection';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './Panel.module.scss';
import SortSelection,
{ convertColumnsStateToSortForm } from 'components/panels/sortSelection/SortSelection';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { useTableContext } from 'context/TableContext';

type DimensionPanelProps = {
  availableTabs: LeftPanelType[];
  mode: 'label' | 'filter' | 'sort' | 'group' | 'columns';
  gridRef: RefObject<AgGridReact>;
  stagingFilter: FilterList;
  setStagingFilter: (filter: FilterList) => void;
  filter: FilterList;
  setFilter: (filter: FilterList) => void;
  unassignLabels: UnassignFunction;
  assignLabels: AssignFunction;
  groupOptions: GroupOption[];
  isGridReady: boolean;
  sectionKey?: string;
  tableKey?: string;
  dimensionsPanelConfig?: {
    hideLazyLabelSetting?: boolean;
  };
};

const DetailedViewLeftPanel = ({
  availableTabs,
  onPanelChange,
  activePanelKey,
  mode,
  gridRef,
  stagingFilter,
  setStagingFilter,
  filter,
  setFilter,
  unassignLabels,
  assignLabels,
  groupOptions,
  isGridReady,
  sectionKey,
  tableKey,
  dimensionsPanelConfig
}: TablePanelProps<Transaction> & DimensionPanelProps) => {
  const { state: { templateId } } = useFinancialTable();

  const { sortState, setSortState, defaultSorting } = useTableContext();

  useEffect(() => {
    setSortState(defaultSorting);
  }, [ sectionKey ]);

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  return (
    <SearchableContextProvider>
      <Card className={ clsx(panelStyles.card, styles.card) }>
        <LeftPanelHeader
          availableTabs={ availableTabs }
          activeKey={ activePanelKey as LeftPanelType }
          onChange={ (key: PanelType) => onPanelChange(key) }
          onClose={ () => onPanelChange(null) }
          syncWithCommandBar={ false }
          utilsToShow={ [ 'filter', 'columns', 'label', 'group', 'sort' ] }
        />
        {
          [ 'filter', 'label', 'columns' ].includes(mode) && (
            <SearchBar
              className={ panelStyles.searchBox }
              showSeparator={ false }
              showResults={ false }
            />
          )
        }

        { 
          (mode === 'sort') && (
            <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
              <SortSelection
                isDisabled={ availableTabs && !availableTabs.includes('sort') }
                gridRef={ gridRef }
                sortState={ convertColumnsStateToSortForm(sortState) }
                defaults={ convertColumnsStateToSortForm(defaultSorting) }
              />
            </div>
          )               
        }

        { 
          (mode === 'group') && (
            <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
              <GroupSelection
                onSelect={ onGroupSelect }
                isDisabled={ availableTabs && !availableTabs.includes('group') }
                gridRef={ gridRef }
                options={ groupOptions }
              />
            </div>
          )               
        }
        
        {
          (mode === 'filter' || mode === 'label') && (
            <div className={ clsx(panelStyles.panelBody) }>
              <DimensionsView
                templateId={ templateId }
                stagingFilter={ stagingFilter }
                setStagingFilter={ setStagingFilter }
                classContainer={ styles.dimensionsList }
                mode={ mode }
                config={ dimensionsPanelConfig }
                gridRef={ gridRef }
                defaultUnassignNodesCallback={ unassignLabels }
                assignLabels={ assignLabels }
              />

              { mode === 'filter' && (
                <DetailedViewFooter
                  filter={ filter }
                  stagingFilter={ stagingFilter }
                  setFilters={ (_filter) => setFilter(_filter) }
                />
              ) }
            </div>
          )
        }

        { mode === 'columns' && (
          <div className={ clsx(panelStyles.panelBody) }>
            <ColumnSelection
              isGridReady={ isGridReady }
              gridRef={ gridRef }
              sectionKey={ sectionKey }
              tableKey={ tableKey }
            />
          </div>
        ) }
        
      </Card>
    </SearchableContextProvider>
  );
};

export default DetailedViewLeftPanel;
