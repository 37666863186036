import React from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import InputGroupWithIcon from './InputGroupWithIcon';
import DatePickerCellEditor from './DatePickerCellEditor';
import { TableColDef } from 'components/elements/tableWrapper/types/table.types';

import styles from './Editors.module.scss';
import { fieldToIconMap } from '../budgetOverview.utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface FormConfig {
  disabled?: boolean;
  message?: string;
  messageType?: 'warning' | 'error' | 'info';
}

interface EditorProps {
  field: TableColDef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  formConfig: Record<string, FormConfig>;
  placeholder?: string;
  errors: FieldErrors;
  multipleSelected?: boolean;
}

const StartEndDateEditor = ({
  field,
  control,
  formConfig,
  placeholder,
  errors,
  multipleSelected,
}: EditorProps) => {
  const [ t ] = useTranslation('financials');

  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });

  return <div className={ styles.doubleFiledEditor }>
    <Controller
      name='startDate'
      control={ control }
      rules={ {
        required: multipleSelected ? false : t('inline-edit.validation.startDateRequired'),
        validate: (value) => {
          if (value && endDate && dayjs(value)?.isAfter(dayjs(endDate))) {
            return t('inline-edit.validation.startDateBeforeEndDate');
          } 
          return true;
        },
        deps: [ endDate ]
        
      } }
      render={ ({ field: { value, onChange } }) =>
        <InputGroupWithIcon
          disabled={ formConfig[ 'startDate' ]?.disabled }
          hasValue={ value }
          prefixIcon={ fieldToIconMap[ 'startDate' ] }
        >
          <DatePickerCellEditor
            value={ value }
            disabled={ formConfig[ 'startDate' ]?.disabled }
            setValue={ onChange }
            placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
            errors={ errors }
          />
        </InputGroupWithIcon>
      }
    />
    <Controller
      name='endDate'
      control={ control }
      rules={ {
        validate: (value) => {
          if (value && startDate && dayjs(value)?.isBefore?.(dayjs(startDate))) {
            return t('inline-edit.validation.endDateAfterStartDate');
          }
          return true;
        },
        deps: [ startDate ]
      } }
      render={ ({ field: { value, onChange } }) =>
        <InputGroupWithIcon
          disabled={ formConfig[ 'endDate' ]?.disabled }
          hasValue={ value }
          prefixIcon={ fieldToIconMap[ 'endDate' ] }
        >
          <DatePickerCellEditor
            value={ value }
            disabled={ formConfig[ 'endDate' ]?.disabled }
            setValue={ onChange }
            placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
            errors={ errors }
          />
        </InputGroupWithIcon>
      }
    />
  </div>;
};

export default StartEndDateEditor;
