import { AgGridReact } from 'ag-grid-react';
import FinancialFooter from 'components/elements/dimensionLeftPanel/footer/FinancialFooter';
import {
  AssignFunction, UnassignFunction
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import DimensionsView 
  from 'components/elements/dimensionLeftPanel/listView/dimensionsView/DimensionsView';
import SearchBar from 'components/elements/searchBar/SearchBar';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useFinancialTable } from 'context/FinancialTableContext';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useClearFiltersMutation, useSetFiltersMutation } from 'store/api/reports.api';
import { selectPeriod, clearFilters, toggleFilteredElements } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { FilterList } from 'types/filterTable.types';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from '../FinancialsLeftPanel.module.scss';

interface Props {
  panelType: 'filter' | 'label';
  gridRef: MutableRefObject<AgGridReact>;
  assignLabels: AssignFunction;
  unassignLabels: UnassignFunction;
}

const FiltersSection = ({ gridRef, panelType, assignLabels, unassignLabels }: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();

  const filter = useAppSelector(state => state.financials.tables[ templateId ]?.filter);
  const [ stagingFilter, setStagingFilter ] = useState<FilterList>();
  const [ clearFiltersMutation, clearFiltersResult ] = useClearFiltersMutation();
  const [ setFilters, setFiltersResult ] = useSetFiltersMutation();
  const period = useAppSelector(selectPeriod(templateId));
  const { refetch } = useReportQuery({ templateId, period });

  useEffect(() => {
    if (filter) {
      setStagingFilter(filter);
    }
  }, [ filter ]);

  useEffect(() => {
    if (filter) {
      setStagingFilter(filter);
    }
  }, [ filter ]);

  const onClearFilters = async () => {
    await clearFiltersMutation({ id: templateId });
    dispatch(clearFilters(templateId));
    refetch();
  };

  const onApplyFilters = async () => {
    await setFilters({ id: templateId, data: stagingFilter });
    dispatch(toggleFilteredElements(stagingFilter));
    refetch();
  };

  return (
    <>
      <SearchBar
        className={ panelStyles.searchBox }
        showSeparator={ false }
        showResults={ false }
      />

      <DimensionsView
        templateId={ templateId }
        stagingFilter={ stagingFilter }
        setStagingFilter={ setStagingFilter }
        classContainer={ styles.dimensionsList }
        mode={ panelType }
        gridRef={ gridRef }
        defaultUnassignNodesCallback={ unassignLabels }
        assignLabels={ assignLabels }
      />

      {
        panelType === 'filter' && (
          <FinancialFooter
            onClearFilters={ onClearFilters }
            onApplyFilters={ onApplyFilters }
            clearLoading={ clearFiltersResult.isLoading }
            applyLoading={ clearFiltersResult.isLoading || setFiltersResult.isLoading }
            filters={ filter }
            stagingFilter={ stagingFilter }
          />
        )
      }
    </>
  );
};

export default FiltersSection;