import {
  CellClassParams,
  CellClickedEvent,
  CheckboxSelectionCallbackParams,
  ColDef,
  IRowNode,
  RowClassParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import React, { useCallback, useMemo, useState } from 'react';
import { ImportedStyles } from 'types/app.types';
import {
  AG_ROOT_NODE_ID,
  AUTO_COLUMN_WIDTH_LS_KEY,
  isProductProxy,
  roundTransactionAmount,
  separateThousands,
  shouldMarkAsLazyLabeled
} from 'utils/financials.utils';
import ChevronRight from 'assets/icons/chevron-right.svg';
import { getFinancialCellClasses, getFinancialRowClasses } from '../rowStyles';
import { DynamicColumnType, RowType } from 'types/financials.types';
import { AgGridReact } from 'ag-grid-react';
import { getComparator, getRootSortSettings, NodeSortingSetting } from 'utils/sorting.utils';
import ExpandButtons from 'components/financials/expandButtons/ExpandButtons';
import { FinancialTableState } from 'store/financials.slice';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import dayjs from 'dayjs';
import { filterNumbers, getDisplayName, trimCadenceSuffix } from 'utils/common.utils';
import { isFormulaNode, ReportType } from 'types/templates.types';
import { NO_DATA_ROWS } from 'utils/grid.utils';
import CustomGroupCellRenderer from '../common/CustomGroupCellRenderer';
import { isNewCellHasValue } from 'components/financials/utils/addRow.utils';
import { isEmpty } from 'lodash';
import { useLocalStorage } from 'hooks/useLocalStorage';

type Props = {
  styles: ImportedStyles;
  templateId: number;
  gridRef: React.RefObject<AgGridReact>;
  maxRowDepth: number;
  maxRowIndentation: number;
  dragOverNodeId: number;
  grayedOutNodeIds?: string[];
};

const useGridStyles = (
  {
    styles,
    templateId,
    gridRef,
    maxRowDepth,
    maxRowIndentation,
    dragOverNodeId,
    grayedOutNodeIds = [],
  }: Props) => {
  const table: FinancialTableState = useAppSelector(state =>
    state.financials.tables[ templateId ]) || null;

  const [ t ] = useTranslation('financials');
  const dynamicColumns = Object.values(DynamicColumnType);
  const [ colWidth, setColWidth ] = useState(80);
  const [ autoColumnWidth ] = useLocalStorage(AUTO_COLUMN_WIDTH_LS_KEY(templateId), 375);

  const formulaValueFormatter = useCallback((params: ValueFormatterParams) => {
    let value = params.value;
    const { formatting, decimals, calculateDynamicTotals } = params.node.data.rowData;
    value = parseFloat(value);
    if (isNaN(value)) {
      return '';
    }

    if (formatting === 'PERCENTAGE') {
      value = value * 100;
    }
    if (decimals && decimals > 0) {
      value = value.toFixed(decimals);
    } else {
      value = Math.round(value);
    }
    if (formatting === 'PERCENTAGE') {
      value += '%';
    }
    if (Math.floor(value) === 0 && !(decimals && decimals > 0)) {
      return '0';
    }
    if (calculateDynamicTotals && !value) {
      return ' ';
    }
    return value ? separateThousands(value) : '0';
  }, []);

  const dynamicColumnValueFormatter = useCallback((
    params: ValueFormatterParams,
    colType: DynamicColumnType
  ) => {
    let value = params.value;
    if (colType.includes('%')) {
      value = value * 100;
      value = Math.round(value * 10) / 10;
      if (isNaN(value)) {
        return '';
      }
      return `${ value.toFixed(1) }%`;
    }
    if (isFormulaNode(params.node?.data)) {
      return formulaValueFormatter(params);
    }
    if (Math.floor(value) === 0) {
      return '';
    }
    return value ? separateThousands(Math.round(value)) : null;
  }, []);

  const getValueFormatter = useCallback((params: ValueFormatterParams): string | null => {
    /**
     * Returning null causes displaying original value without any formatting.
     */
    if (NO_DATA_ROWS.includes(params.node?.data?.type)) {
      return ' ';
    }
    const value = params.value;
    if (params.node.data?.type === RowType.BREAKDOWN && !table.state.dimensionNumbers) {
      if (params.node.level !== 0) {
        return ' ';
      }
    }
    let colType: DynamicColumnType = null;
    colType = filterNumbers(params.column.getColId()) as DynamicColumnType;
    if (!dynamicColumns.includes(colType)) {
      colType = trimCadenceSuffix(colType) as DynamicColumnType;
    }

    if (dynamicColumns.includes(colType)) {
      return dynamicColumnValueFormatter(params, colType);
    }

    if (isFormulaNode(params.node?.data)) {
      return formulaValueFormatter(params);
    }

    if (params.node.data?.type === RowType.NEW_BUDGET_ITEM) {
      if (value == null) return '';

      return isNaN(((value ?? '').toString()).replaceAll(/\s/g, '')) ?
        params.value :
        formulaValueFormatter(params);
    }

    return value ? separateThousands(Math.round(value)) : null;
  }, [ table.state, formulaValueFormatter, dynamicColumnValueFormatter ]);

  const defaultColDef = useMemo(() => {
    return {
      type: 'rightAligned',
      width: colWidth,
      resizable: true,
      cellClass: (params) => {
        const classes = new Set([ 'cell' ]);

        const isPlan = params.colDef?.field.includes(ReportType.PLAN);
        const isFirstPlanColumn = isPlan && params.colDef.isFirst;
        const isFirstRow = params.node.parent?.id === AG_ROOT_NODE_ID &&
          params.node.firstChild && params.colDef?.field;
        const isDynamicColumn = params.colDef?.headerComponentParams?.isDynamicColumn;

        if (
          params.node.parent?.id === AG_ROOT_NODE_ID &&
          isDynamicColumn &&
          params.node.firstChild
        ) {
          classes.add('dynamicColumn');
        } else if (params.colDef?.field && isDynamicColumn) {
          classes.add('dynamicColumn');
        } else if (
          (isFirstRow && dayjs(params.colDef?.field).isValid() &&
            !dayjs(params.colDef?.field).isAfter(dayjs.unix(table.period.endDate)) ||
            isDynamicColumn)
        ) {
          // No additional class needed, 'cell' is already added
        } else if (isFirstPlanColumn && table.period.startDate && table.period.startDatePlan) {
          classes.add('firstPlanColumnCell');
          if (isNewCellHasValue(params.node.data, params.colDef)) {
            classes.add('newCellWithValue');
          }
        } else if (isNewCellHasValue(params.node.data, params.colDef)) {
          classes.add('newCellWithValue');
        }

        if (params.node.data?.type === RowType.NEW_BUDGET_ITEM) {
          classes.add('newBudgetItem');
        }

        if (shouldMarkAsLazyLabeled(params.node, grayedOutNodeIds)) {
          classes.add('grayedOut');
        }

        return Array.from(classes).map(className => styles[ className ]);
      },
      valueFormatter: getValueFormatter,
      cellClassRules: {
        valueCell: (params: CellClassParams) => {
          return params.colDef.colId !== 'ag-Grid-AutoColumn';
        },
      },
    };
  }, [
    styles,
    table.state.dimensionNumbers,
    colWidth,
    table.sorting,
    getValueFormatter,
    table.period,
    grayedOutNodeIds,
  ]);

  const columnTypes = useMemo(() => {
    return {
      valueColumn: {
        valueParser: (props) => {
          const number = Math.round(props.newValue);
          return Number.isInteger(number) ? number : null;
        }
      },
    };
  }, []);

  const icons = useMemo(() => {
    return {
      groupContracted: `<img src='${ ChevronRight }'  alt='Arrow'/>`,
      rowDrag: '<div />'
    };
  }, []);

  const getRowClass = useCallback((params: RowClassParams) => {
    const stylesForType = {
      [ RowType.SUM_UP ]: styles.sumUpRow,
      [ RowType.TITLE ]: styles.row,
      [ RowType.BREAKDOWN ]: styles.breakdownRow,
    };

    const isNewRow = params.node.data?.type === RowType.NEW_BUDGET_ITEM;

    const isFailedRow = params.node.data?.isFailed;

    const rowClasses = getFinancialRowClasses(params.node);
    return [
      ...rowClasses,
      ...(isNewRow ? [ styles.newRow ] : []),
      ...(isFailedRow ? [ styles.failedRow ] : []),
      stylesForType[ params.node.data?.type ] || styles.row
    ];
  }, []);

  const selectRowCells = useCallback((e: CellClickedEvent) => {

    if (hasButtonParent(e.eventPath)) {
      return;
    }

    if (!e.eventPath.some((el: Element) => 
      el?.classList?.contains('action:selectRow') ||
      el?.classList?.contains('ag-selection-checkbox'))) {
      return;
    }
    
    e.api.clearRangeSelection();
    e.api.clearFocusedCell();

    if ((e.event as PointerEvent).ctrlKey || (e.event as PointerEvent).metaKey) {
      e.node.setSelected(!e.node.isSelected());
    } else if ((e.event as PointerEvent).shiftKey) {
      const selectedNodes = e.api.getSelectedNodes();
      if (selectedNodes.length) {
        const lastSelectedIndex = selectedNodes
          .filter(node => node.rowIndex != null)?.at(-1)?.rowIndex;

        if (lastSelectedIndex == null) {
          e.node.setSelected(!e.node.isSelected());
          return;
        }

        const clickedIndex = e.node.rowIndex;

        const start = Math.min(lastSelectedIndex, clickedIndex);
        const end = Math.max(lastSelectedIndex, clickedIndex);

        for (let i = start; i <= end; i++) {
          const node = e.api.getDisplayedRowAtIndex(i);
          node.setSelected(true);
        }
      }
    } else {
      if (e.node.isSelected()) {
        e.node.setSelected(false);
      } else {
        e.api.getSelectedNodes().forEach(node => {
          node.setSelected(false);
        });
        e.node.setSelected(!e.node.isSelected());
      }
    }
  }, []);

  const getSortingSettings = useCallback((node: IRowNode) => {
    const sortingSettings: NodeSortingSetting = table.sorting[ node.parent.id ];
    if (sortingSettings && !isEmpty(sortingSettings)) return sortingSettings;

    if (node.parent?.id === AG_ROOT_NODE_ID) {
      return {};
    }

    return getRootSortSettings(node, table.sorting);
  }, [ table.sorting ]);

  const getHeaderCellClass = useCallback((params: CellClassParams) => {
    const node = params.node;
    let parent = node;
    let depth = 0;
    while (parent) {
      if (parent.data?.type !== RowType.BREAKDOWN && parent.parent) {
        depth++;
      }
      parent = parent.parent;
    }
    const levelClasses = getFinancialCellClasses(params, depth, maxRowIndentation);
    return [ ...levelClasses, styles.rowTitle ];
  }, [ styles, maxRowIndentation ]);

  const autoGroupColumnDef = useMemo((): ColDef => {
    return {
      pinned: true,
      minWidth: 214,
      width: autoColumnWidth,
      maxWidth: 600,
      flex: 1,
      resizable: true,
      sort: 'asc',
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        return !NO_DATA_ROWS.includes(params.node.data?.type);
      },
      editable: (params) => {
        return params?.data?.type === RowType.NEW_BUDGET_ITEM;
      },
      filterValueGetter: (props) => props.node,
      comparator: (valueA, valueB, nodeA, nodeB) => {  
        if (
          nodeB?.data?.type === RowType.NEW_BUDGET_ITEM &&
          nodeA?.data?.type === RowType.NEW_BUDGET_ITEM
        ) {
          const timeStampA = nodeA.id.split('_').at(-1);
          const timeStampB = nodeB.id.split('_').at(-1);
          return Number(timeStampB) - Number(timeStampA);
        }

        if (nodeA?.data?.type === RowType.NEW_BUDGET_ITEM) {
          return -1;
        }

        const sortingSettings = getSortingSettings(nodeA);

        if (sortingSettings && !isEmpty(sortingSettings)) {
          const primarySettings = sortingSettings.primary;
          const secondarySettings = sortingSettings.secondary;
          const secondaryComparator = getComparator(secondarySettings);
          const comparator = getComparator(primarySettings, secondaryComparator);
          return comparator(nodeA, nodeB);
        }
        return 0;
      },
      headerComponent: () =>
        <ExpandButtons
          gridRef={ gridRef }
          maxRowDepth={ maxRowDepth }
          templateId={ templateId }
        />,
      headerClass: styles.expandButtonsCell,
      autoHeight: true,
      cellRendererParams: {
        suppressCount: true,
        suppressEnterExpand: true,
        suppressCellFocus: true,
        suppressDoubleClickExpand: true,
      },
      suppressNavigable: true,
      rowDragText: (params) => {
        // ! HACK: Fixes issue with drag PP ignores selection
        if (isProductProxy(params.rowNode) && params.rowNode.isSelected()) {
          const selection = gridRef.current.api.getSelectedNodes();
          return `${ selection.length } ${ t('table.drag.plural') }`;
        }

        const isPlural = params.rowNodes.length > 1;
        if (!isPlural) {
          return getDisplayName(params.defaultTextValue);
        }
        return `${ params.rowNodes.length } ${ isPlural ?
          t('table.drag.plural') : t('table.drag.singular') }`;
      },
      valueGetter: (params) => {
        if (params.data?.type === RowType.NEW_BUDGET_ITEM) {
          return params.data?.rowData?.memo;
        }

        if (isProductProxy(params.node)) {
          return params.node.groupData[ params.colDef.colId ];
        }

        return params.data?.rowData?.name;
      },
      valueSetter: (params) => {
        if (params.data?.type === RowType.NEW_BUDGET_ITEM) {
          params.data.rowData.memo = params.newValue;
          return true;
        }
        return false;
      },
      onCellClicked: selectRowCells,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.event.code === 'Delete' || params.event.code === 'Backspace') {
          return true;
        }

        if (params.event.code === 'Enter' && !params.editing) {
          return true;
        }
  
        return false;
      },
    };
  }, [ styles,
    templateId,
    gridRef,
    maxRowDepth,
    maxRowIndentation,
    getSortingSettings,
    table.state,
    dragOverNodeId,
    autoColumnWidth
  ]);

  const autoGroupColumnDefWithDragHandler = useMemo((): ColDef => {
    return {
      ...autoGroupColumnDef,
      cellRenderer: (props) => <CustomGroupCellRenderer
        { ...props }
        templateId={ templateId }
        gridRef={ gridRef }
        grayedOutNodeIds={ grayedOutNodeIds }
      />,
      cellClass: (params) => {
        const classes = getHeaderCellClass(params);
        if (params.node.id === dragOverNodeId?.toString()) {
          classes.push(styles.dragOver);
        }
        if (shouldMarkAsLazyLabeled(params.node, grayedOutNodeIds)) {
          classes.push(styles.grayedOut);
        }
        return classes;
      }
    };
  }, [ dragOverNodeId, autoGroupColumnDef, grayedOutNodeIds ]);

  const resizeColumns = useCallback(() => {
    let longestTotal = 0;
    gridRef?.current?.api?.forEachNode(node => {
      const total = roundTransactionAmount(node.data?.total);
      const totalLength = total.toString().length;
      if (totalLength > longestTotal) {
        longestTotal = totalLength;
      }
    });
    setColWidth(80 + longestTotal * 4);
  }, [ gridRef ]);

  return {
    defaultColDef,
    columnTypes,
    icons,
    autoGroupColumnDef: autoGroupColumnDefWithDragHandler,
    getRowClass,
    resizeColumns
  };
};

export default useGridStyles;

function hasButtonParent(targets: EventTarget[]) {
  return targets.some((target) => {
    if (target instanceof HTMLElement) {
      return target.nodeName === 'BUTTON';
    }
  });
}
