import React from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { InvoicingFrequency } from 'types/contracts.types';
import OptionPopover from '../optionPopover/OptionPopover';

import { ReactComponent as RecurringIcon } from 'assets/icons/recurring.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import styles from '../BudgetDropdown.module.scss';
import BudgetOption from '../budgetSelectedOption/BudgetSelectedOption';
import { getBudgetingOptionKey } from 'components/financials/utils/addRow.utils';
import clsx from 'clsx';

const FrequencyOptions = () => {
  const [ t ] = useTranslation();

  const templateId = useAppSelector(state => state.financials.active.templateId);
  const frequencyKey = getBudgetingOptionKey('frequency', templateId);

  const [ frequency, setFrequency ] = useLocalStorage(frequencyKey, InvoicingFrequency.ONE_TIME);

  const frequencyFormatter = (value: InvoicingFrequency) => {
    if (!value) return '';

    return t(`contracts:frequency.${ value }`);
  };

  return <OptionPopover
    content={ <>
      <div className={ styles.frequencyOptionList }>
        {
          Object.values(InvoicingFrequency)
            .map((item) => {
              return <div
                key={ item }
                className={ clsx(
                  styles.optionItem,
                  { [ styles.selectedOption ]: frequency === item }
                ) }
                onClick={ () => setFrequency(item) }
              >
                { frequencyFormatter(item) }
                { frequency === item ? <CheckIcon className={ styles.selectedIcon } /> : null }
              </div>;
            })
        }

      </div>
    </> }
  >
    <BudgetOption
      name={ t('financials:inline-edit.fields.frequency') }
      icon={ <RecurringIcon /> }
      value={ frequencyFormatter(frequency) }
    />
  </OptionPopover>;
};

export default FrequencyOptions;