import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/elements/modal/Modal';
import styles from 'components/elements/modals/Modals.module.scss';
import clsx from 'clsx';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  okText?: string;
  className?: string;
  wrapClassName?: string;
  enforceButtons?: boolean;
  closeText?: string;
}

const WarningModal = ({
  isVisible,
  onClose,
  onConfirm,
  title,
  okText,
  description,
  className,
  wrapClassName,
  enforceButtons,
  closeText,
}: Props) => {
  const [ t ] = useTranslation('common');

  const textForClose = closeText ?? t('form.cancel');

  return <Modal
    className={ clsx(styles.modalWarn, className) }
    wrapClassName={ clsx(wrapClassName) }
    title={
      <div className={ styles.modalTitle }>
        { title }
      </div>
    }
    maskClosable={ !enforceButtons }
    okButtonProps={ { danger: true } }
    description={ description }
    isVisible={ isVisible }
    closeText={ textForClose }
    okText={ okText ?? t('form.confirm') }
    onClose={ onClose }
    onConfirm={ onConfirm }
  />;
};

export default WarningModal;
