import { Popover } from 'antd';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

import styles from '../BudgetDropdown.module.scss';

interface OptionPopoverProps {
  content: React.ReactNode;
  contentClassName?: string;
}

const OptionPopover = ({
  children,
  contentClassName,
  content
}: PropsWithChildren<OptionPopoverProps>) => {
  return <Popover
    trigger={ [ 'hover' ] }
    placement='rightTop'
    showArrow={ false }
    getPopupContainer={ target => target }
    overlayClassName={ styles.popover }
    content={ <div className={ clsx(styles.pickerContainer, contentClassName) }>
      { content }
    </div> }
  >
    { children }
  </Popover>;
};

export default OptionPopover;
