import React from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetItem } from 'types/budget.types';
import { Transaction } from 'types/statutory.types';
import { numberFormatter } from 'utils/common.utils';
import { getLabel, isTransaction } from '../split.utils';
import styles from './SplitItemLabel.module.scss';

const SplitItemLabel = ({ splitItem }: { splitItem: Transaction | BudgetItem }) => {
  const [ t ] = useTranslation('financials');
  const amount = isTransaction(splitItem) ? splitItem.amount : splitItem.amountFormula;

  return <div className={ styles.tlsOption }>
    <div className={ styles.splitItemTypeBadge }>
      {
        isTransaction(splitItem) && !splitItem.budgetItem ? 
          t('actual-label') :
          t('budget-label')
      }
    </div>
    <span className={ styles.description }>
      { getLabel(splitItem) }
    </span>
    <span className={ styles.sum }>
      {
        isNaN(Number(amount)) ? amount : numberFormatter(Number(amount), true) }
    </span>
  </div>;
};

export default SplitItemLabel;
