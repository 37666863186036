import { ReportData, TemplateNode } from './templates.types';
import { Product } from './revenueRecognition.types';
import { ReportSource } from './financials.types';
import { InvoicingFrequency } from './contracts.types';
import { IRowNode } from 'ag-grid-community';

export type StatutoryTemplate = {
  id: number;
  name: string;
  roots: number[];
  nodes: TemplateNode[];
};

export type AccountRequest = {
  account: number;
  templateRow: number;
  order?: number;
};

export type Account = {
  id: number;
  name: string;
  number: number;
};

export type Association = {
  templateRow: number;
  account: Account;
  detected: boolean;
  order: number;
};

export enum DnDStatutoryTypes {
  unassigned = 'unassigned',
  assigned = 'assigned'
}

export type RecognizedLine = {
  transactionDate: string;
  amount: number;
};

export enum TransactionStatus {
  EMPTY = 'empty',
  UNFINISHED = 'unfinished',
  NOT_SENT = 'not_sent',
  SENT = 'sent',
  RECEIVED = 'received',
  PAID = 'paid',
  PAYMENT_DENIED = 'payment_denied',
  VERIFIED = 'verified',
  APPROVED = 'approved',
  INVALIDATED = 'invalidated',
  PAYMENT_QUEUED = 'payment_queued',
  PARTLY_PAID = 'partly_paid',
  PAYMENT_SENT_TO_BANK = 'payment_sent_to_bank',
  MARKED_PAID = 'marked_paid',
  STARTED = 'started',
  INVOICED = 'invoiced',
  OVERRIDDEN = 'overridden',
  DELETED = 'deleted',
  UNSAVED = 'unsaved',
  PAYMENT_TRANSACTION_REMOVED = 'payment_transaction_removed',
  OTHER = 'other',
}

export type DimensionItemWithSplit = {
  dimensionItem: number;
  dimension: number;
  source: 'USER' | 'SYSTEM';
  value: number;
  percentage: string;
};

export type DimensionItemRelation = {
  dimensionItems: DimensionItemWithSplit[];
  dimensionItemIds: number[];
};

export type Transaction = {
  account: number;
  amount: number;
  memo: string;
  data: ReportData;
  tax: number;
  transaction: number;
  id: number;
  counterparty: Counterparty;
  transactionDate: string;
  transactionSource: ReportSource;
  transactionStatus: TransactionStatus;
  invoiceLink: string;
  product: Product;
  productProxy: string | null;
  recognizedLines?: RecognizedLine[];
  type: string;
  taxRate: number;
  vatDeductionPercentage: number;
  startDate: string;
  endDate: string;
  frequency: InvoicingFrequency;
  invoicingDate: string;
  budgetItem: number | null;
} & DimensionItemRelation;

export const isTransactionNode = (node: IRowNode): node is IRowNode<Transaction> => {
  return node?.data?.transaction !== undefined;
};

export type Counterparty = {
  id: number;
  type: CounterpartyType;
  source: CounterpartySource;
  name: string;
  pictureUrl: string;
};

enum CounterpartyType {
  EMPLOYEE = 'EMPLOYEE',
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}

export enum CounterpartySource {
  PROCOUNTOR = 'PROCOUNTOR',
  CALEIDO = 'CALEIDO',
  USER = 'USER',
}

// t(`settings:account-roles.names.ADVANCES_RECEIVED`)
// t(`settings:account-roles.names.CASH_IN_BANK`)
// t(`settings:account-roles.names.COST_ACCRUAL`)
// t(`settings:account-roles.names.PAYROLL_TAX`)
// t(`settings:account-roles.names.PROFIT_AND_LOSS`)
// t(`settings:account-roles.names.RETAINED_EARNINGS`)
// t(`settings:account-roles.names.TAX_ACCOUNT`)
// t(`settings:account-roles.names.VAT_LIABILITY`)
// t(`settings:account-roles.names.TRADE_RECEIVABLES`)
// t(`settings:account-roles.names.TRADE_PAYABLES`)
export type RequiredRole =
 'ADVANCES_RECEIVED' |
 'CASH_IN_BANK' |
 'COST_ACCRUAL' |
 'PAYROLL_TAX' |
 'PROFIT_AND_LOSS' |
 'RETAINED_EARNINGS' |
 'TAX_ACCOUNT' |
 'VAT_LIABILITY' |
 'TRADE_RECEIVABLES' |
 'TRADE_PAYABLES';

export type RequiredRoleAccount = {
  [k in RequiredRole]: number;
};
