import React from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { Entry, getEntryLabel } from './budgetOverview.utils';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';

import styles from './BudgetItemPopover.module.scss';

interface Props {
  entriesList: Entry[];
  selectedEntry: string;
  onEntrySelect: (key: string) => void;
}

const SelectEntries = ({ entriesList, selectedEntry, onEntrySelect }: Props) => {
  const { t } = useTranslation('financials');

  const items = entriesList.map(el => ({
    key: el.key,
    label: <div onClick={ () => onEntrySelect(el.key) } className={ styles.navigationItem }>
      { el.key == null ? 'Multiple' : getEntryLabel(el) }
    </div>
  }));

  return (
    <div className={ styles.selectContainer }>
      <p>{ t('inline-edit.selectedBudgetItem') }</p>
      <Dropdown
        className={ styles.tlsDropdown }
        disabled={ entriesList.length < 2 }
        overlayClassName={ styles.tlsDropdownOverlay }
        getPopupContainer={ (trigger) => trigger }
        menu={ { items } }>
        <div className={ styles.selectedTLsLabel }>
          <span className={ styles.description }>
            <div className={ styles.label }>B</div>
            {
              selectedEntry == null ?
                t('common:form.multiple') :
                getEntryLabel(entriesList.find(e => e.key === selectedEntry))
            }
          </span>
          {
            entriesList.length > 1 ?
              <ChevronDownIcon className={ styles.arrow } /> :
              <span></span>
          }
        </div>
      </Dropdown>

    </div>
  );
};

export default SelectEntries;
