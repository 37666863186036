import React, { useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellPosition } from 'ag-grid-community';
import { isProductProxy } from 'utils/financials.utils';

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  triggerCondition: boolean;
}

const useGridFocusEffect = ({ gridRef, triggerCondition }: Props) => {

  const focusOnCell = useCallback((cell: CellPosition) => {
    gridRef?.current?.api?.ensureColumnVisible(cell.column);
    gridRef?.current?.api?.ensureIndexVisible(cell.rowIndex);
    gridRef?.current?.api?.setFocusedCell(cell.rowIndex, cell.column);
  }, []);

  useEffect(() => {
    if (triggerCondition) {
      const focusedCell = gridRef?.current?.api?.getFocusedCell();
      if (focusedCell) {
        focusOnCell(focusedCell);
      }
    }
  }, [ triggerCondition ]);

  const getFirstDataNodeIndex = useCallback((): number => {
    const rowsAmount = gridRef?.current?.api?.getDisplayedRowCount();
    if (rowsAmount > 0) {
      for (let i = 0; i < rowsAmount; i++) {
        const rowNode = gridRef?.current?.api?.getDisplayedRowAtIndex(i);
        if (!isProductProxy(rowNode)) {
          return i;
        }
      }
    }
    return 0;
  }, []);

  const focusOnFirstCell = useCallback((rowIndex?: number) => {
    const allColumns = gridRef?.current?.columnApi?.getAllDisplayedColumns();
    if (!allColumns) {
      return;
    }
    const columns = allColumns.filter(c => {
      return !c.getColDef().suppressNavigable;
    });

    if (columns?.length) {
      focusOnCell({
        rowIndex: rowIndex ?? getFirstDataNodeIndex(),
        column: columns[ 0 ],
        rowPinned: null
      });
      gridRef?.current?.api?.ensureColumnVisible(allColumns[ 0 ]);
    }
  }, []);

  return {
    focusOnFirstCell,
    focusOnCell
  };
};

export default useGridFocusEffect;