import { TableProps, TableSection } from '../types/table.types';
import { useMemo } from 'react';

type Props<T> = TableProps<T> & {
  activeSection: TableSection;
};

const useTableSectionProps = <T, >({
  activeSection,
  ...tableProps
}: Props<T>): TableProps<T> => {
  return useMemo(() => {
    return {
      ...tableProps,
      ...activeSection?.tableProps ? activeSection.tableProps : {},
    };
  }, [ tableProps, activeSection, activeSection?.tableProps ]);
};

export default useTableSectionProps;
