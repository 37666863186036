import React from 'react';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import {
  CommandBarDefaultButton
} from 'components/commandBar/types/commandBar.types';
import useKeyPressListener,
{ KeyBindingWithCode, KeyBindingWithKey } from 'hooks/useKeyPressListener';

interface Props {
  button: CommandBarDefaultButton;
}

const CommandBarUtilButton = ({ button }: Props) => {
  return <>
    {
      button.keyBinding != null && !button.disabled && !button.keyBinding.disabled ?
        <ButtonKeyBinding
          { ...button.keyBinding }
          keyBindingNode={ button.keyBinding.keyBindingNode }
          cb={ () => button.onClick && button.onClick() }
        />
        : null
    }
    <CommandBarButton
      button={ button }
      disabled={ button.disabled || !button.onClick }
      onClick={ () => button.onClick && button.onClick() }
    >
      { button.icon }
    </CommandBarButton>
  </>;
};

export default CommandBarUtilButton;

interface ButtonKeyBindingProps {
  primary: KeyBindingWithCode | KeyBindingWithKey;
  alternative?: KeyBindingWithCode | KeyBindingWithKey;
  keyBindingNode?: React.MutableRefObject<HTMLElement | Document>;
  disabled?: boolean;
  cb: () => void;
}

const ButtonKeyBinding = ({ cb, keyBindingNode, primary, alternative }: ButtonKeyBindingProps) => {
  useKeyPressListener({
    keyBinding: primary,
    alternativeKeyBinding: alternative,
    ref: keyBindingNode,
    cb,
  });

  return null;
};
