import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { Period } from 'types/financials.types';

interface Props {
  templateId: number;
  period: Period;
}

const useRefetchOnBudgetGeneration = ({ templateId, period }: Props) => {
  const { budgetGeneration } = useAppSelector((state) => state.budget);
  const { refetch } = useReportQuery({
    templateId,
    period,
    shouldFetch: budgetGeneration.status === 'ended'
  });

  useEffect(() => {
    if (budgetGeneration.status === 'ended') {
      refetch();
    }
  }, [ budgetGeneration.status === 'ended' ]);
  return undefined;
};

export default useRefetchOnBudgetGeneration;
