import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/elements/modal/Modal';
import {
  getTransactionNodesFromProxies,
  getTransactionQuery,
  groupTransactionsByCounterparty,
  isProductProxy,
} from 'utils/financials.utils';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './CostLabelerModal.module.scss';
import { Transaction } from 'types/statutory.types';
import { AxiosResponse } from 'axios';
import { ReportSource, RowType } from 'types/financials.types';
import Loader from '../../elements/loader/Loader';
import labelingService from 'services/labeling.service';
import dayjs, { Dayjs } from 'dayjs';
import useLabeler, { GatheredLabels } from '../../../hooks/useLabeler';
import statutoryService from '../../../services/statutory.service';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from 'store/hooks/hooks';
import PageSwitcher from './pageSwitcher/PageSwitcher';
import { DimensionToLabel, LabelingPage } from 'types/labeling.types';
import LabelPage from './pages/label/LabelPage';
import CreateRulePage from './pages/createRule/CreateRulePage';
import ConfirmPage from './pages/confirm/ConfirmPage';
import { getDisplayName } from 'utils/common.utils';
import { handleError } from 'utils/error.utils';
import organizationsService, { SSRMParams } from '../../../services/organizations.service';
import { EXTERNAL_BREAKDOWN_TYPES } from 'types/filterTable.types';
import InlineStatement from 'components/elements/inlineStatement/InlineStatement';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedRows: IRowNode[];
  useServerSide?: boolean;
  serverSideRequestParams?: SSRMParams;
  excludeTransactionIds?: number[];
  gatheredLabels: GatheredLabels;
  templateId: number;
};

const CostLabelerModal = ({
  isVisible,
  onClose,
  onConfirm,
  selectedRows,
  useServerSide = false,
  serverSideRequestParams,
  excludeTransactionIds,
  gatheredLabels,
  templateId
}: Props) => {
  const [ t ] = useTranslation('labeling');
  const [ transactions, setTransactions ] = useState<Transaction[]>([]);
  const [ transactionsToLabel, setTransactionsToLabel ] = useState<Transaction[]>([]);
  const groupedTransactions = useMemo(
    () => groupTransactionsByCounterparty(transactionsToLabel), [ transactionsToLabel ]
  );
  const { dimensionMap } = useAppSelector(state => state.breakdowns);
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);
  const period = useAppSelector(state => state.financials.tables[ templateId ].period);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ isCommitting, setIsCommitting ] = useState<boolean>(false);
  const [ isLabelingDone, setIsLabelingDone ] = useState<boolean>(false);
  const [ startingDate, setStartingDate ] = useState<Dayjs>(dayjs());
  const [ latestTransactionDate, setLatestTransactionDate ] = useState<Dayjs>(null);
  const [ page, setPage ] = useState<LabelingPage>(LabelingPage.LABEL);
  const labeledDimensions = useMemo(() => {
    if (!gatheredLabels) {
      return [];
    }
    const { dimensionItems, unassignedDimensions } = gatheredLabels;

    return [
      ...dimensionItems?.map(
        dimensionItemId => dimensionMap[ dimensionItemMap[ dimensionItemId ].dimensionId ]
      ) || [],
      ...unassignedDimensions?.map(dimensionId => dimensionMap[ dimensionId ]) || []
    ];
  }, [ dimensionMap, gatheredLabels ]);

  const {
    labelTransactions,
  } = useLabeler(templateId);

  const getLatestTransactionDate = (transactionList: Transaction[]): Dayjs => {
    return dayjs(transactionList.reduce((acc, curr) => {
      return curr.transactionDate > acc ? curr.transactionDate : acc;
    }, ''));
  };

  const setDatesForTransactions = (transactionList: Transaction[]) => {
    const highestDate = getLatestTransactionDate(transactionList);
    setLatestTransactionDate(highestDate);
    setStartingDate(highestDate.add(1, 'day'));
  };

  useEffect(() => {
    setDatesForTransactions(transactions);
  }, [ transactions ]);

  useEffect(() => {
    setLoading(true);
    if (!isVisible) {
      setTransactions([]);
    } else {
      if (useServerSide) {
        const filterModel = {
          ...serverSideRequestParams,
          startRow: 0,
          endRow: 9999,
        };
        const response = organizationsService.getTransactionLinesSSRM(filterModel, null);
        response.then(res => {
          const excludeSet = new Set(excludeTransactionIds);
          setTransactions(res.data.transactionLines.filter(
            transaction => !excludeSet.has(transaction.id)
          ));
          setLoading(false);
        }).finally(() => setLoading(false));
      } else if (selectedRows) {
        const promises: Promise<AxiosResponse<Transaction[]>>[] = [];
        for (const r of selectedRows) {
          if (isProductProxy(r)) {
            const transactionsInProxies = getTransactionNodesFromProxies([ r ]);
            for (const transactionNode of transactionsInProxies) {
              setTransactions(prevState => [ ...prevState, transactionNode.data.rowData ]);
            }
          } else if (r?.data?.transaction) {
            setTransactions(prevState => [ ...prevState, r.data ]);
          } else if (r.data?.type !== RowType.TRANSACTION) {
            const parameters = getTransactionQuery([ r ], templateId, period);
            promises.push(statutoryService.getTransactionLines(parameters[ 0 ], null));
          } else if (r.data?.rowData) {
            setTransactions(prevState => [ ...prevState, r.data.rowData ]);
          }
        }
        Promise.all(promises).then(responses => {
          const allTransactions = [];
          for (const r of responses) {
            allTransactions.push(...r.data);
          }
          setTransactions(prev => [ ...prev, ...allTransactions ]);
        }).finally(() => setLoading(false));
      }
    }
  }, [ isVisible, excludeTransactionIds ]);

  const canTransactionBeLabeled = useCallback((transaction: Transaction) => {
    if (labeledDimensions.some(dimension => EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type))) {
      return transaction.transactionSource === ReportSource.SYSTEM;
    }
    return true;
  }, [ labeledDimensions ]);

  useEffect(() => {
    setTransactionsToLabel(transactions.filter(canTransactionBeLabeled));
  }, [ transactions, labeledDimensions ]);

  const isStartDateValid = useMemo(() => {
    return dayjs(latestTransactionDate).isBefore(startingDate);
  }, [ startingDate, latestTransactionDate ]);

  const removeTransaction = useCallback((id: number) => {
    setTransactions(prevState => prevState.filter(transaction => transaction.id !== id));
  }, []);

  const handleOnClose = useCallback(() => {
    setIsCommitting(false);
    setLoading(false);
    setStartingDate(dayjs());
    setPage(LabelingPage.LABEL);
    if (isLabelingDone) {
      onConfirm();
    }
    setIsLabelingDone(false);
    onClose();
  }, [ onClose, isLabelingDone, onConfirm ]);

  const createLabelingRule = useCallback(async () => {
    const uniqueCounterparties = new Set(
      transactionsToLabel.map(transaction => transaction.counterparty?.id)
    );
    for (const dimensionItem of gatheredLabels.dimensionItems) {
      const counterpartiesArray = Array.from(uniqueCounterparties).filter(c => c);
      const endDate = null;
      const startDate = startingDate.format();
      if (!counterpartiesArray.length) {
        return;
      }
      const requestData = counterpartiesArray.map(counterparty => ({
        counterparty,
        dimensionItem,
        endDate,
        startDate
      }));
      await labelingService.createBulkRule(requestData);
    }
    handleOnClose();
    if (isLabelingDone) {
      onConfirm();
    }
  }, [ transactionsToLabel, startingDate, isLabelingDone ]);

  const handleOnAssign = useCallback(async () => {
    await labelTransactions(transactionsToLabel, gatheredLabels);
    setPage(LabelingPage.CONFIRM);
    setIsLabelingDone(true);
  }, [ transactionsToLabel, gatheredLabels, selectedRows, dimensionItemMap ]);

  const getDimensionsWithItem = useCallback((): DimensionToLabel[] => {
    const dimensionsToLabel = [];
    dimensionsToLabel.push(...gatheredLabels?.dimensionItems?.map(
      dimensionItemId => {
        const dimensionItem = dimensionItemMap[ dimensionItemId ];
        const dimension = dimensionMap[ dimensionItem.dimensionId ];
        return {
          id: dimensionItem.id,
          dimension: getDisplayName(dimension.customName),
          dimensionItem: getDisplayName(dimensionItem.customName)
        };
      }
    ) || []);
    dimensionsToLabel.push(...gatheredLabels?.unassignedDimensions?.map(
      dimensionId => {
        const dimension = dimensionMap[ dimensionId ];
        return {
          id: null,
          dimension: getDisplayName(dimension.customName),
          dimensionItem: t('cost-labeler.unassigned', { ns: 'financials' })
        };
      }
    ) || []);
    return dimensionsToLabel;
  }, [ labeledDimensions, gatheredLabels ]);

  const isRulesCreatorDisabled = useMemo(() => {
    if (!gatheredLabels || gatheredLabels.dimensionItems?.length === 0) {
      return true;
    }
    gatheredLabels?.dimensionItems?.length === 0 ||
      transactionsToLabel.every(transaction => !transaction.counterparty);
  }, [ transactionsToLabel, gatheredLabels ]);

  const content = useMemo(() => {
    const dimensionsContent = getDimensionsWithItem();
    return {
      [ LabelingPage.LABEL ]: <LabelPage
        dimensions={ dimensionsContent }
        counterparties={ groupedTransactions }
        onRemove={ removeTransaction }
      />,
      [ LabelingPage.CREATE_RULE ]: <CreateRulePage
        startDate={ startingDate }
        setStartDate={ (date) => setStartingDate(date) }
        isStartDateValid={ isStartDateValid }
        counterparties={ groupedTransactions }
        dimensions={ dimensionsContent }
        transactionsWithoutCounterparty={
          transactionsToLabel.filter(transaction => !transaction.counterparty).length
        }
      />,
      [ LabelingPage.CONFIRM ]: <ConfirmPage
        transactionAmount={ transactionsToLabel.length }
        dimensions={ dimensionsContent }
        createRule={ !isRulesCreatorDisabled }
      />
    }[ page ];
  }, [ page,
    groupedTransactions,
    removeTransaction,
    transactionsToLabel,
    getDimensionsWithItem,
    startingDate,
    isStartDateValid,
    isRulesCreatorDisabled
  ]);

  const okText = useMemo(() => {
    return {
      [ LabelingPage.LABEL ]: t('form.apply', { ns: 'common' }),
      [ LabelingPage.CREATE_RULE ]: t('modal.create-rule'),
      [ LabelingPage.CONFIRM ]: t('modal.create-rule')
    }[ page ];
  }, [ page ]);

  const okButtonType = useMemo(() => {
    if (page === LabelingPage.CONFIRM) {
      return 'default';
    }
    return 'primary';
  }, [ page ]);

  const onConfirmCallback = useMemo(() => {
    return {
      [ LabelingPage.LABEL ]: () => handleOnAssign(),
      [ LabelingPage.CREATE_RULE ]: () => handleError(async () => await createLabelingRule()),
      [ LabelingPage.CONFIRM ]: () => setPage(LabelingPage.CREATE_RULE),
    }[ page ];
  }, [ page, createLabelingRule, handleOnAssign, handleOnClose ]);

  const confirmDisabled = useMemo(() => {
    if (page === LabelingPage.CREATE_RULE) {
      return !isStartDateValid;
    }
    if (page === LabelingPage.CONFIRM) {
      return isRulesCreatorDisabled;
    }
    return transactionsToLabel.length === 0;
  }, [ page, transactionsToLabel, isStartDateValid, isRulesCreatorDisabled ]);

  const areTransactionsFiltered = useMemo(() => {
    return transactionsToLabel.length !== transactions.length;
  }, [ transactions, transactionsToLabel ]);

  return <Modal
    title={
      <div className={ styles.title }>
        { t('cost-labeler.title', { ns: 'financials' }) }
        <span>&bull;</span>
        <span>{ transactionsToLabel.length }</span>
      </div>
    }
    isVisible={ isVisible }
    onClose={ handleOnClose }
    onConfirm={ onConfirmCallback }
    confirmDisabled={ confirmDisabled }
    confirmLoading={ isCommitting }
    closeOnConfirm={ page === LabelingPage.CREATE_RULE }
    destroyOnClose={ true }
    className={ styles.costLabelerModal }
    width={ 850 }
    okText={ okText }
    closeIcon={ <CloseIcon/> }
    okButtonType={ okButtonType }
  >
    <>
      <Loader isActive={ loading }/>
      {
        page !== LabelingPage.CONFIRM ? <PageSwitcher
          page={ page }
          setPage={ (newPage) => setPage(newPage) }
          rulesDisabled={ isRulesCreatorDisabled }
        /> : null
      }
      { content }
      {
        areTransactionsFiltered ?
          <InlineStatement type='info' className={ styles.filterInfo }>
            { t('modal.filter-info') }
          </InlineStatement> : null
      }
    </>
  </Modal>;
};

export default CostLabelerModal;
