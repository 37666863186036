import React from 'react';
import {
  CommandBarDropdown as TCommandBarDropdown
} from 'components/commandBar/types/commandBar.types';
import CommandBarDropdown from
  'components/commandBar/components/common/dropdown/CommandBarDropdown';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';

import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';

import styles from './CommandBarDropdownButton.module.scss';

interface Props {
  button: TCommandBarDropdown;
}

const CommandBarDropdownButton = ({ button }: Props) => {
  return <CommandBarDropdown button={ button }>
    <CommandBarButton button={ button }>
      <div className={ styles.dropdownTrigger }>
        { button.icon }
        <ArrowIcon className={ styles.arrowDown } />
      </div>
    </CommandBarButton>
  </CommandBarDropdown>;
};

export default CommandBarDropdownButton;