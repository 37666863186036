import styles from './styles/rowStyles.module.scss';
import { CellClassParams, IRowNode } from 'ag-grid-community';
import { RowType } from '../../../types/financials.types';
import { IGNORE_NODES } from 'utils/chart.utils';
import { NO_ADD_BUTTON_ROW_TYPES } from '../utils/addRow.utils';

export const getFinancialRowClasses = (node: IRowNode): string[] => {
  const classes = [];
  if (node.data?.rowData?.numbersBold) {
    classes.push(styles.bold);
  }
  if (node.data?.rowData?.accentedLine) {
    classes.push(styles.accentedLine);
  }
  if (node.data?.type === RowType.TRANSACTION || !node.data?.id) {
    classes.push(styles.transactionLine);
  }
  if ([ RowType.SUBTOTAL, RowType.TOTAL ].includes(node.data?.type)) {
    if (node.level === 0) {
      classes.push(styles.bold);
      classes.push(styles.uppercase);
      classes.push(styles.accentedLine);
    }
  }
  return classes;
};

export const getFinancialCellClasses = (
  params: CellClassParams, depth: number, maxDepth: number): string[] => {
  const { node } = params;
  const depthClasses = [];
  const actionStyles = [];

  if (!NO_ADD_BUTTON_ROW_TYPES.includes(node.data?.type) &&
    params.columnApi.getAllGridColumns().some((column) => column.getColId().startsWith('plan'))) {
    actionStyles.push('addable');
  }

  if (node.data?.type === RowType.NEW_BUDGET_ITEM) {
    actionStyles.push('newBudgetItem');
  }

  if (!IGNORE_NODES.includes(node.data?.type) && node.data?.uuid != null) {
    actionStyles.push('chartButton');
  }

  switch (node.data?.type) {
    case RowType.PRODUCT_PROXY:
      return [ styles.transactionLine, ...actionStyles ];
    case RowType.TRANSACTION:
      return [ styles.transactionLine, ...actionStyles ];
    case RowType.BREAKDOWN:
      return [ styles.dimensionCapsule, ...actionStyles ];
    case RowType.GROUP:
      return [ styles.groupCapsule, ...actionStyles ];
    case RowType.TITLE:
      return [ styles.title, ...actionStyles ];
  }

  if (maxDepth >= 7) {
    switch (depth) {
      case 1:
      case 2:
      case 3:
        depthClasses.push(styles.bold);
        break;
      case 4:
        depthClasses.push(styles.italic);
        break;
    }

  }
  if (maxDepth <=6 && maxDepth >=3) {
    switch (depth) {
      case 1:
      case 2:
        depthClasses.push(styles.bold);
        break;
      case 3:
        depthClasses.push(styles.italic);
        break;
    }
  }
  if (maxDepth >=1 && maxDepth <= 2) {
    switch (depth) {
      case 1:
        depthClasses.push(styles.bold);
        break;
    }
  }
  return [ ...depthClasses, ...actionStyles ];
};
